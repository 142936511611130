import { DownOutlined, MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import mkCx from "@leancode/cx";
import { Table } from "antd";
import { ColumnProps } from "antd/lib/table";
import {
    CompetitionFinalRanking,
    FinalRankingEntryWithNameAndOptionalRanks,
} from "Domain/Competitions/CompetitionFinalRanking";
import { l } from "Languages";

import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { formatPhaseType } from "Utils/formatting";
import styles from "./styles.scss";

const cx = mkCx(styles);

type CompetitionSettingsFinalRankingTableProps = {
    entries: FinalRankingEntryWithNameAndOptionalRanks[];
    mode: "edit" | "readonly";
    type: "assigned" | "unassigned";
    finalRanking: CompetitionFinalRanking;
};

const CompetitionSettingsFinalRankingTable: React.FunctionComponent<CompetitionSettingsFinalRankingTableProps> = ({
    entries,
    mode,
    type,
    finalRanking,
}) => {
    const columns = useMemo(() => {
        const columns: ColumnProps<FinalRankingEntryWithNameAndOptionalRanks>[] = [
            {
                title: l("CompetitionDetails_FinalRanking_Table_PhaseType"),
                dataIndex: "phaseId",
                render: (_, { phaseType }) => <div>{formatPhaseType(phaseType)}</div>,
                width: 120,
            },
            {
                title: l("CompetitionDetails_FinalRanking_Table_PhaseName"),
                dataIndex: "phaseName",
                render: (_, { phaseName }) => <div>{phaseName}</div>,
            },
            {
                title: "",
                dataIndex: "groupName",
                render: (_, { groupName }) => <div>{groupName ?? ""}</div>,
            },
        ];

        if (type === "assigned") {
            columns.push({
                title: l("CompetitionDetails_FinalRanking_Table_Ranks"),
                render: (_, { rankFrom, rankTo }) => {
                    if (rankFrom === undefined || rankTo === undefined) {
                        return "";
                    }

                    if (rankTo < rankFrom) {
                        return "-";
                    }

                    if (rankFrom === rankTo) {
                        return rankFrom;
                    }

                    return `${rankFrom} - ${rankTo}`;
                },
                width: 100,
            });
        }

        if (mode === "edit") {
            if (type === "assigned") {
                columns.push({
                    title: "",
                    render: (_, { phaseId, groupId }, index) => {
                        const isFirst = index === 0;
                        const isLast = index === entries.length - 1;

                        return (
                            <div className={cx("move-arrows")}>
                                <DownOutlined
                                    className={isFirst ? cx("disabled") : undefined}
                                    rotate={180}
                                    onClick={() => !isFirst && finalRanking.moveEntry({ phaseId, groupId }, "up")}
                                />
                                <DownOutlined
                                    className={isLast ? cx("disabled") : undefined}
                                    onClick={() => !isLast && finalRanking.moveEntry({ phaseId, groupId }, "down")}
                                />
                            </div>
                        );
                    },
                    width: 80,
                });
            }

            columns.push({
                title: "",
                dataIndex: "actions",
                key: "actions",
                render: (_, { phaseId, groupId }) => (
                    <div>
                        {type === "assigned" ? (
                            <MinusCircleOutlined onClick={() => finalRanking.unassignEntry({ phaseId, groupId })} />
                        ) : (
                            <PlusCircleOutlined onClick={() => finalRanking.assignEntry({ phaseId, groupId })} />
                        )}
                    </div>
                ),
                width: 50,
            });
        }

        return columns;
    }, [entries.length, finalRanking, mode, type]);

    return (
        <>
            <div>
                {type === "assigned"
                    ? l("CompetitionDetails_FinalRanking_Assigned")
                    : l("CompetitionDetails_FinalRanking_Unassigned")}
            </div>
            <Table
                dataSource={entries}
                pagination={false}
                columns={columns}
                rowKey={({ phaseId, groupId }) => `${phaseId}-${groupId}`}
            />
        </>
    );
};

export default observer(CompetitionSettingsFinalRankingTable);
