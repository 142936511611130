import { PhaseTypeDTO } from "Contracts/PlayooLeagueClient";
import { action, computed, observable, runInAction } from "mobx";
import api from "Services/Api";
import { notUndefined } from "Utils/predicates";

export class CompetitionFinalRanking {
    private readonly competitionId: string;

    @observable allValidEntries: FinalRankingEntryWithName[] = [];
    @observable currentEntries?: FinalRankingEntry[];
    @observable entriesDraft?: FinalRankingEntry[];

    @observable isLoading = false;
    @observable isSaving = false;

    constructor(competitionId: string) {
        this.competitionId = competitionId;
    }

    @computed
    get assignedEntries() {
        const entries = this.entriesDraft ?? this.currentEntries;

        const assigned: FinalRankingEntryWithNameAndRanks[] = [];

        entries
            ?.map(entry => this.allValidEntries.find(e => e.phaseId === entry.phaseId && e.groupId === entry.groupId))
            .filter(notUndefined)
            .forEach((entry, index) => {
                if (index === 0) {
                    assigned.push({
                        ...entry,
                        rankFrom: 1,
                        rankTo: entry.numberOfPlaces,
                    });
                    return;
                }

                const previousEntry = assigned[assigned.length - 1];

                assigned.push({
                    ...entry,
                    rankFrom: previousEntry.rankTo + 1,
                    rankTo: previousEntry.rankTo + entry.numberOfPlaces,
                });
            });

        return assigned;
    }

    @computed
    get unassignedEntries() {
        return this.allValidEntries.filter(
            entry => !this.assignedEntries.some(e => e.phaseId === entry.phaseId && e.groupId === entry.groupId),
        );
    }

    @action.bound
    async fetchFinalRankingConfiguration() {
        this.isLoading = true;
        const result = await api.finalRankingConfiguration({
            CompetitionId: this.competitionId,
        });
        this.isLoading = false;

        if (result.isSuccess && result.result) {
            this.allValidEntries = result.result.AllValidEntries.map(entry => ({
                phaseType: entry.PhaseType,
                phaseId: entry.PhaseId,
                phaseName: entry.PhaseName,
                groupId: entry.GroupId ?? undefined,
                groupName: entry.GroupName ?? undefined,
                numberOfPlaces: entry.NumberOfPlaces ?? 0,
            }));
            this.currentEntries = result.result.CurrentEntries?.map(entry => ({
                phaseId: entry.PhaseId,
                groupId: entry.GroupId ?? undefined,
            }));

            return true;
        }

        return false;
    }

    @action.bound
    async saveEntries(entries?: FinalRankingEntry[]) {
        this.isSaving = true;
        const result = await api.updateFinalRankingConfiguration({
            CompetitionId: this.competitionId,
            Entries:
                entries?.map(entry => ({
                    PhaseId: entry.phaseId,
                    GroupId: entry.groupId ?? null,
                })) ?? [],
        });
        this.isSaving = false;

        return result.isSuccess && result.result.WasSuccessful;
    }

    @action.bound
    turnOn() {
        this.currentEntries = [];
    }

    @action.bound
    async turnOff() {
        await runInAction(async () => {
            this.currentEntries = undefined;
            await this.saveEntries(undefined);
        });
    }

    @action.bound
    startEditing() {
        if (!this.currentEntries) {
            return;
        }

        this.entriesDraft = [...this.currentEntries];
    }

    @action.bound
    cancelEditing() {
        this.entriesDraft = undefined;
    }

    @action.bound
    async saveEdited() {
        if (!this.entriesDraft || !this.currentEntries) {
            return false;
        }

        const success = await this.saveEntries(this.entriesDraft);

        if (success) {
            this.currentEntries = [...this.entriesDraft];
            this.entriesDraft = undefined;
        }

        return success;
    }

    @action.bound
    assignEntry(entry: FinalRankingEntry) {
        if (!this.entriesDraft) {
            return;
        }

        this.entriesDraft.push(entry);
    }

    @action.bound
    unassignEntry(entry: FinalRankingEntry) {
        if (!this.entriesDraft) {
            return;
        }

        this.entriesDraft = this.entriesDraft.filter(e => e.phaseId !== entry.phaseId || e.groupId !== entry.groupId);
    }

    @action.bound
    moveEntry(entry: FinalRankingEntry, direction: "up" | "down") {
        if (!this.entriesDraft) {
            return;
        }

        const index = this.entriesDraft.findIndex(e => e.phaseId === entry.phaseId && e.groupId === entry.groupId);

        if (index === -1) {
            return;
        }

        const newIndex = direction === "up" ? index - 1 : index + 1;

        if (newIndex < 0 || newIndex >= this.entriesDraft.length) {
            return;
        }

        this.entriesDraft.splice(index, 1, this.entriesDraft[newIndex]);
        this.entriesDraft.splice(newIndex, 1, entry);
    }
}

type FinalRankingEntry = {
    phaseId: string;
    groupId?: string;
};

export type FinalRankingEntryWithName = FinalRankingEntry & {
    phaseName: string;
    groupName?: string;
    numberOfPlaces: number;
    phaseType: PhaseTypeDTO;
};

type Ranks = {
    rankFrom: number;
    rankTo: number;
};

export type FinalRankingEntryWithNameAndRanks = FinalRankingEntryWithName & Ranks;
export type FinalRankingEntryWithNameAndOptionalRanks = FinalRankingEntryWithName & Partial<Ranks>;
