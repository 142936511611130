export default {
    AccessCodes_AddNew: "Dodaj swój kod organizatora",
    AccessCodes_AddNew_Failure: "Nie udało się dodać kodu organizatora. Spróbuj ponownie później.",
    AccessCodes_AddNew_Success: "Kod organizatora został dodany.",
    AccessCodes_Change: "Zmień kod",
    AccessCodes_Change_Failure: "Nie udało się zmienić kodu organizatora. Spróbuj ponownie później.",
    AccessCodes_Change_Success: "Kod organizatora został zmieniony.",
    AccessCodes_Form_AccessCode: "Twój kod organizatora",
    AccessCodes_Form_AccessCode_AlreadyInUse: "Podany kod organizatora jest już używany w systemie.",
    AccessCodes_Form_Create_Title: "Dodaj swój kod organizatora",
    AccessCodes_Form_Edit_Title: "Edytuj swój kod organizatora",
    AccessCodes_Title: "Twój kod organizatora",
    AppBar_LeagueEditionNotSelected: "Wszystkie sezony",
    AppBar_LeagueNotSelected: "Wszystkie ligi",
    AppBar_MobileSelectEmptyLeague: "Wybierz ligę i sezon",
    AppBar_MobileSelectEmptyLeagueEdition: "Wybierz sezon",
    AppBar_Navigation_League: "Liga",
    AppBar_Navigation_LeagueEdition: "Sezon",
    AppBar_Navigation_Schedule: "Terminarz",
    AppBar_Navigation_Standings: "Rankingi",
    AppBar_Navigation_Teams: "Drużyny",
    AppBar_SignOut: "Wyloguj",
    Articles: "Artykuły",
    Articles_AddNew: "Dodaj artykuł",
    Articles_AddNew_Failure: "Nie udało się dodać artykułu. Spróbuj ponownie później.",
    Articles_AddNew_Success: "Artykuł został dodany.",
    Articles_DateModified: "Data ostatniej modyfikacji",
    Articles_DatePublished: "Data publikacji",
    Articles_Delete: "Usuń",
    Articles_Delete_Confirmation_Title: "Usuwanie artykułu",
    Articles_Delete_Confirmation_Content: "Czy na pewno chcesz usunąć artykuł?",
    Articles_Delete_Failure: "Nie udało się usunąć artykułu. Spróbuj ponownie później.",
    Articles_Delete_Success: "Artykuł został usunięty.",
    Articles_Edit: "Edytuj",
    Articles_Edit_Failure: "Nie udało się edytować artykułu. Spróbuj ponownie później.",
    Articles_Edit_Success: "Artykuł został edytowany.",
    Articles_Edit_Title: "Edytuj artykuł",
    Articles_Editor_AddTag: "Dodaj tag",
    Articles_Editor_Competitions: "Rozgrywki",
    Articles_Editor_Content: "Treść",
    Articles_Editor_IsNews: "News",
    Articles_Editor_IsNews_Checked: "Tak",
    Articles_Editor_IsNews_Unchecked: "Nie",
    Articles_Editor_SaveDraft: "Zapisz draft",
    Articles_Editor_Summary: "Podsumowanie",
    Articles_Editor_Tags: "Tagi",
    Articles_Editor_Title: "Tytuł",
    Articles_Editor_Thumbnail: "Miniaturka",
    Articles_Editor_Thumbnail_NoThumbnail: "Brak",
    Articles_EmptyState: "Brak artykułów.",
    Articles_Preview: "Podgląd",
    Articles_Preview_NotAvailable: "Nie masz włączonej usługi strony internetowej.",
    Articles_Publish: "Opublikuj",
    Articles_Publish_Confirmation_Content: "Czy chcesz opublikować artykuł?",
    Articles_Publish_Confirmation_Title: "Publikowanie artykułu",
    Articles_Publish_Failure: "Nie udało się opublikować artykułu. Spróbuj ponownie później.",
    Articles_Publish_Success: "Artykuł został opublikowany.",
    Articles_State: "Status",
    Articles_State_Draft: "Draft",
    Articles_State_Published: "Opublikowane",
    Articles_Tags: "Tagi",
    Articles_Title: "Tytuł",
    Articles_Unpublish: "Cofnij publikację",
    Articles_Unpublish_Confirmation_Content: "Czy na pewno chcesz cofnąć publikację?",
    Articles_Unpublish_Confirmation_Title: "Cofanie publikacji artykułu",
    Articles_Unpublish_Failure: "Nie udało się cofnąć publikacji artykułu. Spróbuj ponownie później.",
    Articles_Unpublish_Success: "Publikacja artykułu została cofnięta.",
    Common_Actions: "Akcje",
    Common_Add: "Dodaj",
    Common_Cancel: "Anuluj",
    Common_Edit: "Edytuj",
    Common_Export: "Eksportuj",
    Common_Form_CountryCode: "Nr kierunkowy kraju",
    Common_Form_PhoneNumber: "Nr telefonu",
    Common_Ordinal: "Nr",
    Common_Remove: "Usuń",
    Common_Reset: "Resetuj",
    Common_Save: "Zapisz",
    Common_Search: "Szukaj",
    Common_Validation_FieldRequired: "To pole jest wymagane.",
    Common_Validation_FieldTooLong: "To pole zawiera zbyt wiele znaków.",
    Common_Validation_InvalidEmail: "Niepoprawny format adresu email.",
    Common_Validation_InvalidPhoneNumber: "Numer telefonu powinien składać się z 9 cyfr.",
    Common_Validation_Number: "Podaj poprawną liczbę.",
    Common_Versus: "vs",
    CompetitionDetails_AddNewPhase: "Dodaj fazę",
    CompetitionDetails_GenerateSchedule: "Wygeneruj terminarz",
    CompetitionDetails_Draw_Generate: "Rozlosuj drużyny",
    CompetitionDetails_Draw_NoPlaceholdersOrTeams: "Nie ma żadnych wolnych drużyn lub miejsc na drużynę",
    CompetitionDetails_Draw_Save: "Zapisz drużyny",
    CompetitionDetails_DrawGroups: "Losuj grupy",
    CompetitionDetails_DrawGroups_ModalTitle: "Losowanie drużyn do grup",
    CompetitionDetails_DrawTeams: "Losuj drużyny",
    CompetitionDetails_DrawTeams_ModalTitle: "Losowanie drużyn",
    CompetitionDetails_FinalRanking: "Klasyfikacja końcowa",
    CompetitionDetails_FinalRanking_Assigned: "Fazy przypisane do klasyfikacji końcowej",
    CompetitionDetails_FinalRanking_Edit: "Zarządzaj kolejnością",
    CompetitionDetails_FinalRanking_Export_Error: "Nie udało się wyeksportować klasyfikacji końcowej. Spróbuj ponownie później.",
    CompetitionDetails_FinalRanking_Filename: "Klasyfikacja końcowa {0}.xlsx",
    CompetitionDetails_FinalRanking_Is_Off: "Klasyfikacja końcowa jest wyłączona",
    CompetitionDetails_FinalRanking_Off: "Wyłącz",
    CompetitionDetails_FinalRanking_Off_Confirmation: "Czy na pewno chcesz wyłączyć klasyfikację końcową? Aktualne ustawienia zostaną usunięte.",
    CompetitionDetails_FinalRanking_On: "Włącz",
    CompetitionDetails_FinalRanking_Table_PhaseName: "Nazwa fazy",
    CompetitionDetails_FinalRanking_Table_PhaseType: "Typ fazy",
    CompetitionDetails_FinalRanking_Table_Ranks: "Miejsca",
    CompetitionDetails_FinalRanking_Unassigned: "Fazy nieprzypisane do klasyfikacji końcowej",
    CompetitionDetails_GeneratePhaseSchedule_Failure: "Nie udało się wygenerować terminarza. Spróbuj ponownie później.",
    CompetitionDetails_GeneratePhaseSchedule_Form_Phase_Failure: "Faza ma nieprawidłowy stan lub harmonogram został już wygenerowany.",
    CompetitionDetails_GeneratePhaseSchedule_Form_GenerationMode: "Tryb generowania terminarza",
    CompetitionDetails_GeneratePhaseSchedule_Form_GenerationMode_MatchesPerPair: "każdy z każdym",
    CompetitionDetails_GeneratePhaseSchedule_Form_GenerationMode_MatchesPerTeam: "tylko wybrana liczba meczy",
    CompetitionDetails_GeneratePhaseSchedule_Form_MatchesPerPair: "Wybierz liczbę meczów jaką ma zagrać każda drużyna:",
    CompetitionDetails_GeneratePhaseSchedule_Form_MatchesPerPair_One: "1 raz",
    CompetitionDetails_GeneratePhaseSchedule_Form_MatchesPerPair_Two: "2 razy",
    CompetitionDetails_GeneratePhaseSchedule_Form_MatchesPerTeam: "Liczba meczy do zagrania przez każdą drużynę:",
    CompetitionDetails_GeneratePhaseSchedule_Form_OkText: "Wygeneruj",
    CompetitionDetails_GeneratePhaseSchedule_Form_Title: "Generowanie terminarza",
    CompetitionDetails_GeneratePhaseSchedule_Success: "Terminarz fazy typu tabela został wygnerowany.",
    CompetitionDetails_NoPhases: "Brak faz w rozgrywkach",
    CompetitionDetails_Phases: "Fazy",
    CompetitionDetails_Phases_Finish: "Zakończ fazę",
    CompetitionDetails_Phases_Finish_Failure: "Nie udało się zakończyć fazy. Spróbuj ponownie później.",
    CompetitionDetails_Phases_Finish_Success: "Faza została zakończona.",
    CompetitionDetails_Phases_Name: "Nazwa",
    CompetitionDetails_Phases_Ongoing: "Trwa",
    CompetitionDetails_Phases_Remove: "Usuń",
    CompetitionDetails_Phases_Remove_Confirm_Title: "Usuwanie fazy",
    CompetitionDetails_Phases_Remove_Confirm_Content: "Czy na pewno chcesz usunąć fazę?",
    CompetitionDetails_Phases_Remove_Failure: "Nie udało się usunąć fazy. Spróbuj ponownie później.",
    CompetitionDetails_Phases_Remove_Success: "Faza została usunięta.",
    CompetitionDetails_Phases_Start: "Ustaw jako trwającą",
    CompetitionDetails_Phases_Start_Failure: "Nie udało się rozpocząć fazy. Spróbuj ponownie później.",
    CompetitionDetails_Phases_Start_Success: "Faza została rozpoczęta.",
    CompetitionDetails_Phases_Type: "Typ fazy",
    CompetitionDetails_Referees: "Sędziowie",
    CompetitionDetails_Referees_Add: "Dodaj sędziego",
    CompetitionDetails_Referees_Add_Failure: "Nie udało się dodać sędziów. Spróbuj ponownie później.",
    CompetitionDetails_Referees_Add_Select_AddNew: "Dodaj nowego sędziego",
    CompetitionDetails_Referees_Add_Success: "Sędziowie zostali dodani do rozgrywek.",
    CompetitionDetails_Referees_EmptyState: "Brak sędziów.",
    CompetitionDetails_Referees_FirstName: "Imię",
    CompetitionDetails_Referees_Form_FirstName: "Imię",
    CompetitionDetails_Referees_Form_LastName: "Nazwisko",
    CompetitionDetails_Referees_Form_PhoneNumberNotUnique: "Sędzia o podanym numerze telefonu został dodany do rozgrywek lub znajduje się na liście sędziów do wyboru.",
    CompetitionDetails_Referees_Form_Title: "Dodaj sędziego",
    CompetitionDetails_Referees_LastName: "Nazwisko",
    CompetitionDetails_Referees_PhoneNumber: "Tel.",
    CompetitionDetails_Referees_Remove: "Usuń",
    CompetitionDetails_Referees_Remove_Confirmation_Title: "Czy na pewno chcesz usunąć sędziego?",
    CompetitionDetails_Referees_Remove_Confirmation_Content: "Straci dostęp i możliwość publikowania meczów, ale będzie mógł zmieniać te mecze, do których uzyskał dostęp w aplikacji dla sędziów.",
    CompetitionDetails_Referees_Remove_Failure: "Nie udało się usunąć sędziego. Spróbuj ponownie później.",
    CompetitionDetails_Referees_Remove_Success: "Sędzia został usunięty.",
    CompetitionDetails_SportsFields: "Boiska",
    CompetitionDetails_SportsFields_Create: "Dodaj boisko",
    CompetitionDetails_SportsFields_Create_Failure: "Nie udało się dodać boiska. Spróbuj ponownie później.",
    CompetitionDetails_SportsFields_Create_Success: "Boisko zostało dodane.",
    CompetitionDetails_SportsFields_Edit: "Edytuj nazwę",
    CompetitionDetails_SportsFields_Edit_Failure: "Nie udało się edytować nazwy boiska. Spróbuj ponownie później.",
    CompetitionDetails_SportsFields_Edit_Success: "Nazwa boiska została zmieniona.",
    CompetitionDetails_SportsFields_EmptyState: "Brak boisk.",
    CompetitionDetails_SportsFields_Form_Name: "Nazwa",
    CompetitionDetails_SportsFields_Form_Title_Create: "Dodawanie boiska",
    CompetitionDetails_SportsFields_Form_Title_Edit: "Edycja boiska",
    CompetitionDetails_Teams: "Drużyny",
    CompetitionDetails_Teams_Add: "Dodaj drużynę",
    CompetitionDetails_Teams_Delete: "Usuń drużynę",
    CompetitionDetails_Teams_Delete_Confirm_Title: "Usuwanie drużyny",
    CompetitionDetails_Teams_Delete_Confirm_Content: "Czy na pewno chcesz usunąć drużynę? Jeżeli drużyna nie występuje w innych Twoich rozgrywkach nie będzie już jej można przywrócić. Pamiętaj też, że drużyna będzie widoczna w meczach, w fazach, które mają już wygenerowany terminarz.",
    CompetitionDetails_Teams_Delete_Confirm_OkText: "Usuń drużynę",
    CompetitionDetails_Teams_Delete_Failure: "Nie udało się usunąć drużyny. Spróbuj ponownie później.",
    CompetitionDetails_Teams_Delete_Success: "Drużyna została usunięta.",
    CompetitionDetails_Teams_Edit: "Edytuj drużynę",
    CompetitionDetails_Teams_Edit_Failure: "Nie udało się edytować drużyny.",
    CompetitionDetails_Teams_Edit_Success: "Drużyna została zaktualizowana.",
    CompetitionDetails_Teams_Failure: "Nie udało się dodać drużyn do rozgrywek.",
    CompetitionDetails_Teams_Logo: "Logo",
    CompetitionDetails_Teams_Name: "Nazwa",
    CompetitionDetails_Teams_PartialFailure: "Część drużyn nie została dodana do rozgrywek.",
    CompetitionDetails_Teams_PlaceholderTeam: "Miejsce na drużynę",
    CompetitionDetails_Teams_Remove: "Usuń drużynę",
    CompetitionDetails_Teams_Remove_Failure: "Nie udało się usunąć drużyny z fazy.",
    CompetitionDetails_Teams_Remove_Success: "Drużyna została usunięta z fazy.",
    CompetitionDetails_Teams_Replace: "Wymień tę drużynę",
    CompetitionDetails_Teams_Replace_Confirm_Title: "Czy na pewno chcesz wymienić drużynę?",
    CompetitionDetails_Teams_Replace_Confirm_Content: "Wymieniasz drużynę {0} na drużynę {1}. Spowoduje to, że {1} zastąpi {0} w każdej z faz oraz w każdym z meczów. Indywidualne statystyki graczy z poprzedniej drużyny zostaną utracone, a nowa drużyna zastąpi starą także w tych meczach, które się już odbyły.",
    CompetitionDetails_Teams_Replace_Confirm_OkText: "Zmień",
    CompetitionDetails_Teams_Replace_Failure: "Nie udało się wymienić drużyny {0} na {1}. Spróbuj ponownie później.",
    CompetitionDetails_Teams_Replace_Failure_TeamAlreadyCompetes: "Drużyna {0} bierze już udział w rozgrywkach.",
    CompetitionDetails_Teams_Replace_Success: "Drużyna {0} została pomyślnie zastąpiona przez drużynę {1}.",
    CompetitionDetails_Teams_Merge: "Scal drużyny",
    CompetitionDetails_Teams_Merge_Confirmation_Content: "Czy na pewno chcesz scalić drużyny? Skutków tej operacji nie można cofnąć.",
    CompetitionDetails_Teams_Merge_Confirmation_OkText: "Scal drużyny",
    CompetitionDetails_Teams_Merge_Confirmation_Title: "Potwierdzenie scalenia",
    CompetitionDetails_Teams_Merge_Failure: "Nie udało się scalić drużyn. Spróbuj ponownie później.",
    CompetitionDetails_Teams_Merge_Form_OkText: "Scal",
    CompetitionDetails_Teams_Merge_Form_Team: "Drużyna",
    CompetitionDetails_Teams_Merge_Form_EmptyState: "Brak drużyn do scalenia",
    CompetitionDetails_Teams_Merge_Form_Title: "Scal",
    CompetitionDetails_Teams_Merge_Success: "Drużyny zostały scalone.",
    CompetitionDetails_Teams_Merge_History_Season: "Sezon",
    CompetitionDetails_Teams_Merge_History_Team: "Historia drużyny",
    CompetitionDetails_Teams_Success: "Drużyny zostały dodane do rozgrywek.",
    CompetitionGroups: "Grupy rozgrywkowe",
    CompetitionGroups_Add: "Dodaj Grupę rozgrywkową",
    CompetitionGroups_Add_Failure: "Nie udało się dodać grupy rozgrywkowej. Spróbuj ponownie później.",
    CompetitionGroups_Add_Success: "Grupa rozgrywkowa została dodana.",
    CompetitionGroups_Edit: "Edytuj Grupę rozgrywkową",
    CompetitionGroups_Edit_Failure: "Nie udało się edytować grupy rozgrywkowej. Spróbuj ponownie później.",
    CompetitionGroups_Edit_Success: "Grupa rozgrywkowa została edytowana.",
    CompetitionGroups_EmptyState: "Brak grup rozgrywkowych",
    CompetitionGroups_Form_Name: "Nazwa",
    CompetitionGroups_Form_Title_Add: "Dodaj grupę rozgrywkową",
    CompetitionGroups_Form_Title_Edit: "Edytuj grupę rozgrywkową",
    CompetitionGroups_Reorder: "Zmień kolejność",
    CompetitionGroups_Reorder_Failure: "Nie udało się zmienić kolejności grup rozgrywkowych w sezonie. Spróbuj ponownie później.",
    CompetitionGroups_Reorder_Loading: "Zapisywanie kolejności grup rozgrywkowych.",
    CompetitionGroups_Reorder_Success: "Kolejność grup rozgrywkowych została zmieniona.",
    CompetitionPhases_Create_AddAllTeamsFromCompetition: "Dodaj wszystkie drużyny z Rozgrywek do fazy",
    CompetitionPhases_Create_Failure: "Nie udało się dodać fazy rozgrywek. Spróbuj ponownie później.",
    CompetitionPhases_Create_LinkedPhase: "Faza skojarzona",
    CompetitionPhases_Create_ChooseLinkedPhase: "Wybierz fazę",
    CompetitionPhases_Create_PhaseWhichWillBeLinkedWithNewPhase: "Faza z którą będzie skojarzona nowa faza",
    CompetitionPhases_Create_Name: "Nazwa",
    CompetitionPhases_Create_PhaseType: "Typ fazy",
    CompetitionPhases_Create_Success: "Faza została dodana do rozgrywek.",
    CompetitionPhases_Create_Title: "Dodaj fazę",
    CompetitionPhases_Custom: "Elastyczna",
    CompetitionPhases_Custom_AddMatch: "Dodaj mecz",
    CompetitionPhases_Custom_AddMatch_Failure: "Nie udało się dodać meczu. Spróbuj ponownie później.",
    CompetitionPhases_Custom_AddMatch_Form_Title: "Dodawanie meczu",
    CompetitionPhases_Custom_AddMatch_Success: "Mecz został dodany.",
    CompetitionPhases_Custom_EditMatch_Failure: "Nie udało się edytować meczu. Spróbuj ponownie później.",
    CompetitionPhases_Custom_EditMatch_Success: "Mecz został edytowany.",
    CompetitionPhases_Custom_Form_Date: "Data",
    CompetitionPhases_Custom_Form_MatchGroups: "Grupy meczów",
    CompetitionPhases_Custom_Form_Name: "Nazwa meczu",
    CompetitionPhases_Custom_Form_SportsField: "Boisko",
    CompetitionPhases_Details_DeleteSchedule: "Usuń harmonogram i wyniki",
    CompetitionPhases_Details_DeleteSchedule_Confirmation_Content: "Czy na pewno chcesz usunąć terminarz i wyniki? Stracisz przebieg meczów oraz zaplanowany harmonogram. Ta operacja jest nieodwracalna.",
    CompetitionPhases_Details_DeleteSchedule_Confirmation_OkText: "Usuń terminarz i wyniki",
    CompetitionPhases_Details_DeleteSchedule_Confirmation_Title: "Usuwanie terminarza",
    CompetitionPhases_Details_DeleteSchedule_Failure: "Nie udało się usunąć harmonogramu i wyników. Spróbuj ponownie później.",
    CompetitionPhases_Details_DeleteSchedule_Success: "Harmonogram i wyniki zostały usunięte.",
    CompetitionPhases_Details_Details_Groups: "Grupy",
    CompetitionPhases_Details_MatchGroups: "Grupy meczów",
    CompetitionPhases_Details_MatchGroups_Create: "Dodaj grupę",
    CompetitionPhases_Details_MatchGroups_Create_Failure: "Nie udało się dodać grupy meczów. Spróbuj ponownie później.",
    CompetitionPhases_Details_MatchGroups_Create_Success: "Grupa meczów została dodana.",
    CompetitionPhases_Details_MatchGroups_Create_Title: "Dodawanie grupy meczów",
    CompetitionPhases_Details_MatchGroups_Delete_Confirmation_Content: "Czy na pewno chcesz usunać grupę meczów?",
    CompetitionPhases_Details_MatchGroups_Delete_Confirmation_Title: "Usuwanie grupy meczów",
    CompetitionPhases_Details_MatchGroups_Delete_Failure: "Nie udało się usunąć grupy meczów. Spróbuj ponownie później.",
    CompetitionPhases_Details_MatchGroups_Delete_Success: "Grupa meczów została usunięta.",
    CompetitionPhases_Details_MatchGroups_Edit: "Edytuj",
    CompetitionPhases_Details_MatchGroups_Edit_Failure: "Nie udało się edytować grupy meczów. Spróbuj ponownie później.",
    CompetitionPhases_Details_MatchGroups_Edit_Success: "Grupa meczów została edytowana.",
    CompetitionPhases_Details_MatchGroups_Edit_Title: "Edycja nazwy grupy meczów",
    CompetitionPhases_Details_MatchGroups_Name: "Nazwa",
    CompetitionPhases_Details_MatchGroups_Reorder: "Zmień kolejność",
    CompetitionPhases_Details_MatchGroups_Reorder_Failure: "Nie udało się zmienić kolejności grup meczów. Spróbuj ponownie później.",
    CompetitionPhases_Details_MatchGroups_Reorder_SaveInProgress: "Zapisywanie kolejności grup meczów.",
    CompetitionPhases_Details_MatchGroups_Reorder_Success: "Kolejność grup meczów została zmieniona.",
    CompetitionPhases_Details_NoTitlePhase: "Szczegóły fazy",
    CompetitionPhases_Details_Schedule: "Harmonogram",
    CompetitionPhases_Details_Schedule_Date: "Data",
    CompetitionPhases_Details_Schedule_EmptyState: "Brak meczów",
    CompetitionPhases_Details_Schedule_EmptyState_GroupsPhase: "Wygeneruj terminarz, żeby zobaczyć tutaj mecze",
    CompetitionPhases_Details_Schedule_MatchdayColumn: "Kolejka",
    CompetitionPhases_Details_Schedule_Matchday: "{0} kolejka",
    CompetitionPhases_Details_Schedule_SportsField: "Boisko",
    CompetitionPhases_Details_Schedule_Team1: "Gospodarz",
    CompetitionPhases_Details_Schedule_Team2: "Gość",
    CompetitionPhases_Details_SetMatchDate: "Dodaj",
    CompetitionPhases_Details_Teams: "Drużyny",
    CompetitionPhases_Details_Teams_AddTeam: "Dodaj drużynę",
    CompetitionPhases_Details_Teams_AddTeam_Failure: "Nie udało się dodać drużyn do fazy.",
    CompetitionPhases_Details_Teams_AddTeam_NoTeams: "Żeby dodać drużyny do fazy, najpierw dodaj je do rozgrywek.",
    CompetitionPhases_Details_Teams_AddTeam_Success: "Drużyny zostały dodane do fazy.",
    CompetitionPhases_Details_Teams_NoTeams: "Brak drużyn w fazie.",
    CompetitionPhases_ExternalReferences_AdvanceTeam: "Przenieś",
    CompetitionPhases_ExternalReferences_AdvanceTeam_Confirmation_Content: "Czy na pewno chcesz przenieść drużynę?",
    CompetitionPhases_ExternalReferences_AdvanceTeam_Confirmation_Title: "Przenoszenie drużyny",
    CompetitionPhases_ExternalReferences_AdvanceTeam_Failure: "Nie udało się przenieść drużyny. Spróbuj ponownie później.",
    CompetitionPhases_ExternalReferences_AdvanceTeam_Success: "Drużyna została przeniesiona.",
    CompetitionPhases_ExternalReferences_AdvanceTeam_TeamAlreadyAddedToPhase: "Ta drużyna jest już w fazie. Najpierw musisz ją usunąć.",
    CompetitionPhases_ExternalReferences_AdvanceTeams: "Przenieś wszystkie",
    CompetitionPhases_ExternalReferences_AdvanceTeams_Confirmation_Content: "Czy na pewno chcesz przenieść drużyny?",
    CompetitionPhases_ExternalReferences_AdvanceTeams_Confirmation_Title: "Przenoszenie drużyn",
    CompetitionPhases_ExternalReferences_AdvanceTeams_Failure: "Nie udało się przenieść drużyn. Spróbuj ponownie później.",
    CompetitionPhases_ExternalReferences_AdvanceTeams_PartialFailure: "Części drużyn nie udało się przenieść, bo są już w fazie. Usuń je i spróbuj ponownie.",
    CompetitionPhases_ExternalReferences_AdvanceTeams_Success: "Drużyny zostały przeniesione.",
    CompetitionPhases_ExternalReferences_NthBestForPlaceInGroup_DisplayName_1st: "{0} bilans z {1}ych miejsc",
    CompetitionPhases_ExternalReferences_NthBestForPlaceInGroup_DisplayName_2nd_3rd: "{0} bilans z {1}ich miejsc",
    CompetitionPhases_ExternalReferences_NthBestForPlaceInGroup_DisplayName_Nth: "{0} bilans z {1}ych miejsc",
    CompetitionPhases_ExternalReferences_PlaceInGroup_DisplayName: "{0} miejsce {1}",
    CompetitionPhases_ExternalReferences_PlaceInTable_DisplayName: "{0} miejsce w tabeli",
    CompetitionPhases_ExternalReferences_RevokeTeamAdvancing: "Cofnij przeniesienie",
    CompetitionPhases_ExternalReferences_RevokeTeamAdvancing_Confirmation_Content: "Czy na pewno chcesz cofnąć przeniesienie drużyny?",
    CompetitionPhases_ExternalReferences_RevokeTeamAdvancing_Confirmation_Title: "Cofanie przeniesienia drużyny",
    CompetitionPhases_ExternalReferences_RevokeTeamAdvancing_Failure: "Nie udało się cofnąć przeniesienia drużyny. Spróbuj ponownie później.",
    CompetitionPhases_ExternalReferences_RevokeTeamAdvancing_Success: "Przeniesienie drużyny zostało cofnięte.",
    CompetitionPhases_Groups: "Grupowa",
    CompetitionPhases_Groups_AddGroup_Failure: "Nie udało się dodać grupy. Spróbuj ponownie później.",
    CompetitionPhases_Groups_AddGroup_Success: "Grupa została dodana.",
    CompetitionPhases_Groups_AddMatch: "Dodaj mecz",
    CompetitionPhases_Groups_AddMatch_Failure: "Nie udało się dodać meczu. Spróbuj ponownie później.",
    CompetitionPhases_Groups_AddMatch_Success: "Mecz został dodany.",
    CompetitionPhases_Groups_AddTeams: "Dodaj drużyny",
    CompetitionPhases_Groups_AddTeams_EmptyState: "Żeby dodać drużyny do grupy, najpierw dodaj je do rozgrywek.",
    CompetitionPhases_Groups_AddTeams_Failure: "Nie udało się dodać drużyn lub części drużyn do grupy. Spróbuj ponownie później.",
    CompetitionPhases_Groups_AddTeams_NthBestForPlaceInGroupReferences: "Bilanse",
    CompetitionPhases_Groups_AddTeams_PlaceInGroup: "{0} miejsca",
    CompetitionPhases_Groups_AddTeams_PlaceInGroupReferences: "Grupy",
    CompetitionPhases_Groups_AddTeams_Success: "Drużyny zostały dodane do grupy.",
    CompetitionPhases_Groups_AddTeams_Teams: "Drużyny",
    CompetitionPhases_Groups_CreateSchedule: "Stwórz terminarz",
    CompetitionPhases_Groups_DefaultGroupPrefix: "Grupa",
    CompetitionPhases_Groups_DeleteGroup: "Usuń grupę",
    CompetitionPhases_Groups_DeleteGroup_Confirmation_Content: "Czy na pewno chcesz usunąć grupę?",
    CompetitionPhases_Groups_DeleteGroup_Confirmation_Title: "Usuwanie grupy",
    CompetitionPhases_Groups_DeleteGroup_Failure: "Nie udało się usunąć grupy. Spróbuj ponownie później.",
    CompetitionPhases_Groups_DeleteGroup_Success: "Grupa została usunięta.",
    CompetitionPhases_Groups_EditGroups: "Edytuj grupy",
    CompetitionPhases_Groups_GenerateSchedule: "Wygeneruj terminarz",
    CompetitionPhases_Groups_GenerateSchedule_Failure: "Nie udało się wygenerować terminarza. Spróbuj ponownie później.",
    CompetitionPhases_Groups_GenerateSchedule_GroupHasTooFewTeams: "Wszystkie grupy muszą składać się z co najmniej dwóch drużyn.",
    CompetitionPhases_Groups_GenerateSchedule_PhaseHasNoGroups: "Faza musi składać się z co najmniej jednej grupy.",
    CompetitionPhases_Groups_GenerateSchedule_Success: "Terminarz fazy grupowej został wygenerowany.",
    CompetitionPhases_Groups_Match_Form_Add_Title: "Dodawanie meczu",
    CompetitionPhases_Groups_Match_Form_Date: "Data",
    CompetitionPhases_Groups_Match_Form_Edit_Title: "Edycja meczu",
    CompetitionPhases_Groups_Match_Form_Groups: "Grupa",
    CompetitionPhases_Groups_Match_Form_Matchday: "Kolejka",
    CompetitionPhases_Groups_Match_Form_SportsField: "Boisko",
    CompetitionPhases_Groups_Name: "Nazwa grupy",
    CompetitionPhases_Groups_RenameGroup_Failure: "Nie udało się zmienić nazwy grupy. Spróbuj ponownie później.",
    CompetitionPhases_Groups_RenameGroup_Success: "Nazwa grupy została zmieniona.",
    CompetitionPhases_Groups_RemoveTeamFromGroup_Failure: "Nie udało się usunąć drużyny z grupy. Spróbuj ponownie później.",
    CompetitionPhases_Groups_RemoveTeamFromGroup_Success: "Drużyna została usunięta z grupy",
    CompetitionPhases_Groups_Save_Failure: "Nie udało się zapisać grup. Spróbuj ponownie później.",
    CompetitionPhases_Groups_Save_Success: "Grupy zostały zapisane.",
    CompetitionPhases_Groups_ScheduleCreation: "Tworzenie terminarza",
    CompetitionPhases_Groups_SetGroupName: "Zmiana nazwy grupy",
    CompetitionPhases_Knockout: "Drabinka",
    CompetitionPhases_Knockout_Board_Matches: "Mecze 1szej fazy turnieju",
    CompetitionPhases_Knockout_Board_UnassignedTeams: "Nieprzypisane",
    CompetitionPhases_Knockout_EditMatch_Form_Name: "Nazwa",
    CompetitionPhases_Knockout_EditMatch_Form_SportsField: "Boisko",
    CompetitionPhases_Knockout_EditMatch_Form_Date: "Data",
    CompetitionPhases_Knockout_GenerateSchedule: "Stwórz terminarz",
    CompetitionPhases_Knockout_GenerateSchedule_ConfiguredMatchesCountNotAPowerOfTwo: "Liczba meczów musi wynosić 1, 2, 4, 8, 16, 32, 64...",
    CompetitionPhases_Knockout_GenerateSchedule_Failure: "Nie udało się wygenerować terminarza. Spróbuj ponownie później.",
    CompetitionPhases_Knockout_GenerateSchedule_OneOfMatchesMisconfigured: "W jednym powinny meczu powinny być 2 drużyny.",
    CompetitionPhases_Knockout_GenerateSchedule_Success: "Terminarz fazy został wygenerowany.",
    CompetitionPhases_Knockout_MatchNumber: "Mecz {0}",
    CompetitionPhases_Knockout_MatchForNthPlace: "Mecz o {0} miejsce",
    CompetitionPhases_Knockout_MatchNumberForNthPlace: "Mecz {0} o {1} miejsce",
    CompetitionPhases_Knockout_ScheduleCreation: "Tworzenie terminarza",
    CompetitionPhases_Knockout_ScheduleCreation_AddNthPlaceMatch: "Mecz o {0} miejsce",
    CompetitionPhases_Knockout_Stage_Final: "Finał",
    CompetitionPhases_Knockout_Stage_Nth: "1/{0}",
    CompetitionPhases_Knockout_Stage_Semifinal: "Półfinał",
    CompetitionPhases_Knockout_Stage_Quarterfinal: "Ćwierćfinał",
    CompetitionPhases_Knockout_TeamAdvancingFromAsLoser: "Przegrany meczu {0}",
    CompetitionPhases_Knockout_TeamAdvancingFromAsLoserForNthPlace: "Przegrany meczu {0} o {1} miejsce",
    CompetitionPhases_Knockout_TeamAdvancingFromAsWinner: "Zwycięzca meczu {0}",
    CompetitionPhases_Knockout_TeamAdvancingFromAsWinnerForNthPlace: "Zwycięzca meczu {0} o {1} miejsce",
    CompetitionPhases_MatchFrom_NthBestForPlaceInGroupReferences: "Bilanse",
    CompetitionPhases_MatchFrom_PlaceInGroup: "{0} miejsca",
    CompetitionPhases_MatchFrom_PlaceInGroupReferences: "Grupy",
    CompetitionPhases_MatchForm_Team1: "Gospodarze",
    CompetitionPhases_MatchForm_Team2: "Goście",
    CompetitionPhases_MatchFrom_Teams: "Drużyny",
    CompetitionPhases_PlaceholderTeams_Add: "Miejsce na drużynę",
    CompetitionPhases_PlaceholderTeams_Add_Failure: "Nie udało się dodać miejsca na drużynę. Spróbuj ponownie później.",
    CompetitionPhases_PlaceholderTeams_Add_Success: "Miejsce na drużynę zostało dodane.",
    CompetitionPhases_PlaceholderTeams_Advance: "Przenieś",
    CompetitionPhases_PlaceholderTeams_Advance_Failure: "Nie udało się przenieść drużyny z poprzedniej fazy. Spróbuj ponownie później.",
    CompetitionPhases_PlaceholderTeams_Advance_Succes: "Drużyna została przeniesiona",
    CompetitionPhases_PlaceholderTeams_Delete_Confirm_Title: "Usuwanie miejsca na drużynę",
    CompetitionPhases_PlaceholderTeams_Delete_Confirm_Content: "Czy na pewno chcesz usunąć drużynę? Spowoduje to odwołanie wszystkich jej meczów.",
    CompetitionPhases_PlaceholderTeams_Delete_Confirm_OkText: "Usuń drużynę",
    CompetitionPhases_PlaceholderTeams_Delete_Failure: "Nie udało się usunąć miejsca na drużynę. Spróbuj ponownie później.",
    CompetitionPhases_PlaceholderTeams_Delete_Success: "Miejsce na drużynę zostało usunięte.",
    CompetitionPhases_PlaceholderTeams_Edit: "Edytuj nazwę",
    CompetitionPhases_PlaceholderTeams_Edit_Failure: "Nie udało się zmienić nazwy miejsca na drużynę. Spróbuj ponownie później.",
    CompetitionPhases_PlaceholderTeams_Edit_Success: "Nazwa miejsca na drużynę została zmieniona.",
    CompetitionPhases_PlaceholderTeams_Form_Add_Title: "Dodawanie miejsca na drużynę",
    CompetitionPhases_PlaceholderTeams_Form_Edit_Title: "Edycja miejsca na drużynę",
    CompetitionPhases_PlaceholderTeams_Form_Name: "Nazwa",
    CompetitionPhases_PlaceholderTeams_Replace: "Wymień tę drużynę",
    CompetitionPhases_PlaceholderTeams_Replace_Failure: "Nie udało się wymienić miejsca na drużynę.",
    CompetitionPhases_PlaceholderTeams_Replace_Select_Replace: "Zamień",
    CompetitionPhases_PlaceholderTeams_Replace_Success: "Miejsce na drużynę zostało wymienione.",
    CompetitionPhases_PlanSchedule: "Zaplanuj terminarz",
    CompetitionPhases_PlanSchedule_AddBreak: "Dodaj przerwę",
    CompetitionPhases_PlanSchedule_AddBreak_Form_BreakDuration: "Długość przerwy",
    CompetitionPhases_PlanSchedule_AddBreak_Form_Title: "Dodaj przerwę",
    CompetitionPhases_PlanSchedule_AddMatchday: "Dodaj dzień meczowy",
    CompetitionPhases_PlanSchedule_AddMatchday_Form_Title: "Dodawanie dnia meczowego",
    CompetitionPhases_PlanSchedule_AutomaticSchedulePlanning_Form_ClosingTime: "Do:",
    CompetitionPhases_PlanSchedule_AutomaticSchedulePlanning_Form_OkText: "Rozmieść",
    CompetitionPhases_PlanSchedule_AutomaticSchedulePlanning_Form_Title: "Wybór boisk",
    CompetitionPhases_PlanSchedule_AutomaticSchedulePlanning_Plan: "Rozmieść",
    CompetitionPhases_PlanSchedule_AutomaticSchedulePlanning_SchedulePartiallyPlanned: "Nie udało się rozmieścić częsci meczów. Przenieś nieprzypisane mecze ręcznie.",
    CompetitionPhases_PlanSchedule_Board_Unassigned: "Nieprzypisane",
    CompetitionPhases_PlanSchedule_Break: "Przerwa",
    CompetitionPhases_PlanSchedule_ClearSportsFields: "Wyczyść meczom daty",
    CompetitionPhases_PlanSchedule_Form_Matchday: "Dzień",
    CompetitionPhases_PlanSchedule_Form_MatchDurationInMinutes: "Długość meczu",
    CompetitionPhases_PlanSchedule_Form_PauseBetweenMatchesInMinutes: "Długość przerwy",
    CompetitionPhases_PlanSchedule_DefaultSportsFieldName: "Boisko {0}",
    CompetitionPhases_PlanSchedule_Errors_MatchDatesNotGenerated: "Uzupełnij konfigurację terminarza, aby wygenerować daty meczów.",
    CompetitionPhases_PlanSchedule_Errors_ScheduleNotPlannedForAnySportsField: "Brak zaplanowanych meczów.",
    CompetitionPhases_PlanSchedule_Failure: "Nie udało się zaplanować terminarza. Spróbuj ponownie później.",
    CompetitionPhases_PlanSchedule_Header: "Planowanie terminarza",
    CompetitionPhases_PlanSchedule_OpenWizard: "Przepisz ręcznie",
    CompetitionPhases_PlanSchedule_SportsField_Name: "Nazwa boiska",
    CompetitionPhases_PlanSchedule_SportsField_SetName: "Zmiana nazwy boiska",
    CompetitionPhases_PlanSchedule_Success: "Terminarz został zaplanowany.",
    CompetitionPhases_PlanSchedule_Wizard_SlotsFilling_Date: "Data i godzina",
    CompetitionPhases_PlanSchedule_Wizard_SlotsFilling_SportsField: "Boisko",
    CompetitionPhases_PlanSchedule_Wizard_SlotsFilling_Team1: "Drużyna 1",
    CompetitionPhases_PlanSchedule_Wizard_SlotsFilling_Team2: "Drużyna 2",
    CompetitionPhases_PlanSchedule_Wizard_SportsFields_MoveToSlotsFilling: "Dalej",
    CompetitionPhases_PlanSchedule_Wizard_SportsFields_SportsFields: "Boiska",
    CompetitionPhases_PlanSchedule_Wizard_Title: "Ręczne planowanie terminarza",
    CompetitionPhases_PlanSchedule_UnassignedMatchesFilter: "Filtry",
    CompetitionPhases_PlanSchedule_UnassignedMatchesFilter_Groups_Placeholder: "Wybierz grupy",
    CompetitionPhases_PlanSchedule_UnassignedMatchesFilter_GroupsOrPhases_Placeholder: "Wybierz grupy lub fazy",
    CompetitionPhases_RenamePhase_Failure: "Nie udało się zmienić nazwy fazy. Spróbuj ponownie później.",
    CompetitionPhases_RenamePhase_Form_Name: "Nazwa",
    CompetitionPhases_RenamePhase_Form_Title: "Edytuj nazwę fazy",
    CompetitionPhases_RenamePhase_Success: "Nazwa fazy została zmieniona.",
    CompetitionPhases_SaveTieBreakingOrder_Failure: "Nie udało się zapisać kolejności drużyn. Spróbuj ponownie później.",
    CompetitionPhases_SaveTieBreakingOrder_Success: "Kolejność zapisana",
    CompetitionPhases_Scoring_Defeats: "Porażki",
    CompetitionPhases_Scoring_Draws: "Remisy",
    CompetitionPhases_Scoring_Victories: "Zwycięstwa",
    CompetitionPhases_SetMatchDate_Failure: "Nie udało się ustawić daty meczu.",
    CompetitionPhases_SetMatchDate_Form_Add_Title: "Nadaj meczowi datę i godzinę",
    CompetitionPhases_SetMatchDate_Form_Edit_Title: "Edytuj datę i godzinę meczu",
    CompetitionPhases_SetMatchDate_Success: "Data meczu została ustawiona.",
    CompetitionPhases_SwapTeams_Failure: "Nie udało się wymienić drużyn. Spróbuj ponownie później.",
    CompetitionPhases_SwapTeams_ModalText: "Zamieniasz drużynę {0} na drużynę {1}. Spowoduje to, że {1} zastąpi {0} w każdym z meczów, a {1} zastąpi {0} oraz jeśli nie zależą do tych samych grup, to zamienią się w grupach.",
    CompetitionPhases_SwapTeams_ModalTitle: "Czy na pewno chcesz wymienić drużynę?",
    CompetitionPhases_SwapTeams_Success: "Wymieniono drużyny.",
    CompetitionPhases_SwapTeams_Swap: "Zamień",
    CompetitionPhases_SwapTeams_SwapTeams: "Zamień drużyny",
    CompetitionPhases_Table: "Tabela",
    CompetitionPhases_Table_AddMatch: "Dodaj mecz",
    CompetitionPhases_Table_AddMatch_Failure: "Nie udało się dodać meczu. Spróbuj ponownie później.",
    CompetitionPhases_Table_AddMatch_Form_Title: "Dodawanie meczu",
    CompetitionPhases_Table_AddMatch_Success: "Mecz został dodany.",
    CompetitionPhases_Table_Form_Date: "Data",
    CompetitionPhases_Table_Form_Matchday: "Numer kolejki meczu",
    CompetitionPhases_Table_Form_SportsField: "Boisko",
    CompetitionPhases_Table_AddTeams_LinkedTable: "Ze skojarzonej tabeli",
    CompetitionPhases_Table_AddTeams_PlaceInTable: "{0} miejsce",
    CompetitionPhases_TablePointsAndOrder: "Punkty i kolejność",
    CompetitionPhases_TableOrderModifiers: "Kolejność w razie remisu",
    CompetitionPhases_TableOrderModifiers_Manage: "Zarządzaj kolejnością",
    CompetitionPhases_TableOrderModifiers_TeamName: "Nazwa",
    CompetitionPhases_TablePointsModifiers: "Punkty",
    CompetitionPhases_TablePointsModifiers_Add: "Dodaj punkty",
    CompetitionPhases_TablePointsModifiers_Add_Failure: "Nie udało się dodać punktów uznaniowych. Spróbuj ponownie później.",
    CompetitionPhases_TablePointsModifiers_Add_Success: "Punkty uznaniowe zostały dodane.",
    CompetitionPhases_TablePointsModifiers_Delete_Confirmation_Content: "Czy na pewno chcesz usunąć punkty uznaniowe?",
    CompetitionPhases_TablePointsModifiers_Delete_Confirmation_Title: "Usuwanie punktów uznaniowych",
    CompetitionPhases_TablePointsModifiers_Delete_Failure: "Nie udało się usunąć punktów uznaniowych. Spróbuj ponownie później.",
    CompetitionPhases_TablePointsModifiers_Delete_Success: "Punkty uznaniowe zostały usunięte.",
    CompetitionPhases_TablePointsModifiers_Description: "Opis",
    CompetitionPhases_TablePointsModifiers_EmptyState: "Brak punktów uznaniowych.",
    CompetitionPhases_TablePointsModifiers_Form_Description: "Opis",
    CompetitionPhases_TablePointsModifiers_Form_Points: "Punkty",
    CompetitionPhases_TablePointsModifiers_Form_Points_PointsEqualZero: "Liczba punktów musi być różna od 0.",
    CompetitionPhases_TablePointsModifiers_Form_Team: "Drużyna",
    CompetitionPhases_TablePointsModifiers_Form_Title: "Dodawanie punktów",
    CompetitionPhases_TablePointsModifiers_Points: "Pkt",
    CompetitionPhases_TablePointsModifiers_Team: "Drużyna",
    CompetitionPhases_UpdateMatchday: "Edytuj numer kolejki meczu",
    CompetitionPhases_UpdateMatchday_Failure: "Nie udało się edytować numeru kolejki meczu",
    CompetitionPhases_UpdateMatchday_Matchday: "Numer kolejki meczu",
    CompetitionPhases_UpdateMatchday_Success: "Numer kolejki meczu został edytowany.",
    CompetitionPhases_UpdateScoring: "Zmień konfigurację punktów",
    Competitions_AddNew: "Dodaj rozgrywki",
    Competitions_CompetitionsWithoutGroup: "Nieprzypisane do żadnej Grupy rozgrywkowej",
    Competitions_Create_AddNewSeason: "Dodaj nowy sezon",
    Competitions_Create_CompetitionGroup: "Grupa rozgrywkowa",
    Competitions_Create_Failure: "Nie udało się dodać rozgrywek do systemu. Spróbuj ponownie później.",
    Competitions_Create_Name: "Nazwa rozgrywek",
    Competitions_Create_Season: "Sezon",
    Competitions_Create_Success: "Rozgrywki zostały dodane do systemu.",
    Competitions_Create_Title: "Dodaj rozgrywki",
    Competitions_Copy: "Kopiuj rozgrywki",
    Competitions_Copy_FirstMatchDay: "Pierwszy dzień meczowy",
    Competitions_Copy_Failure: "Kopiowanie rozgrywek się nie powiodło. Spróbuj ponownie później.",
    Competitions_Copy_Success: "Rozgrywki zostały skopiowane.",
    Competitions_Copy_Title: "Kopiuj rozgrywki",
    Competitions_Copy_Ok: "Kopiuj",
    Competitions_Edit: "Edytuj rozgrywki",
    Competitions_Edit_Failure: "Nie udało się edytować rozgrywek. Spróbuj ponownie później.",
    Competitions_Edit_Success: "Rozgrywki zostały zaktualizowane.",
    Competitions_Edit_Title: "Edytuj rozgrywki",
    Competitions_Hide: "Usuń rozgrywki",
    Competitions_Hide_Confirmation_Content: "Nie będą one widoczne i zarówno Ty, jak i użytkownicy strony i aplikacji stracą do nich dostęp.",
    Competitions_Hide_Confirmation_Title: "Czy na pewno chcesz usunąć rozgrywki?",
    Competitions_Hide_Failure: "Nie udało się usunąć rozgrywek. Spróbuj ponownie później.",
    Competitions_Hide_Success: "Rozgrywki zostały usunięte.",
    Competitions_Referees: "Sędziowie",
    Competitions_Reorder: "Zmień kolejność rozgrywek",
    Competitions_Reorder_Failure: "Nie udało się zmienić kolejności rozgrywek w sezonie. Spróbuj ponownie później.",
    Competitions_Reorder_Loading: "Zapisywanie kolejności rozrywek.",
    Competitions_Reorder_Success: "Kolejność rozgrywek w sezonie została zmieniona.",
    Competitions_SeasonSelection_NoSeason: "Brak sezonu",
    Competitions_SeasonSelection_ShowAllSeasons: "Pokaż wszystkie sezony",
    Competitions_Title: "Rozgrywki",
    CompetitionSettings: "Ustawienia",
    CompetitionSettings_PlayerRankings: "Rankingi graczy",
    CompetitionSettings_PlayerRankings_Assists: "Asystenci",
    CompetitionSettings_PlayerRankings_Canadian: "Klasyfikacja kanadyjska",
    CompetitionSettings_PlayerRankings_Export_Error: "Nie udało się wyeksportować rankingu graczy. Spróbuj ponownie później.",
    CompetitionSettings_PlayerRankings_Filename: "Rankingi {0} {1}.xlsx",
    CompetitionSettings_PlayerRankings_Goals: "Strzelcy",
    CompetitionSettings_PlayerRankings_Goalkeeping: "Bramkarze",
    CompetitionSettings_PlayerRankings_MatchMvps: "MVP meczu",
    CompetitionSettings_PlayerRankings_RedCards: "Czerwone kartki",
    CompetitionSettings_PlayerRankings_TeamMvps: "MVP drużyny",
    CompetitionSettings_PlayerRankings_YellowCards: "Żołte kartki",
    CompetitionSettings_Update_Failure: "Nie udało się zmienić ustawień rozgrywek. Spróbuj ponownie później.",
    CompetitionSettings_Update_Success: "Ustawienia rozgrywek zostały zaktualizowane.",
    CompetitionTeams_Admin: "Admin",
    CompetitionTeams_Admin_Add: "Dodaj administratora",
    CompetitionTeams_Admin_CancelInvitation: "Anuluj zaproszenie",
    CompetitionTeams_Admin_EmptyState: "Brak administratorów drużyny.",
    CompetitionTeams_Admin_InvitationSent: "Wysłano",
    CompetitionTeams_Admin_PhoneNumber: "Tel.",
    CompetitionTeams_Admin_CancelInvitation_Failure: "Nie udało się anulować zaproszenia do bycia administratorem drużyny. Spróbuj ponownie później.",
    CompetitionTeams_Admin_CancelInvitation_InvitationNotPending: "Zaproszenie zostało już przyjęte lub odrzucone.",
    CompetitionTeams_Admin_CancelInvitation_Success: "Zaproszenie do bycia administratorem drużyny zostało anulowane.",
    CompetitionTeams_Admin_FirstName: "Imię (z Profilu)",
    CompetitionTeams_Admin_Invite_Failure: "Nie udało się dodać administratora. Spróbuj ponownie później.",
    CompetitionTeams_Admin_Invite_Form_OkText: "Mianuj",
    CompetitionTeams_Admin_Invite_Form_Title: "Dodaj administratora drużyny",
    CompetitionTeams_Admin_Invite_InvitationAlreadyExists: "Użytkownik o podanym numerze telefonu został już zaproszony do bycia administratorem.",
    CompetitionTeams_Admin_Invite_Success: "Zaproszenie do bycia administratorem drużyny zostało wysłane.",
    CompetitionTeams_Admin_LastName: "Nazwisko",
    CompetitionTeams_Admin_Remove: "Odbierz uprawnienia admina",
    CompetitionTeams_Admin_Remove_Confirm_Content: "Czy na pewno chcesz odebrać uprawnienia admina drużyny kontu: {0} {1}?",
    CompetitionTeams_Admin_Remove_Confirm_OkText: "Odbierz",
    CompetitionTeams_Admin_Remove_Confirm_Title: "Odbieranie uprawnienia admina",
    CompetitionTeams_Admin_Remove_Failure: "Nie udało się odebrać uprawnień administratora drużyny. Spróbuj ponownie później.",
    CompetitionTeams_Admin_Remove_Success: "Uprawnienia administratora drużyny zostały odebrane.",
    CompetitionTeams_CreateNew_AgeGroup: "Rocznik",
    CompetitionTeams_CreateNew_Name: "Nazwa drużyny",
    CompetitionTeams_CreateNew_ShortName: "Krótka nazwa",
    CompetitionTeams_CreateNew_Title: "Dodaj drużynę",
    CompetitionTeams_Edit_Title: "Edytuj drużynę",
    CompetitionTeams_Photo: "Zdjęcie",
    CompetitionTeams_Photo_Remove_Confirmation_Content: "Czy na pewno chcesz usunąć zdjęcie drużyny?",
    CompetitionTeams_Photo_Remove_Confirmation_Title: "Usuwanie zdjęcia drużyny",
    CompetitionTeams_Photo_Remove_Failure: "Nie udało się usunąć zdjęcia drużyny. Spróbuj ponownie później.",
    CompetitionTeams_Photo_Remove_Success: "Zdjęcie drużyny zostało usunięte.",
    CompetitionTeams_Merge_Error_Overlap: "Drużyny nie mogły zostać scalone, ponieważ brały udział w tych samych rozgrywkach.",
    CompetitionTeams_Merge_Failure: "Nie udało się scalić drużyn. Spróbuj ponownie później.",
    CompetitionTeams_Merge_Success: "Drużyny zostały scalone.",
    CompetitionTeams_Photo_Update_Failure: "Nie udało się zaktualizować zdjęcia drużyny.",
    CompetitionTeams_Photo_Update_InProgress: "Trwa aktualizacja zdjęcia drużyny.",
    CompetitionTeams_Photo_Update_Success: "Zdjęcie drużyny zostało zaktualizowane.",
    CompetitionTeams_Photo_Upload_AddNew: "Kliknij lub przeciągnij plik w celu dodania zdjęcia drużyny.",
    CompetitionTeams_Photo_Upload_Update: "Kliknij lub przeciągnij plik w celu zmiany zdjęcia drużyny.",
    CompetitionTeams_PlaceholderTeam: "Miejsce na drużynę",
    CompetitionTeams_Players: "Zawodnicy",
    CompetitionTeams_RemovePlayer: "Usuń zawodnika",
    CompetitionTeams_RemovePlayer_Confirmation_Title: "Usuń zawodnika",
    CompetitionTeams_RemovePlayer_Confirmation_Content: "Czy na pewno chcesz usunąć zawodnika: {0} {1}?",
    CompetitionTeams_RemovePlayer_Failure: "Nie udało się usunąć zawodnika z drużyny. Spróbuj ponownie później.",
    CompetitionTeams_RemovePlayer_Success: "Zawodnik został usunięty z drużyny.",
    CompetitionTeams_Select_AddNewTeam: "Dodaj nową drużynę",
    CompetitionTeams_Select_AddPlaceholderTeam: "Dodaj wolne miejsce",
    CompetitionTeams_Select_NewTeams: "Nowe drużyny",
    CompetitionTeams_Select_OtherTeams: "Pozostałe drużyny",
    CompetitionTeams_TeamDeleted: "Usunięto",
    CompetitionTeams_UpdateLogo_Failed: "Zmiana logo drużyny {0} nie powiodła się. Spróbuj ponownie później.",
    CompetitionTeams_UpdateLogo_InProgress: "Trwa aktualizacja loga drużyny: {0}",
    CompetitionTeams_UpdateLogo_Success: "Logo drużyny {0} zostało zmienione.",
    CompetitionTeamPlayers_Account: "Konto",
    CompetitionTeamPlayers_AddPlayer: "Dodaj zawodnika",
    CompetitionTeamPlayers_AddPlayers_Failure: "Nie udało się dodać zawodników do drużyny.",
    CompetitionTeamPlayers_AddPlayers_PartialFailure: "Część zawodników nie została dodana do rozgrywek.",
    CompetitionTeamPlayers_AddPlayers_Success: "Zawodnicy zostali dodani do drużyny.",
    CompetitionTeamPlayers_CancelInvitation: "Anuluj zaproszenie",
    CompetitionTeamPlayers_CancelInvitation_Failure: "Nie udało się anulować zaproszenia. Spróbuj ponownie później.",
    CompetitionTeamPlayers_CancelInvitation_InvitationNotPending: "Zaproszenie zostało już przyjęte lub odrzucone przez zawodnika.",
    CompetitionTeamPlayers_CancelInvitation_Success: "Zaproszenie zostało anulowane.",
    CompetitionTeamPlayers_CancelInvitation_Confirm_CancelText: "Wróć",
    CompetitionTeamPlayers_CancelInvitation_Confirm_Content: "Czy na pewno chcesz anulować zaproszenie?",
    CompetitionTeamPlayers_CancelInvitation_Confirm_OkText: "Anuluj zaproszenie",
    CompetitionTeamPlayers_CancelInvitation_Confirm_Title: "Anulowanie zaproszenia",
    CompetitionTeamPlayers_CreateNew_ShirtNumberError: "Numer koszulki musi być liczbą większą lub równą 0.",
    CompetitionTeamPlayers_CreateNew_Title: "Dodaj zawodnika",
    CompetitionTeamPlayers_Edit: "Edytuj zawodnika",
    CompetitionTeamPlayers_Edit_Failure: "Nie udało się edytować zawodnika spróbuj ponownie później.",
    CompetitionTeamPlayers_Edit_Success: "Zawodnik został edytowany.",
    CompetitionTeamPlayers_FirstAndLastNameEditionDisabled: "Istnieje konto połączone z tym zawodnikiem. Imię i nazwisko może być edytowane tylko przez użytkownika w aplikacji dla graczy.",
    CompetitionTeamPlayers_FirstName: "Imię",
    CompetitionTeamPlayers_InvitePlayer: "Zaproś",
    CompetitionTeamPlayers_Invite_Failure: "Nie udało się wysłać zaproszenia do zawodnika. Spróbuj ponownie później.",
    CompetitionTeamPlayers_Invite_Form_OkText: "Zaproś",
    CompetitionTeamPlayers_Invite_Form_Title: "Wyślij zaproszenie do zawodnika",
    CompetitionTeamPlayers_Invite_InvitationAlreadyExists: "Użytkownik o podanym numerze telefonu został już zaproszony.",
    CompetitionTeamPlayers_Invite_Success: "Zaproszenie zostało wysłane do zawodnika.",
    CompetitionTeamPlayers_LastName: "Nazwisko",
    CompetitionTeamPlayers_MergeProfiles: "Scal zawodników",
    CompetitionTeamPlayers_MergeProfiles_Confirmation_Content: "Czy na pewno chcesz scalić zawodników? Skutków tej operacji nie można cofnąć.",
    CompetitionTeamPlayers_MergeProfiles_Confirmation_OkText: "Scal zawodników",
    CompetitionTeamPlayers_MergeProfiles_Confirmation_Title: "Potwierdzenie scalenia",
    CompetitionTeamPlayers_MergeProfiles_Failure: "Nie udało się scalić zawodników. Spróbuj ponownie później.",
    CompetitionTeamPlayers_MergeProfiles_Form_OkText: "Scal",
    CompetitionTeamPlayers_MergeProfiles_Form_Player: "Zawodnik",
    CompetitionTeamPlayers_MergeProfiles_Form_Player_EmptyState: "Brak zawodników o imieniu i nazwisku {0} do scalenia.",
    CompetitionTeamPlayers_MergeProfiles_Form_Title1: "Scal",
    CompetitionTeamPlayers_MergeProfiles_Form_Title2: "z:",
    CompetitionTeamPlayers_MergeProfiles_Success: "Zawodnicy zostali scaleni.",
    CompetitionTeamPlayers_NoPlayersInTeam: "Brak zawodników w drużynie.",
    CompetitionTeamPlayers_PlayerInvited: "Zaproszono",
    CompetitionTeamPlayers_ProfilePictures_Add_Failure: "Nie udało się dodać zdjęcia profilowego. Spróbuj ponownie później.",
    CompetitionTeamPlayers_ProfilePictures_Add_InProgress: "Trwa dodawanie zdjęcia profilowego.",
    CompetitionTeamPlayers_ProfilePictures_Add_Success: "Zdjęcie profilowe zostało dodane.",
    CompetitionTeamPlayers_ProfilePictures_Select_Failure: "Nie udało się ustawić zdjęcia profilowego użytkownika.",
    CompetitionTeamPlayers_ProfilePictures_Upload: "Kliknij lub przeciągnij pliki w celu dodania zdjęcia profilowego.",
    CompetitionTeamPlayers_ShirtNumber: "Nr na koszulce",
    CompetitionTeamPlayers_Select_AddNewPlayer: "Dodaj nowego zawodnika",
    CompetitionTeamPlayers_Select_NewPlayers: "Nowi zawodnicy",
    CompetitionTeamPlayers_Select_OtherTeamsPlayers: "Zawodnicy z innych drużyn",
    CompetitionTeamPlayers_Select_PreviousCompetitionsPlayers: "Zawodnicy z {0}",
    Global_Cancel: "Anuluj",
    Global_NetworkError: "Wystąpił błąd połączenia.",
    Global_Ok: "OK",
    ImageUpload_CropModalTitle: "Przycinanie zdjęcia",
    ImageUpload_InvalidFormat: "Niepoprawny format pliku. Podaj plik z rozszerzeniem: .png, .jpg, .gif, .jpeg lub .apng",
    Landing: "Strona",
    Landing_Banners: "Reklamy",
    Landing_Banners_Add: "Dodaj reklamę",
    Landing_Banners_Add_Failure: "Nie udało się dodać reklamy. Spróbuj ponownie później.",
    Landing_Banners_Add_Success: "Reklama została dodana.",
    Landing_Banners_Delete_Failure: "Nie udało się usunąć reklamy. Spróbuj ponownie później.",
    Landing_Banners_Delete_Success: "Reklama została usunięta.",
    Landing_Banners_Edit_Failure: "Nie udało się edytować reklamy. Spróbuj ponownie później.",
    Landing_Banners_Edit_Success: "Reklama została edytowana.",
    Landing_Banners_Form_Add: "Dodawanie reklamy.",
    Landing_Banners_Form_Edit: "Edycja reklamy.",
    Landing_Banners_Form_Link: "Link",
    Landing_Banners_Form_Picture: "Reklama",
    Landing_Banners_Form_Validation_LinkNotValid: "Niepoprawny link",
    Landing_Banners_Skeleton_News: "Aktualności",
    Landing_Banners_Skeleton_Videos: "Video",
    Landing_Partners: "Loga",
    Landing_Partners_Add_Failure: "Nie udało się dodać loga. Spróbuj ponownie później.",
    Landing_Partners_Add_Success: "Logo zostało dodane.",
    Landing_Partners_Delete_Confirmation_Content: "Czy na pewno chcesz usunąć logo?",
    Landing_Partners_Delete_Confirmation_Title: "Usuwanie loga",
    Landing_Partners_Delete_Failure: "Nie udało się usunąć loga. Spróbuj ponownie później.",
    Landing_Partners_Delete_Success: "Logo zostało usunięte.",
    Landing_Partners_UpdateLink: "Edytuj link",
    Landing_Partners_UpdateLink_Failure: "Nie udało się edytować linku. Spróbuj ponownie później.",
    Landing_Partners_UpdateLink_Form_Link: "Link",
    Landing_Partners_UpdateLink_Form_Title: "Edycja linku",
    Landing_Partners_UpdateLink_Success: "Link został zmieniony.",
    Landing_Partners_UpdateLink_Validation_LinkNotValid: "Link jest nieprawidłowy.",
    Landing_Partners_UpdateLogo_Failure: "Nie udało się zaktualizować loga. Spróbuj ponownie później.",
    Landing_Partners_UpdateLogo_InProgress: "Aktualizacja loga.",
    Landing_Partners_UpdateLogo_Success: "Logo zostało zaktualizowane.",
    Landing_Partners_Link: "Link",
    Landing_Partners_Logo: "Logo",
    Landing_Partners_NavigateToLink: "Przejdź",
    LeagueEditions_ActivateTitle: "Aktywacja sezonu",
    LeagueEditions_ActivationConfirmation: "Czy napewno chcesz ustawić sezon {0} jako aktywny?",
    LeagueEditions_AddLeagueEdition_Title: "Dodaj sezon",
    LeagueEditions_EditLeagueEdition_Title: "Edytuj sezon",
    LeagueEditions_IsActive: "aktualnie trwa",
    LeagueEditions_LeagueEditionName: "Nazwa sezonu",
    LeagueEditions_StartDate: "Przybliżona data rozpoczęcia",
    LeagueEditions_StartEdition: "Rozpocznij",
    LeagueEditions_Title: "Sezony",
    LeagueEditionsDialog_Title: "Wybierz ligę i sezon",
    LeagueEditionSinglePage_ScheduleTitle: "Terminarz",
    LeagueEditionSinglePage_ShowAll: "Pokaż pełny",
    LeagueEditionSinglePage_ShowUpcoming: "Pokaż najbliższe",
    LeagueReferees_AddLeagueReferee: "Dodaj sędzięgo",
    LeagueReferees_AddLeagueReferee_Choose: "Wybierz sędziego...",
    LeagueReferees_AddLeagueReferee_NewReferee: "Dodaj nowego sędziego",
    LeagueReferees_AddLeagueReferee_NoOptions: "Brak sędziów",
    LeagueReferees_AddLeagueReferee_TenantReferees: "Sędziowie z innych lig",
    LeagueReferees_AddPhoneNumber: "Dodaj tel",
    LeagueReferees_AssignPhoneNumberToPlayer: "Dodaj numer telefonu sędziego",
    LeagueReferees_AssignPhoneNumberToPlayer_InvalidNumberError: "Podany numer jest niepoprawny",
    LeagueReferees_AssignPhoneNumberToPlayer_NumberIsUsedError: "Podany numer jest już przypisany sędziego w lidze",
    LeagueReferees_FirstName: "Imię",
    LeagueReferees_InvalidName: "Sędzia musi posiadać imię i/lub nazwisko",
    LeagueReferees_LastName: "Nazwisko",
    LeagueReferees_NameInvalidLength: "Imię lub nazwisko sędziego jest zbyt długie",
    LeagueReferees_RemoveLeagueReferee: "Usuń",
    LeagueReferees_RemoveLeagueReferee_Confirmation: "Czy napewno chcesz usunąć sędziego?",
    LeagueReferees_Title: "Sędziowie",
    LeagueReferees_UpdateLeagueReferee: "Edytuj dane sędziego",
    Leagues_AddLeague_Title: "Dodaj ligę",
    Leagues_CardMenu_EditLeague: "Edytuj nazwę ligi",
    Leagues_CardMenu_Referees: "Sędziowie",
    Leagues_EmptyState: "Liga jeszcze nie wystartowała",
    Leagues_Errors_EmptyName: "Nazwa nie może być pusta.",
    Leagues_Errors_InvalidNameLength: "Podana nazwa jest za długa.",
    Leagues_ShowEditions: "Zobacz sezony",
    Leagues_Title: "Ligi",
    Leagues_Tooltips_News: "Newsy",
    Leagues_Tooltips_Rankings: "Rankingi",
    Leagues_Tooltips_Schedule: "Terminarz",
    Leagues_Tooltips_Team: "Drużyny",
    Leagus_AddLeague_Name: "Nazwa ligi",
    MatchDetails_Article: "Relacja",
    MatchDetails_Article_CreateNew: "Dodaj relację",
    MatchDetails_Article_CreateNew_Failure: "Nie udało się dodać relacji meczu. Spróbuj ponownie później.",
    MatchDetails_Article_CreateNew_Success: "Relacja meczu została dodana.",
    MatchDetails_Article_Edit: "Edytuj",
    MatchDetails_Article_Edit_Failure: "Nie udało się edytować relacji meczu. Spróbuj ponownie później.",
    MatchDetails_Article_Edit_Success: "Relacja meczu została edytowana.",
    MatchDetails_Article_Edit_Title: "Edytuj relację",
    MatchDetails_Article_Preview: "Podgląd",
    MatchDetails_Article_Publish: "Opublikuj",
    MatchDetails_Article_Unpublish: "Cofnij publikację",
    MatchDetails_DeleteMatch: "Usuń mecz",
    MatchDetails_DeleteMatch_Confirmation_Content: "Czy na pewno chcesz usunąć mecz? Ta operacja jest nieodwracalna i stracisz wszystkie informacje związane  z meczem.",
    MatchDetails_DeleteMatch_Confirmation_OkText: "Usuń mecz",
    MatchDetails_DeleteMatch_Confirmation_Title: "Usuwanie meczu",
    MatchDetails_DeleteMatch_Failure: "Nie udało się usunąć meczu. Spróbuj ponownie później.",
    MatchDetails_DeleteMatch_Success: "Mecz został usunięty.",
    MatchDetails_EditMatch: "Edytuj mecz",
    MatchDetails_EditMatch_Failure: "Mecz został edytowany.",
    MatchDetails_EditMatch_Form_Title: "Edycja meczu",
    MatchDetails_EditMatch_Success: "Nie udało się edytować meczu. Spróbuj ponownie później.",
    MatchDetails_EditMatchReport: "Edytuj raport meczowy",
    MatchDetails_EditMatchReport_Failure: "Nie udało się edytować raportu meczowego. Spróbuj ponownie później.",
    MatchDetails_EditMatchReport_Success: "Raport meczowy został edytowany.",
    MatchDetails_EditMatchReport_Title: "Edycja raportu meczowego",
    MatchDetails_MatchEvents: "Zdarzenia meczowe",
    MatchDetails_Photos: "Galeria",
    MatchDetails_Photos_Edit: "Edytuj galerię meczu",
    MatchDetails_Photos_Title: "Galeria meczu",
    MatchDetails_PublishMatchReport: "Opublikuj raport meczowy",
    MatchDetails_PublishMatchReport_Failure: "Nie udało się opublikować raportu meczowego. Spróbuj ponownie później.",
    MatchDetails_PublishMatchReport_Form_Draw: "Remis",
    MatchDetails_PublishMatchReport_Form_FinalScore: "Wynik meczu",
    MatchDetails_PublishMatchReport_Form_HalfTimeScore: "Wynik do przerwy",
    MatchDetails_PublishMatchReport_Form_MatchMvp: "MVP meczu",
    MatchDetails_PublishMatchReport_Form_PenaltiesPlayed: "Karne",
    MatchDetails_PublishMatchReport_Form_PenaltiesPlayed_Checked: "Tak",
    MatchDetails_PublishMatchReport_Form_PenaltiesPlayed_Unchecked: "Nie",
    MatchDetails_PublishMatchReport_Form_TeamMvp: "MVP {0}",
    MatchDetails_PublishMatchReport_Form_Winner: "Zwycięzca meczu",
    MatchDetails_PublishMatchReport_Form_Validation_InvalidScore: "Pola wyniku muszą być poprawnymi liczbami.",
    MatchDetails_PublishMatchReport_Form_Validation_MatchCannotEndWithDraw: "Mecz nie może zakończyć się remisem.",
    MatchDetails_PublishMatchReport_Form_Validation_ScoreNotComplete: "Jedno z pól wyniku jest puste.",
    MatchDetails_PublishMatchReport_Lineup: "Skład",
    MatchDetails_PublishMatchReport_Lineup_SelectAll: "Zaznacz wszystkich",
    MatchDetails_PublishMatchReport_Lineup_Goalkeeper_Checked: "BR",
    MatchDetails_PublishMatchReport_Lineup_Goalkeeper_Tooltip: "Oznacz zawodnika jako bramkarza",
    MatchDetails_PublishMatchReport_MatchReport: "Raport meczowy",
    MatchDetails_PublishMatchReport_OkText: "Opublikuj",
    MatchDetails_PublishMatchReport_Success: "Raport meczowy został opublikowany.",
    MatchDetails_PublishMatchReport_Title: "Publikowanie raportu meczowego",
    MatchDetails_RecreateMatch: "Usuń raport meczowy",
    MatchDetails_RecreateMatch_Confirmation_Content: "Czy na pewno chcesz rozegrać ponownie mecz? Dotychczasowe dane zostaną usunięte permanentnie i nie będzie możliwe ich odtworzenie. W szczególności dotyczy to danych o wynikach, zdarzeniach meczowych czy zawodnikach.",
    MatchDetails_RecreateMatch_Confirmation_OkText: "Usuń na zawsze",
    MatchDetails_RecreateMatch_Confirmation_Title: "Usunięcie raportu meczowego",
    MatchDetails_RecreateMatch_Failure: "Nie udało się usunąć raportu meczowego. Spróbuj ponownie później.",
    MatchDetails_RecreateMatch_Success: "Raport meczowy został usunięty.",
    MatchDetails_RecreateMatch_Validation_NextStageMatchNotInUpcomingState: "Mecz w kolejnej fazie fazy pucharowej został już rozegrany. Aby usunąć raport meczowy usuń raport meczowy meczu z kolejnej fazy turnieju.",
    MatchDetails_Status: "Status",
    MatchDetails_Status_Canceled: "Odwołany",
    MatchDetails_Status_Finished: "Zakończony",
    MatchDetails_Status_Upcoming: "Zaplanowany",
    MatchDetails_Status_Ongoing: "Trwa",
    MatchDetails_TeamLineup_EmptyState: "Skład drużyny nie został dodany.",
    MatchDetails_TeamLineup_MatchWinner: "Zwycięzca",
    MatchDetails_TeamLineups: "Składy",
    MatchDetails_Title: "Szczegóły meczu",
    MatchDetails_Result_HalfTimeScore: "({0}:{1} do przerwy)",
    MatchDetails_Result_PenaltyScore: "({0}:{1} w karnych)",
    MatchEvent_Add: "Dodaj zdarzenie meczowe",
    MatchEvent_Add_Failure: "Nie udało się dodać zdarzenia meczowego. Spróbuj ponownie później.",
    MatchEvent_Add_Success: "Zdarzenie meczowe zostało dodane.",
    MatchEvent_Add_Validation_KnockoutPhaseMatchesCannotHaveWinnerChanged: "Dodanie tego zdarzenia meczowego skutkuje zmianą zwycięzcy meczu. W fazie pucharowej zmiana zwycięzcy meczu jest niemożliwa.",
    MatchEvent_Delete_Confirmation_Title: "Usuwanie zdarzenia meczowego",
    MatchEvent_Delete_Confirmation_Content: "Czy na pewno chcesz usunąć zdarzenie meczowe?",
    MatchEvent_Delete_Failure: "Nie udało się usunąć zdarzenia meczowego. Spróbuj ponownie później.",
    MatchEvent_Delete_Success: "Zdarzenie meczowe zostało usunięte.",
    MatchEvent_Delete_Validation_KnockoutPhaseMatchesCannotHaveWinnerChanged: "Usunięcie tego zdarzenia meczowego skutkuje zmianą zwycięzcy meczu. W fazie pucharowej zmiana zwycięzcy meczu jest niemożliwa.",
    MatchEvent_Add_Title: "Dodaj zdarzenie meczowe",
    MatchEvent_Edit_Failure: "Nie udało się edytować zdarzenia meczowego. Spróbuj ponownie później.",
    MatchEvent_Edit_Goalkeeper: "Bramkarz",
    MatchEvent_Edit_Minute: "Minuta",
    MatchEvent_Edit_OtherPlayer_Assistant: "Asystujący",
    MatchEvent_Edit_OtherPlayer_Fouled: "Faulowany",
    MatchEvent_Edit_PenaltyValue: "Kara minutowa",
    MatchEvent_Edit_Player: "Zawodnik",
    MatchEvent_Edit_Second: "Sekunda",
    MatchEvent_Edit_Side: "Drużyna",
    MatchEvent_Edit_Subtype: "Podtyp",
    MatchEvent_Edit_Success: "Zdarzenie meczowe zostało edytowane.",
    MatchEvent_Edit_Title: "Edytuj zdarzenie meczowe",
    MatchEvent_Edit_Type: "Typ zdarzenia",
    MatchEvent_Edit_Validation_KnockoutPhaseMatchesCannotHaveWinnerChanged: "Edycja tego zdarzenia meczowego skutkuje zmianą zwycięzcy meczu. W fazie pucharowej zmiana zwycięzcy meczu jest niemożliwa.",
    MatchEvent_Subtype_Goal_FollowUp: "Dobitka",
    MatchEvent_Subtype_Goal_FreeKick: "Z rzutu wolnego",
    MatchEvent_Subtype_Goal_FromNear: "Z bliska",
    MatchEvent_Subtype_Goal_FromDistance: "Z daleka",
    MatchEvent_Subtype_Goal_Head: "Z główki",
    MatchEvent_Subtype_Shot_OnTarget: "Celny",
    MatchEvent_Subtype_Goal_Own: "Samobój",
    MatchEvent_Subtype_Goal_Penalty: "Z rzutu karnego",
    MatchEvent_Subtype_Goal_Unspecified: "Gol",
    MatchEvent_Subtype_Shot_OffTarget: "Niecelny",
    MatchEvent_Subtype_Shot_GoalPost: "W słupek",
    MatchEvent_Subtype_Shot_Crossbar: "W poprzeczkę",
    MatchEvent_Type_Foul: "Faul",
    MatchEvent_Type_Goal: "Gol",
    MatchEvent_Type_MinutesPenalty: "Kara minutowa",
    MatchEvent_Type_RedCard: "Czerwona kartka",
    MatchEvent_Type_Shot: "Strzał",
    MatchEvent_Type_YellowCard: "Żółta kartka",
    Menu_AccessCode: "Kod organizatora",
    Menu_Competitions: "Rozgrywki",
    Photos_Gallery_Delete_Confirmation_Content: "Czy napewno chcesz usunąć zdjęcie?",
    Photos_Gallery_Delete_Confirmation_Title: "Usuwanie zdjęcia",
    Photos_Gallery_Delete_Failure: "Nie udało się usunąć zdjęcia. Spróbuj ponownie później.",
    Photos_Gallery_Delete_Success: "Zdjęcie zostało usunięte.",
    Photos_Gallery_ImageCountSeparator: " z ",
    Photos_Upload_Failure: "Nie udało się dodać zdjęć. Spróbuj ponownie później.",
    Photos_Upload_InProgress: "Trwa dodawanie zdjęć.",
    Photos_Upload_PartialSuccess: "Nie udało się dodać niektórych zdjęć.",
    Photos_Upload_Success: "Zdjęcia zostały dodane.",
    Photos_Upload_Text: "Kliknij lub przeciągnij pliki w celu dodania zdjęć do galerii",
    Registrations: "Zapisy",
    Registrations_Add: "Dodaj zapisy",
    Registrations_Add_Failure: "Nie udało się dodać zapisów. Spróbuj ponownie później.",
    Registrations_Add_Success: "Zapisy zostały dodane.",
    Registrations_AgeGroups_Open: "Open",
    Registrations_Applications_AgeGroups: "Roczniki",
    Registrations_Applications_Email: "Email",
    Registrations_Applications_EmptyText: "Brak zgłoszeń",
    Registrations_Applications_PhoneNumber: "Telefon",
    Registrations_Applications_SubmittedAt: "Data zgłoszenia",
    Registrations_Applications_TeamCaptainOrCoachFullName: "Trener / Kapitan",
    Registrations_Applications_TeamName: "Nazwa drużyny",
    Registrations_Close: "Zakończ",
    Registrations_Close_Failure: "Nie udało się zakończyć zapisów. Spróbuj ponownie później.",
    Registrations_Close_Success: "Zapisy zostały zakończone.",
    Registrations_CancelHighlight: "Anuluj wyróżnienie",
    Registrations_CancelHighlight_Failure: "Nie udało się anulować wyróżnienia zapisów. Spróbuj ponownie później.",
    Registrations_CancelHighlight_Success: "Wyróżnienie zapisów zostało anulowane.",
    Registrations_Edit: "Edycja zapisów",
    Registrations_Edit_Failure: "Nie udało się edytować zapisów. Spróbuj ponownie później.",
    Registrations_Edit_Success: "Zapisy zostały edytowane.",
    Registrations_Form_Address: "Adres",
    Registrations_Form_AgeGroups: "Kategorie wiekowe",
    Registrations_Form_Article: "Artykuł",
    Registrations_Form_ArticleTitle: "Tytuł artykułu",
    Registrations_Form_BackgroundPhoto: "Zdjęcie w tle",
    Registrations_Form_City: "Miasto",
    Registrations_Form_Documents: "Dokumenty",
    Registrations_Form_Documents_LoadFile: "Wczytaj plik",
    Registrations_Form_Documents_RequiresAcceptance: "Do akceptacji",
    Registrations_Form_Documents_Validation_OneOfDocumentsNameMissingOrEmpty: "Nazwy dokumentów muszą być niepuste.",
    Registrations_Form_Documents_Validation_OneOfDocumentsNameTooLong: "Nazwy dokumentów powinny zawierać maksymalnie 40 znaków.",
    Registrations_Form_EndDate: "Data zakończenia",
    Registrations_Form_EntryFee: "Cena",
    Registrations_Form_ExtraFormFieldDescription: "Dodatkowe pole formularza - opis",
    Registrations_Form_Logo: "Logo",
    Registrations_Form_MaxTeamsCount: "Maks. l. drużyn",
    Registrations_Form_SportsVenueName: "Nazwa obiektu",
    Registrations_Form_StartDate: "Data startu",
    Registrations_Form_Summary: "Krótki opis",
    Registrations_Form_Title: "Tytuł",
    Registrations_Form_ThumbnailPhoto: "Miniaturka",
    Registrations_Form_UploadDocument_Error: "Nie udało się dodać dokumentu.",
    Registrations_Form_UploadPhoto_Error: "Nie udało się dodać zdjęcia.",
    Registrations_Highlight: "Wyróżnij",
    Registrations_Highlight_Failure: "Nie udało się wyróżnić zapisów. Spróbuj ponownie później.",
    Registrations_Highlight_Success: "Zapisy zostały wyróżnione.",
    Registrations_IsClosed: "Zakończone",
    Registrations_IsHighlighted: "Wyróżnione",
    Registrations_IsOpen: "Trwają",
    Registrations_Open: "Wznów",
    Registrations_Open_Failure: "Nie udało się wznowić zapisów. Spróbuj ponownie później.",
    Registrations_Open_Success: "Zapisy zostały wznowione.",
    Registrations_StartDate: "Data startu",
    Registrations_Title: "Tytuł",
    Seasons: "Sezony",
    Seasons_Create: "Dodaj sezon",
    Seasons_Create_ExpectedStartDate: "Przybliżona data startu",
    Seasons_Create_Failure: "Nie udało się dodać sezonu do systemu. Spróbuj ponownie później.",
    Seasons_Create_Name: "Nazwa",
    Seasons_Create_Success: "Sezon został dodany do systemu.",
    Seasons_Create_Title: "Dodaj nowy sezon",
    Seasons_Edit: "Edytuj sezon",
    Seasons_Edit_Failure: "Nie udało się edytować sezonu. Spróbuj ponownie później.",
    Seasons_Edit_Success: "Sezon został zedytowany.",
    Seasons_Edit_Title: "Edytuj sezon",
    Seasons_Hide: "Usuń sezon",
    Seasons_Hide_Confirmation_Content: "Nie będzie on widoczny i zarówno Ty, jak i użytkownicy strony i aplikacji stracą do niego dostęp.",
    Seasons_Hide_Confirmation_Title: "Czy na pewno chcesz usunąć sezon?",
    Seasons_Hide_Failure: "Nie udało się usunąć sezonu. Spróbuj ponownie później.",
    Seasons_Hide_Success: "Sezon został usunięty.",
    Seasons_Export: "Eksportuj terminarz",
    Seasons_Export_Error: "Nie udało się wyeksportować terminarza. Spróbuj ponownie później.",
    Seasons_Export_Filename: "{0} Terminarz {1}.xlsx",
    Seasons_SchedulePlanner: "Planer",
    Seasons_State_Next: "Następny",
    Seasons_State_Ongoing: "Trwa",
    Seasons_Title: "Sezony",
    Seasons_UpdateState_Default: "Ustaw jako zakończony",
    Seasons_UpdateState_Failure: "Nie udało się zaktualizować stanu sezonu. Spróbuj ponownie później.",
    Seasons_UpdateState_Next: "Ustaw jako następny",
    Seasons_UpdateState_Ongoing: "Ustaw jako trwający",
    Seasons_UpdateState_Success: "Stan sezonu został zaktualizowany.",
    SignIn_Base_Password: "Hasła nie są identyczne",
    SignIn_Check_Inbox: "Sprawdź skrzynkę email",
    SignIn_Diffrent_Passwords_Error: "Hasła nie są identyczne",
    SignIn_Email: "Email",
    SignIn_IncorrectCredentials: "Podane dane logowania są niepoprawne.",
    SignIn_Login: "Login",
    SignIn_NetworkError: "Wystąpił błąd podczas logowania. Spróbuj ponownie później.",
    SignIn_New_Password: "Hasło",
    SignIn_Or: "lub",
    SignIn_Password: "Hasło",
    SignIn_Password_Recovery: "Przypomnij hasło",
    SignIn_Password_Reseted: "Hasło zostało ustawione.",
    SignIn_Repeate_New_Password: "Powtórz hasło",
    SignIn_ResetPassword_Failure: "Nie udało się ustawić hasła. Spróbuj ponownie później.",
    SignIn_ResetPassword_LinkExpired: "Link do ustawienia hasła wygasł.",
    SignIn_Send_Password_Recovery_Link: "Wyślij link",
    SignIn_Send_Reset_Password: "Ustaw hasło",
    SignIn_Send_Reset_Password_Failure: "Nie udało się wysłać linku do resetu hasła. Spróbuj ponownie później.",
    SignIn_Sent_Reset_Password_Email: "Wysłaliśmy Ci link do resetu hasła",
    SignIn_SignIn: "Zaloguj",
    SignIn_SignUp: "Zarejestruj",
    SignIn_Too_Short_Password_Error: "Hasło musi mieć min. 8 znaków",
    SignUp_Form_Email: "Email",
    SignUp_Form_RegistrationFailed: "Nie udało się utworzyć nowego konta organizacji. Spróbuj ponownie później.",
    SignUp_Form_Regulations_Part1: "Akceptuję ",
    SignUp_Form_Regulations_Part2: "Regulamin",
    SignUp_Form_Regulations_Part3: " i ",
    SignUp_Form_Regulations_Part4: "Politykę Prywatności",
    SignUp_Form_Regulations_ValidationError: "Akceptacja regulaminu i polityki prywatności jest wymagana w celu stworzenia konta.",
    SignUp_Form_Submit: "Zarejestruj",
    SignUp_Form_TenantName: "Nazwa organizatora",
    SignUp_Form_TenantNameTooLong: "Nazwa organizatora nie może być dłuższa niż 20 znaków.",
    SignUp_Form_Title: "Tworzenie konta organizacji",
    SignUp_GoToSignIn: "Powrót do logowania",
    SignUp_Success_Title: "Dokończ rejestrację klikając link przesłany na podany przez Ciebie adres email",
    SignUp_TenantExistence: "Czy istnieje już jakieś konto w Twojej organizacji?",
    SignUp_TenantExistence_Yes: "Tak",
    SignUp_TenantExistence_No: "Nie",
    SignUp_TenantExists_Title: "Poproś osobę, która ma już konto w Twojej organizacji o dodanie Cię do konta organizacji.",
    Standing_GoalsAgainst: "BS",
    Standings_AllGoals: "Br",
    Standings_Assists: "A",
    Standings_Draws: "R",
    Standings_Goals: "B",
    Standings_GoalsFor: "BZ",
    Standings_Losses: "P",
    Standings_Matches: "M",
    Standings_Player: "Zawodnik",
    Standings_Points: "P",
    Standings_Position: "#",
    Standings_Team: "Drużyna",
    Standings_TopAssistProviders: "Asystenci",
    Standings_TopScorers: "Strzelcy",
    Standings_Wins: "Z",
    Table_TriggerAsc: "Sortuj rosnąco",
    Table_TriggerDesc: "Sortuj malejąco",
    Table_CancelSort: "Wyłącz sortowanie",
    Teams_AddPhoneNumber: "Dodaj tel",
    Teams_AddTeamPlayer: "Dodaj zawodnika",
    Teams_AddTeamPlayer_Choose: "Wybierz zawodnika...",
    Teams_AddTeamPlayer_NewPlayer: "Dodaj nowego zawodnika",
    Teams_AddTeamPlayer_NoOptions: "Brak zespołów",
    Teams_AddTeams: "Dodaj zespoły",
    Teams_AddTeams_Choose: "Wybierz zespoły...",
    Teams_AddTeams_LastEdition: "Poprzedni sezon",
    Teams_AddTeams_NewTeam: 'Dodaj nowy zespół "{0}"',
    Teams_AddTeams_NoOptions: "Brak zespołów",
    Teams_AddTeams_PastEditions: "Wcześniejsze sezony",
    Teams_AddTeams_Short: "Dodaj",
    Teams_AssignPhoneNumberToPlayer: "Dodaj numer telefonu użytkownika",
    Teams_AssignPhoneNumberToPlayer_Error: "Podany numer jest niepoprawny",
    Teams_Cancel_Generate_Custom_schedule: "Czy na pewno chcesz odrzucić wprowadzony terminarz?",
    Teams_Captain: "Admin",
    Teams_ConfirmDeletingTeam: "Czy na pewno chcesz usunąć zespół?",
    Teams_DeleteTeam: "Usuń zespół",
    Teams_FirstName: "Imię",
    Teams_Generate_Auto_Schedule: "Automatycznie",
    Teams_Generate_Custom_Schedule: "Ręcznie",
    Teams_GenerateSchedule: "Generuj terminarz",
    Teams_GenerateSchedule_Confirmation_1: "Czy na pewno chcesz wygenerować terminarz?",
    Teams_GenerateSchedule_Confirmation_2: "Po wygenerowaniu terminarza nie będzie można już dodać drużyny do sezonu.",
    Teams_HeaderTitle: "Drużyny",
    Teams_InvalidName: "Zawodnik musi posiadać imię i/lub nazwisko",
    Teams_InvalidShirtNumber: "Numer koszulki musi być liczbą w granicach 0-99",
    Teams_LastName: "Nazwisko",
    Teams_NameInvalidLength: "Imię lub nazwisko nazwodnika jest zbyt długie",
    Teams_PastSeasonsPlayers: "Z poprzednich sezonów",
    Teams_RemoveTeamCaptainRole: "Odbierz funkcję admina",
    Teams_RemoveTeamPlayer: "Usuń zawodnika",
    Teams_RemoveTeamPlayer_Confirmation: "Czy na pewno chcesz usunąć zawodnika?",
    Teams_RemoveTeamPlayer_ConfirmationButton: "Usuń",
    Teams_Save_Schedule: "Zapisz Terminarz",
    Teams_ScheduleAlreadyGenerated: "Drużyny nie można usunąć po wygenerowaniu terminarza.",
    Teams_Select_Team: "Wybierz",
    Teams_SetTeamCaptain: "Mianuj adminem",
    Teams_ShirtNumber: "Numer koszulki",
    Teams_TenantPlayers: "Inni zawodnicy",
    Teams_UpdateTeam_InvalidName: "Nazwa zespołu nie może być pusta",
    Teams_UpdateTeam_Name: "Nazwa",
    Teams_UpdateTeam_NameInvalidLength: "Nazwa zespołu jest zbyt długa,",
    Teams_UpdateTeam_Title: "Zmień nazwę zespołu",
    Teams_UpdateTeamPlayer: "Edytuj zawodnika",
    Tenant: "Konto organizatora",
    Tenant_Details: "Organizator",
    Tenant_Details_LandingLogo: "Logo strona",
    Tenant_Details_Logo: "Logo",
    Tenant_Details_Name: "Nazwa",
    Tenant_ManagerUsers: "Współpracownicy",
    Tenant_ManagerUsers_Add: "Dodaj współpracownika",
    Tenant_ManagerUsers_Add_Failure: "Nie udało się dodać współpracownika. Spróbuj ponownie później.",
    Tenant_ManagerUsers_Add_Form_Email: "Adres email",
    Tenant_ManagerUsers_Add_Form_Title: "Dodaj współpracownika",
    Tenant_ManagerUsers_Add_Success: "Współpracownik został dodany.",
    Tenant_ManagerUsers_Add_Validation_EmailAlreadyRegistered: "Nie możemy przyznać dostępu dla tego adresu email.",
    Tenant_ManagerUsers_Email: "Email",
    Tenant_ManagerUsers_Remove: "Usuń współpracownika",
    Tenant_ManagerUsers_Remove_Confirmation_Title: "Czy na pewno chcesz usunąć współpracownika?",
    Tenant_ManagerUsers_Remove_Confirmation_Content: "Spowoduje to, że nie będzie on się już mógł zalogować do managera i wykonywać żadnych zadań, ale zalogowani współpracownicy wciąż będą mogli pracować na systemie maks. do 1 godziny od usunięcia.",
    GroupsPhaseTeam_Remove_Confirmation_Title: "Usuwanie drużyny",
    GroupsPhaseTeam_Remove_Confirmation_Content: "Czy na pewno chcesz usunąć drużynę? Nie będzie już jej można przywrócić. Może to też spowodować konieczność zmian w fazach skojarzonych.",
    Tenant_ManagerUsers_Remove_Failure: "Nie udało się usunąć współpracownika. Spróbuj ponownie później.",
    Tenant_ManagerUsers_Remove_CannotRemoveSelf: "Nie można usuwać siebie z listy współpracowników.",
    Tenant_ManagerUsers_Remove_Success: "Usunięto współpracownika",
    Tenant_UpdateLogo_Failure: "Zmiana logo organizatora nie powiodła się. Spróbuj ponownie później.",
    Tenant_UpdateLogo_InProgress: "Trwa aktualizacja loga organizatora.",
    Tenant_UpdateLogo_Success: "Logo organizatora zostało zmienione.",
    Tenant_UpdateName: "Edytuj nazwę",
    Tenant_UpdateName_Failure: "Nie udało się edytować nazwy organizatora. Spróbuj ponownie później.",
    Tenant_UpdateName_Form_Name: "Nazwa",
    Tenant_UpdateName_Form_Title: "Edycja nazwy organizatora",
    Tenant_UpdateName_Success: "Nazwa organizatora została edytowana.",
    Videos: "Video",
    Videos_Add: "Dodaj Video",
    Videos_Add_Failure: "Nie udało się dodać Video. Spróbuj ponownie później.",
    Videos_Add_Success: "Video zostało dodane.",
    Videos_DateRecorded: "Data Video",
    Videos_Delete_Confirmation_Content: "Czy na pewno chcesz usunąć to Video? Tej operacji nie można cofnąć.",
    Videos_Delete_Confirmation_Title: "Usuwanie Video",
    Videos_Delete_Failure: "Nie udało się usunąć video. Spróbuj ponownie później.",
    Videos_Delete_Success: "Video zostało usunięte.",
    Videos_Edit_Failure: "Nie udało się edytować Video. Spróbuj ponownie później.",
    Videos_Edit_Success: "Video zostało edytowane.",
    Videos_Form_Add_Title: "Dodawanie Video",
    Videos_Form_DateCreated: "Data video",
    Videos_Form_Edit_Title: "Edycja Video",
    Videos_Form_Thumbnail: "Miniaturka",
    Videos_Form_Title: "Tytuł",
    Videos_Form_Uri: "Link do video",
    Videos_Form_Uri_NotValid: "Link do video jest nieprawidłowy.",
    Videos_Show: "Pokaż",
    Videos_Thumbnail: "Miniaturka",
    Videos_Title: "Tytuł",
};
