import React from "react";
import { useForm } from "antd/lib/form/Form";
import Modal from "Components/Modal";
import { l } from "Languages";
import PhaseScheduleConfigurationForm, {
    PhaseScheduleConfigurationFormFields,
} from "../PhaseScheduleConfigurationForm";

type GeneratePhaseScheduleFormDialogProps = {
    onFinish: (values: PhaseScheduleConfigurationFormFields) => Promise<void>;
    onClose?: () => void;
    teamsCount?: number;
};

const GeneratePhaseScheduleFormDialog: React.FunctionComponent<GeneratePhaseScheduleFormDialogProps> = ({
    onFinish,
    onClose,
    teamsCount,
}) => {
    const [form] = useForm();

    return (
        <Modal
            title={l("CompetitionDetails_GeneratePhaseSchedule_Form_Title")}
            onCancel={onClose}
            okText={l("CompetitionDetails_GeneratePhaseSchedule_Form_OkText")}
            cancelText={l("Common_Cancel")}
            onOk={form.submit}>
            <PhaseScheduleConfigurationForm onFinish={onFinish} form={form} teamsCount={teamsCount} />
        </Modal>
    );
};

export default GeneratePhaseScheduleFormDialog;
