import { DeleteOutlined } from "@ant-design/icons";
import { Button, message, PageHeader } from "antd";
import { PageHeaderProps } from "antd/lib/page-header";
import DropdownMenu from "Components/DropdownMenu";
import PageContent from "Components/PageContent";
import RoutedTabs from "Components/RoutedTabs";
import Spacing from "Components/Spacing";
import GroupsPhase from "Domain/CompetitionPhases/GroupsPhase/GroupsPhase";
import GroupsPhaseMatch from "Domain/CompetitionPhases/GroupsPhase/GroupsPhaseMatch";
import Competition from "Domain/Competitions/Competition";
import MatchdayFormDialog from "DomainComponents/Matches/MatchdayFormDialog";
import { l } from "Languages";
import { observer } from "mobx-react-lite";
import React, { FunctionComponent, useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router";
import routes from "Router/routes";
import GeneratePhaseScheduleFormDialog from "../PhaseScheduleForm/GeneratePhaseScheduleFormDialog";
import { PhaseScheduleConfigurationFormFields } from "../PhaseScheduleForm/PhaseScheduleConfigurationForm";
import TableOrderModifiers from "../TableOrderModifiers";
import TablePointsModifiers from "../TablePointsModifiers";
import useScheduleDeletion from "../useScheduleDeletion";
import GroupsDrawDialog from "./GroupsDrawDialog";
import GroupsPhaseSchedule from "./GroupsPhaseSchedule";
import TeamGroups from "./TeamGroups";

type GroupsPhaseDetailsProps = {
    phase: GroupsPhase;
    competition: Competition;

    headerProps: PageHeaderProps;
};

const GroupsPhaseDetails: FunctionComponent<GroupsPhaseDetailsProps> = observer(
    ({ phase, competition, headerProps }) => {
        const { push } = useHistory();

        const [matchToUpdateMatchday, setMatchToUpdateMatchday] = useState<GroupsPhaseMatch>();
        const [isGenerateScheduleDialogOpen, setIsGenerateScheduleDialogOpen] = useState(false);
        const [isDrawDialogOpen, setIsDrawDialogOpen] = useState(false);

        const showDeleteScheduleConfirmation = useScheduleDeletion(phase);

        const onScheduleGenerate = useCallback(
            async (values: PhaseScheduleConfigurationFormFields) => {
                const result = await phase.generateSchedule(values.matchesPerPair ?? 1);

                result
                    .handle(
                        [
                            "MatchesPerPairOutOfRange",
                            "GroupHasTooFewTeams",
                            "PhaseHasNoGroups",
                            "PhaseNotFound",
                            "ScheduleAlreadyGenerated",
                            "failure",
                        ],
                        () => {
                            message.error(l("CompetitionDetails_GeneratePhaseSchedule_Failure"));
                        },
                    )
                    .handle("success", () => {
                        message.success(l("CompetitionDetails_GeneratePhaseSchedule_Success"));

                        competition.fetchDetails();
                        setIsGenerateScheduleDialogOpen(false);
                        push(routes.competitionPhase.schedule({ phaseId: phase.id }));
                    })
                    .check();
            },
            [competition, phase, push],
        );

        const hasPlaceholders = useMemo(
            () =>
                competition.allTeams.filter(t => phase.teamsAssignedToGroups?.includes(t.id) && t.isPlaceholder)
                    .length > 0,
            [competition.allTeams, phase.teamsAssignedToGroups],
        );

        return (
            <>
                <PageContent wide>
                    <PageContent.Header>
                        <PageHeader
                            {...headerProps}
                            extra={
                                phase.schedule ? (
                                    <>
                                        {hasPlaceholders && (
                                            <Button onClick={() => setIsDrawDialogOpen(true)}>
                                                {l("CompetitionDetails_DrawGroups")}
                                            </Button>
                                        )}
                                        <DropdownMenu
                                            menuItems={[
                                                {
                                                    children: (
                                                        <>
                                                            <DeleteOutlined />{" "}
                                                            {l("CompetitionPhases_Details_DeleteSchedule")}
                                                        </>
                                                    ),
                                                    onClick: showDeleteScheduleConfirmation,
                                                },
                                            ]}
                                        />
                                    </>
                                ) : (
                                    <Button
                                        type="primary"
                                        disabled={phase?.teams && phase.teams.length < 2}
                                        onClick={() => setIsGenerateScheduleDialogOpen(true)}>
                                        {l("CompetitionDetails_GenerateSchedule")}
                                    </Button>
                                )
                            }
                        />
                    </PageContent.Header>
                    <PageContent.Card>
                        <RoutedTabs<"schedule" | "groups" | "points">
                            tabs={[
                                {
                                    key: "schedule",
                                    label: l("CompetitionPhases_Details_Schedule"),
                                    navigateTo: routes.competitionPhase.schedule({ phaseId: phase.id }),
                                    routePattern: routes.competitionPhase.schedule.pattern,
                                    content: competition.sportsFields && (
                                        <GroupsPhaseSchedule phase={phase} sportsFields={competition.sportsFields} />
                                    ),
                                    disabled: phase && !phase.schedule,
                                },
                                {
                                    key: "groups",
                                    label: l("CompetitionPhases_Details_Details_Groups"),
                                    navigateTo: routes.competitionPhase.groups({ phaseId: phase.id }),
                                    routePattern: routes.competitionPhase.groups.pattern,
                                    content: competition?.teams && (
                                        <TeamGroups phase={phase} competition={competition} />
                                    ),
                                },
                                {
                                    key: "points",
                                    label: l("CompetitionPhases_TablePointsAndOrder"),
                                    navigateTo: routes.competitionPhase.tablePointsModifiers({ phaseId: phase.id }),
                                    routePattern: routes.competitionPhase.tablePointsModifiers.pattern,
                                    onClick: () => phase.sortTieBreakingOrder(),
                                    content: (
                                        <>
                                            <TablePointsModifiers phase={phase} />
                                            <Spacing top />
                                            <TableOrderModifiers phase={phase} />
                                        </>
                                    ),
                                },
                            ]}
                            defaultKey={phase.schedule ? "schedule" : "groups"}
                        />
                    </PageContent.Card>
                </PageContent>
                {isGenerateScheduleDialogOpen && (
                    <GeneratePhaseScheduleFormDialog
                        onFinish={onScheduleGenerate}
                        onClose={() => setIsGenerateScheduleDialogOpen(false)}
                    />
                )}
                {isDrawDialogOpen && (
                    <GroupsDrawDialog
                        competition={competition}
                        onClose={() => setIsDrawDialogOpen(false)}
                        phase={phase}
                    />
                )}
                {matchToUpdateMatchday && (
                    <MatchdayFormDialog
                        match={matchToUpdateMatchday}
                        onClose={savedFormFields => {
                            if (savedFormFields) {
                                phase.fetchDetails();
                            }

                            setMatchToUpdateMatchday(undefined);
                        }}
                    />
                )}
            </>
        );
    },
);

export default GroupsPhaseDetails;
