/* eslint-disable */
import { CQRS } from "@leancode/cqrs-client/CQRS";
import { IRemoteCommand, IRemoteQuery } from "@leancode/cqrs-client/ClientType";
import { ApiDateTime, ApiDate, ApiDateTimeOffset } from "Utils/DTO";

interface AuthorizeWhenAttribute { }

export enum AccountStateDTO {
    Unlinked = 0,
    Invited = 1,
    Linked = 2,
}

export interface AddArticle extends ICompetitionMaybeRelated, IArticleContentRelated, IRemoteCommand {
    Id: string;
    IsNews: boolean;
    Title: string;
    Content: string;
    Summary: string;
    ThumbnailPhotoUri?: string | null;
    Tags?: string[] | null;
    CompetitionId?: string | null;
}

export interface AddCompetition extends ISeasonMaybeRelated, ICompetitionGroupMaybeRelated, IRemoteCommand {
    CompetitionId: string;
    SeasonId?: string | null;
    GroupId?: string | null;
    Name: string;
}

export interface AddCompetitionGroup extends IRemoteCommand {
    GroupId: string;
    Name: string;
}

export interface AddCopiesOfTeams extends ICompetitionRelated, IRemoteCommand {
    CompetitionId: string;
    TeamIds: string[];
}

export interface AddCustomPhase extends AddLinkedPhaseBase {
}

export interface AddCustomPhaseMatch extends ICompetitionPhaseRelated, IRemoteCommand {
    PhaseId: string;
    MatchId: string;
    Team1Id: string;
    Team2Id: string;
    LocalGroupId?: string | null;
    Name?: string | null;
    Date?: ApiDateTimeOffset | null;
    SportsFieldId?: string | null;
}

export interface AddExistingPlayersToTeam extends ICompetitionTeamRelated, IRemoteCommand {
    TeamId: string;
    UserIds: string[];
}

export interface AddGroupsPhase extends AddLinkedPhaseBase {
    CustomScoring?: CustomTableScoringDTO | null;
}

export interface AddGroupsPhaseMatch extends ICompetitionPhaseRelated, IRemoteCommand {
    PhaseId: string;
    GroupId: string;
    Team1Id: string;
    Team2Id: string;
    Matchday: number;
    Date?: ApiDateTimeOffset | null;
    SportsFieldId?: string | null;
    Name?: string | null;
}

export interface AddKnockoutPhase extends AddLinkedPhaseBase {
}

export interface AddLinkedPhaseBase extends AddPhaseBase {
    LinkedPhaseId?: string | null;
}

export interface AddManagerUser extends IEmailAddressRelated, IRemoteCommand {
    EmailAddress: string;
}

export interface AddMatchArticle extends IArticleContentRelated, IMatchRelated, IRemoteCommand {
    Id: string;
    MatchId: string;
    Title: string;
    Content: string;
    Summary: string;
}

export interface AddMatchGroupToCustomPhase extends ICompetitionPhaseRelated, IRemoteCommand {
    PhaseId: string;
    LocalGroupId: string;
    Name: string;
}

export interface AddNewPlayerToTeam extends ICompetitionTeamRelated, IRemoteCommand {
    TeamId: string;
    FirstName: string;
    LastName: string;
    ShirtNumber?: number | null;
}

export interface AddNewTeam extends ICompetitionRelated, IRemoteCommand {
    CompetitionId: string;
    TeamId: string;
    Name: string;
    ShortName?: string | null;
    AgeGroup?: string | null;
}

export interface AddNthBestForPlaceInGroupReferences extends ICompetitionPhaseRelated, IRemoteCommand {
    PhaseId: string;
    ReferencedPhaseId: string;
    References: AddNthBestForPlaceInGroupReferences_NthBestForPlaceInGroupReferenceDescriptionDTO[];
}

export interface AddNthBestForPlaceInGroupReferences_NthBestForPlaceInGroupReferenceDescriptionDTO {
    Id: string;
    PlaceholderId: string;
    Place: number;
    PlaceInGroup: number;
}

export interface AddNthPlaceMatchToKnockoutPhase extends ICompetitionPhaseRelated, IRemoteCommand {
    PhaseId: string;
    Team1Id: string;
    Team2Id: string;
    Date?: ApiDateTimeOffset | null;
    NodeNumber: number;
    SportsFieldId?: string | null;
    Name?: string | null;
}

export interface AddPhaseBase extends ICompetitionRelated, IRemoteCommand {
    CompetitionId: string;
    PhaseId: string;
    Name?: string | null;
}

export interface AddPhotoForMatch extends IMatchRelated, IRemoteCommand {
    MatchId: string;
    PhotoId: string;
    Uri: string;
}

export interface AddPlaceholderTeam extends ICompetitionRelated, IRemoteCommand {
    CompetitionId: string;
    TeamId: string;
}

export interface AddPlaceholderTeamForPhase extends ICompetitionPhaseRelated, IRemoteCommand {
    CompetitionId: string;
    PhaseId: string;
    TeamId: string;
    Name: string;
}

export interface AddPlaceInGroupReferencesToPhase extends ICompetitionPhaseRelated, IRemoteCommand {
    PhaseId: string;
    ReferencedPhaseId: string;
    References: AddPlaceInGroupReferencesToPhase_PlaceInGroupReferenceDescriptionDTO[];
}

export interface AddPlaceInGroupReferencesToPhase_PlaceInGroupReferenceDescriptionDTO {
    Id: string;
    PlaceholderId: string;
    GroupId: string;
    PlaceInGroup: number;
}

export interface AddPlaceInTableReferencesToPhase extends ICompetitionPhaseRelated, IRemoteCommand {
    PhaseId: string;
    ReferencedPhaseId: string;
    References: PlaceInTableReferenceDescriptionDTO[];
}

export interface AddProfilePicture extends IUserProfileRelated, IRemoteCommand {
    UserId: string;
    PictureUri: string;
}

export interface AddReferees extends ICompetitionRelated, IRemoteCommand {
    CompetitionId: string;
    Referees: RefereeDTO[];
}

export interface AddSeason extends IRemoteCommand {
    SeasonId: string;
    Name: string;
    ExpectedStartDate: ApiDate;
}

export interface AddTablePhase extends AddPhaseBase {
    CustomScoring?: CustomTableScoringDTO | null;
}

export interface AddTablePhaseMatch extends ICompetitionPhaseRelated, IRemoteCommand {
    PhaseId: string;
    Team1Id: string;
    Team2Id: string;
    Matchday: number;
    Date?: ApiDateTimeOffset | null;
    SportsFieldId?: string | null;
    Name?: string | null;
}

export interface AddTablePointsModifier extends ICompetitionPhaseRelated, IRemoteCommand {
    Id: string;
    PhaseId: string;
    TeamId: string;
    Points: number;
    Description?: string | null;
}

export interface AddTeamsToPhase extends ICompetitionRelated, IRemoteCommand {
    CompetitionId: string;
    PhaseId: string;
    TeamIds: string[];
}

export interface AddVideo extends IRemoteCommand {
    VideoId: string;
    Title: string;
    ThumbnailUri: string;
    Uri: string;
    DateRecorded: ApiDateTime;
}

export interface AdvanceTeamsForPhaseRelatedReferences extends ICompetitionPhaseRelated, IRemoteCommand {
    PhaseId: string;
    ExternalReferenceIds: string[];
}

export interface AllArticles extends IRemoteQuery<ArticleDTO[]> {
}

export interface AllCompetitionGroups extends IRemoteQuery<CompetitionGroupDTO[]> {
}

export interface AllCompetitions extends IRemoteQuery<CompetitionWithSeasonIdDTO[]> {
}

export interface AllSeasons extends IRemoteQuery<SeasonDTO[]> {
}

export interface AllVideos extends IRemoteQuery<VideoDTO[]> {
}

export interface ArticleDetails extends IArticleRelated, IRemoteQuery<ArticleDetailsDTO> {
    ArticleId: string;
}

export interface ArticleDetailsDTO extends ArticleDTO {
    IsNews: boolean;
    Content: string;
    Summary: string;
    ThumbnailPhotoUri?: string | null;
    CompetitionId?: string | null;
}

export interface ArticleDTO {
    Id: string;
    Title: string;
    State: ArticleStateDTO;
    Tags: string[];
    DatePublished?: ApiDateTime | null;
    DateModified: ApiDateTime;
}

export enum ArticleStateDTO {
    Draft = 0,
    Published = 1,
}

export interface AuthorizeWhenCreatedOrInvitedUser extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenCreatedOrInvitedUsers extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenIsCompetitionTeamsAdmin extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenIsInvitationsRecipient extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenOwnsArticle extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenOwnsBanner extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenOwnsCompetition extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenOwnsCompetitionGroup extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenOwnsCompetitionGroupOrCompetitionGroupNotSelected extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenOwnsCompetitionOrCompetitionNotSelected extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenOwnsCompetitionPhase extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenOwnsCompetitionTeam extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenOwnsCompetitionTeams extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenOwnsInvitation extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenOwnsMatch extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenOwnsPartner extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenOwnsPhoto extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenOwnsRegistration extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenOwnsSeason extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenOwnsSeasonOrSeasonNotSelected extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenOwnsSharedTeams extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenOwnsVideo extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenRefereesMatch extends AuthorizeWhenAttribute {
}

export interface AzureStorageTokenDTO {
    StorageUri: string;
    ContainerName: string;
    SharedAccessSignatureToken: string;
}

export interface BeginPhase extends ICompetitionPhaseRelated, IRemoteCommand {
    PhaseId: string;
}

export interface CancelInvitation extends IInvitationRelated, IRemoteCommand {
    InvitationId: string;
}

export interface ChangeAccessCode extends IRemoteCommand {
    NewAccessCode?: string | null;
}

export interface ChangeMatchday extends IMatchRelated, IRemoteCommand {
    MatchId: string;
    Matchday: number;
}

export interface ChangeTenantLandingLogo extends IRemoteCommand {
    NewLogoUri?: string | null;
}

export interface ChangeTenantLogo extends IRemoteCommand {
    NewLogoUri?: string | null;
}

export interface ChangeTenantName extends IRemoteCommand {
    NewName: string;
}

export interface CompetitionDetails extends ICompetitionRelated, IRemoteQuery<CompetitionDetailsDTO> {
    CompetitionId: string;
}

export interface CompetitionDetailsDTO extends CompetitionDTO {
    Season?: SeasonDTO | null;
    Teams: CompetitionTeamDTO[];
    Phases: CompetitionPhaseDTO[];
    SportsFields: CompetitionSportsFieldDTO[];
}

export interface CompetitionDTO {
    Id: string;
    Name: string;
    CreatedAt: ApiDateTime;
    GroupId?: string | null;
}

export interface CompetitionGroupDTO {
    Id: string;
    Name: string;
}

export interface CompetitionPhaseDetailsDTO extends CompetitionPhaseDTO {
    Season?: SeasonDTO | null;
    Competition: CompetitionDTO;
    Teams: PhaseTeamDTO[];
    CustomPhaseDetails?: CustomPhaseDetailsDTO | null;
    TablePhaseDetails?: TablePhaseDetailsDTO | null;
    GroupsPhaseDetails?: GroupsPhaseDetailsDTO | null;
    KnockoutPhaseDetails?: KnockoutPhaseDetailsDTO | null;
    DefaultMatchDurationInMinutes?: number | null;
    DefaultPauseBetweenMatchesInMinutes?: number | null;
    PlaceInGroupReferences: PlaceInGroupReferenceDTO[];
    NthBestForPlaceInGroupReferences: NthBestForPlaceInGroupReferenceDTO[];
    PlaceInTableReferences: PlaceInTableReferenceDTO[];
}

export interface CompetitionPhaseDTO {
    Id: string;
    Name: string;
    Type: PhaseTypeDTO;
    LinkedPhaseId?: string | null;
    IsOngoing: boolean;
    IsHidden: boolean;
}

export interface CompetitionPlayerRankingsDTO {
    Goals: boolean;
    Assists: boolean;
    Canadian: boolean;
    Goalkeeping: boolean;
    MatchMvps: boolean;
    TeamMvps: boolean;
    YellowCards: boolean;
    RedCards: boolean;
}

export interface CompetitionReferees extends ICompetitionRelated, IRemoteQuery<RefereeDTO[]> {
    CompetitionId: string;
}

export interface CompetitionSettings extends ICompetitionRelated, IRemoteQuery<CompetitionSettingsDTO> {
    CompetitionId: string;
}

export interface CompetitionSettingsDTO {
    PlayerRankings: CompetitionPlayerRankingsDTO;
}

export interface CompetitionsInSeason extends ISeasonMaybeRelated, IRemoteQuery<CompetitionDTO[]> {
    SeasonId?: string | null;
}

export interface CompetitionSportsFieldDTO {
    Id: string;
    Name: string;
}

export interface CompetitionTeamDetailsDTO extends CompetitionTeamDTO {
    Season?: SeasonDTO | null;
    Competition: CompetitionDTO;
    CreatedAt: ApiDateTime;
    PhotoUri?: string | null;
    Players: TeamPlayerDTO[];
    Admins: UserProfileDTO[];
    PendingTeamAdminInvitations: InvitationSummaryDTO[];
    History: HistorySeasonDTO[];
}

export interface CompetitionTeamDTO {
    Id: string;
    SharedId?: string | null;
    PhaseId?: string | null;
    ExternalReferenceId?: string | null;
    Name: string;
    ShortName?: string | null;
    LogoUri?: string | null;
    AgeGroup?: string | null;
}

export interface CompetitionWithSeasonIdDTO extends CompetitionDTO {
    SeasonId?: string | null;
}

export interface CopyCompetition extends ISeasonMaybeRelated, ICompetitionGroupMaybeRelated, IRemoteCommand {
    CompetitionId: string;
    SeasonId?: string | null;
    GroupId?: string | null;
    Name: string;
    CompetitionToCopyFromId: string;
    FirstMatchDay: ApiDate;
}

export interface CustomPhaseDetailsDTO {
    Groups: MatchGroupDTO[];
    Schedule: CustomPhaseMatchDTO[];
}

export interface CustomPhaseMatchDTO extends MatchDTO {
    LocalGroupId?: string | null;
}

export interface CustomTableScoringDTO {
    PointsPerVictory: number;
    PointsPerDraw: number;
    PointsPerDefeat: number;
}

export interface DeleteMatch extends IMatchRelated, IRemoteCommand {
    MatchId: string;
}

export interface DeleteMatchGroupFromCustomPhase extends ICompetitionPhaseRelated, IRemoteCommand {
    PhaseId: string;
    LocalGroupId: string;
}

export interface DeletePhaseMatchSchedule extends ICompetitionPhaseRelated, IRemoteCommand {
    PhaseId: string;
}

export interface DeletePhoto extends IPhotoRelated, IRemoteCommand {
    PhotoId: string;
}

export interface DeleteTablePointsModifier extends ICompetitionPhaseRelated, IRemoteCommand {
    PhaseId: string;
    TablePointsModifierId: string;
}

export interface DeleteTeam extends ICompetitionTeamRelated, IRemoteCommand {
    TeamId: string;
}

export interface DeleteVideo extends IVideoRelated, IRemoteCommand {
    VideoId: string;
}

export interface EditArticle extends ICompetitionMaybeRelated, IArticleContentRelated, IRemoteCommand {
    ArticleId: string;
    IsNews: boolean;
    Title: string;
    Content: string;
    Summary: string;
    ThumbnailPhotoUri?: string | null;
    Tags?: string[] | null;
    CompetitionId?: string | null;
    DatePublished?: ApiDateTime | null;
}

export interface EditMatchArticle extends IArticleContentRelated, IMatchRelated, IRemoteCommand {
    ArticleId: string;
    MatchId: string;
    Title: string;
    Content: string;
    Summary: string;
    DatePublished?: ApiDateTime | null;
}

export interface EditVideo extends IVideoRelated, IRemoteCommand {
    VideoId: string;
    Title: string;
    ThumbnailUri: string;
    Uri: string;
    DateRecorded: ApiDateTime;
}

export interface EndPhase extends ICompetitionPhaseRelated, IRemoteCommand {
    PhaseId: string;
}

export interface ExternalReferenceDTO {
    Id: string;
    ReferencedPhaseId: string;
    AdvancingTeamId?: string | null;
    PredictedAdvancingTeamId?: string | null;
}

export interface FinalRankingConfiguration extends ICompetitionRelated, IRemoteQuery<FinalRankingConfigurationDTO> {
    CompetitionId: string;
}

export interface FinalRankingConfigurationDTO {
    AllValidEntries: FinalRankingEntryWithNameDTO[];
    CurrentEntries?: FinalRankingEntryDTO[] | null;
}

export interface FinalRankingEntryDTO {
    PhaseId: string;
    GroupId?: string | null;
}

export interface FinalRankingEntryWithNameDTO extends FinalRankingEntryDTO {
    PhaseType: PhaseTypeDTO;
    PhaseName: string;
    GroupName?: string | null;
    NumberOfPlaces?: number | null;
}

export interface FinalRankingExportSpreadsheet extends ICompetitionRelated, IRemoteQuery<number[]> {
    CompetitionId: string;
}

export interface GenerateGroupsPhaseMatchSchedule extends ICompetitionPhaseRelated, IRemoteCommand {
    PhaseId: string;
    MatchesPerPair: number;
}

export interface GenerateKnockoutPhaseMatchSchedule extends ICompetitionPhaseRelated, IRemoteCommand {
    PhaseId: string;
    InitialTeamOrder: string[];
    AdditionalNthPlaceTrees: number;
}

export interface GeneratePresignedPhotoUploadLink extends IRemoteQuery<UploadLinksDTO> {
    PhotoId: string;
    Extension: string;
}

export interface GeneratePresignedVideoThumbnailUploadLink extends IRemoteQuery<UploadLinksDTO> {
    FileName: string;
}

export interface GenerateTablePhaseMatchSchedule extends ICompetitionPhaseRelated, IRemoteCommand {
    PhaseId: string;
    GenerationMode: TablePhaseMatchScheduleGenerationModeDTO;
    MatchesPerPair?: number | null;
    MatchesPerTeam?: number | null;
}

export interface GroupsPhaseDetailsDTO {
    Scoring: CustomTableScoringDTO;
    Groups: TeamGroupDTO[];
    Schedule: GroupsPhaseMatchDTO[];
    PointsModifiers: TablePointsModifierDTO[];
}

export interface GroupsPhaseMatchDTO extends MatchDTO {
    LocalGroupId: string;
}

export interface HideCompetition extends ICompetitionRelated, IRemoteCommand {
    CompetitionId: string;
}

export interface HidePhase extends ICompetitionPhaseRelated, IRemoteCommand {
    PhaseId: string;
}

export interface HideSeason extends ISeasonRelated, IRemoteCommand {
    SeasonId: string;
}

export interface HistoryCompetitionDTO {
    Id: string;
    Name: string;
}

export interface HistorySeasonDTO {
    Id: string;
    Name: string;
    Competitions: HistoryCompetitionDTO[];
}

export interface IArticleContentRelated extends IMemberwiseCloneable<IArticleContentRelated> {
    Content: string;
}

export interface IArticleRelated {
    ArticleId: string;
}

export interface IBannerRelated {
    BannerId: string;
}

export interface ICompetitionGroupMaybeRelated {
    GroupId?: string | null;
}

export interface ICompetitionGroupRelated {
    GroupId: string;
}

export interface ICompetitionMaybeRelated {
    CompetitionId?: string | null;
}

export interface ICompetitionPhaseRelated {
    PhaseId: string;
}

export interface ICompetitionRelated {
    CompetitionId: string;
}

export interface ICompetitionTeamRelated {
    TeamId: string;
}

export interface ICompetitionTeamsRelated {
}

export interface ICreatedOrInvitedUser {
}

export interface ICreatedOrInvitedUsers {
}

export interface IEmailAddressRelated extends IMemberwiseCloneable<IEmailAddressRelated> {
    EmailAddress: string;
}

export interface IInvitationRelated {
    InvitationId: string;
}

export interface IIsCompetitionTeamsAdmin {
}

export interface IIsInvitationsRecipient {
}

export interface IMatchRelated {
    MatchId: string;
}

export interface IMemberwiseCloneable<T extends IMemberwiseCloneable<T>> {
}

export interface InvitationSummaryDTO {
    Id: string;
    PhoneNumber: string;
    CreatedAt: ApiDateTime;
}

export interface InvitePlayerToProfile extends ICompetitionTeamRelated, IPhoneNumberRelated, IRemoteCommand {
    TeamId: string;
    UserId: string;
    PhoneNumber: string;
}

export interface InviteTeamAdmin extends ICompetitionTeamRelated, IPhoneNumberRelated, IRemoteCommand {
    TeamId: string;
    PhoneNumber: string;
}

export interface IOwnsArticle {
}

export interface IOwnsBanner {
}

export interface IOwnsCompetition {
}

export interface IOwnsCompetitionGroup {
}

export interface IOwnsCompetitionGroupOrCompetitionGroupNotSelected {
}

export interface IOwnsCompetitionOrCompetitionNotSelected {
}

export interface IOwnsCompetitionPhase {
}

export interface IOwnsCompetitionTeam {
}

export interface IOwnsCompetitionTeams {
}

export interface IOwnsInvitation {
}

export interface IOwnsMatch {
}

export interface IOwnsPartner {
}

export interface IOwnsPhoto {
}

export interface IOwnsRegistration {
}

export interface IOwnsSeason {
}

export interface IOwnsSeasonOrSeasonNotSelected {
}

export interface IOwnsSharedTeams {
}

export interface IOwnsVideo {
}

export interface IPartnerRelated {
    PartnerId: string;
}

export interface IPhoneNumberRelated extends IMemberwiseCloneable<IPhoneNumberRelated> {
    PhoneNumber: string;
}

export interface IPhotoRelated {
    PhotoId: string;
}

export interface IRefereesMatch {
}

export interface IRegistrationRelated {
    RegistrationId: string;
}

export interface ISeasonMaybeRelated {
    SeasonId?: string | null;
}

export interface ISeasonRelated {
    SeasonId: string;
}

export interface ISharedTeamsRelated {
}

export interface IUserProfileRelated {
    UserId: string;
}

export interface IUserProfilesRelated {
}

export interface IVideoRelated {
    VideoId: string;
}

export interface KnockoutPhaseDetailsDTO {
    Schedule: MatchDTO[];
    IsInFinalRanking?: boolean | null;
    FinalRankingMatchPlaceOffset?: number | null;
}

export interface ManagerUserDTO {
    Id: string;
    EmailAddress: string;
}

export interface ManagerUsers extends IRemoteQuery<ManagerUserDTO[]> {
}

export interface MatchDetails extends IMatchRelated, IRemoteQuery<MatchDetailsDTO> {
    MatchId: string;
}

export interface MatchDetailsDTO extends MatchDTO {
    Article?: ArticleDTO | null;
    LocalGroupId?: string | null;
    GroupName?: string | null;
    Phase: CompetitionPhaseDTO;
    Competition: CompetitionDTO;
    Events?: MatchEventDTO[] | null;
    Team1Lineup?: MatchPlayerDTO[] | null;
    Team2Lineup?: MatchPlayerDTO[] | null;
    Team1MvpId?: string | null;
    Team2MvpId?: string | null;
    MatchMvpId?: string | null;
}

export interface MatchDTO {
    Id: string;
    Team1Id?: string | null;
    Team2Id?: string | null;
    Date?: ApiDateTimeOffset | null;
    Status: MatchStatusDTO;
    Result?: MatchResultDTO | null;
    MatchInPhaseDesignator: number;
    SportsFieldId?: string | null;
    Name?: string | null;
}

export interface MatchEventDTO {
    Side: MatchEventSideDTO;
    Type: MatchEventTypeDTO;
    Subtype?: MatchEventSubtypeDTO | null;
    PenaltyValue?: number | null;
    SecondOfMatch?: number | null;
    PlayerId?: string | null;
    OtherPlayerId?: string | null;
    GoalkeeperPlayerId?: string | null;
}

export enum MatchEventSideDTO {
    Team1 = -1,
    Team2 = 1,
}

export enum MatchEventSubtypeDTO {
    FromNear = 0,
    FromDistance = 1,
    FollowUp = 2,
    Head = 3,
    Penalty = 4,
    FreeKick = 5,
    Own = 6,
    Unspecified = 255,
    OnTarget = 0,
    OffTarget = 1,
    GoalPost = 2,
    Crossbar = 3,
}

export enum MatchEventTypeDTO {
    Goal = 0,
    Shot = 1,
    Foul = 2,
    MinutesPenalty = 3,
    YellowCard = 4,
    RedCard = 5,
}

export interface MatchGroupDTO {
    LocalId: string;
    Name: string;
}

export interface MatchPhotos extends IMatchRelated, IRemoteQuery<PhotoDTO[]> {
    MatchId: string;
}

export interface MatchPlayerDTO {
    Id: string;
    FirstName: string;
    LastName: string;
    PictureUri?: string | null;
    ShirtNumber?: number | null;
    IsGoalkeeper: boolean;
}

export interface MatchResultDTO {
    HalfTimeScore?: MatchScoreDTO | null;
    FullTimeScore: MatchScoreDTO;
    AfterExtraTimeScore?: MatchScoreDTO | null;
    PenaltyScore?: MatchScoreDTO | null;
    Winner: MatchWinnerDTO;
}

export interface MatchScoreDTO {
    Team1: number;
    Team2: number;
}

export interface MatchSlotDTO {
    MatchId: string;
    SportsFieldId?: string | null;
    Date?: ApiDateTimeOffset | null;
}

export enum MatchStatusDTO {
    Upcoming = 0,
    Finished = 1,
    Canceled = 2,
    Ongoing = 3,
}

export enum MatchWinnerDTO {
    Team1 = -1,
    Draw = 0,
    Team2 = 1,
}

export interface MergeProfiles extends IUserProfilesRelated, IRemoteCommand {
    User1Id: string;
    User2Id: string;
}

export interface MergeTeam extends ISharedTeamsRelated, IRemoteCommand {
    TeamSharedId: string;
    TeamToBeMergedInSharedId: string;
}

export interface NthBestForPlaceInGroupReferenceDTO extends ExternalReferenceDTO {
    Place: number;
    PlaceInGroup: number;
}

export interface PhaseDetails extends ICompetitionPhaseRelated, IRemoteQuery<CompetitionPhaseDetailsDTO> {
    PhaseId: string;
}

export interface PhaseTeamDTO {
    TeamId: string;
    TieBreakingOrder: number;
}

export enum PhaseTypeDTO {
    Custom = 0,
    Table = 1,
    Groups = 2,
    Knockout = 3,
}

export interface PhotoDTO {
    Id: string;
    Uri: string;
    DateCreated: ApiDateTime;
}

export interface PlaceInGroupReferenceDTO extends ExternalReferenceDTO {
    GroupId: string;
    GroupName: string;
    PlaceInGroup: number;
}

export interface PlaceInTableReferenceDescriptionDTO {
    Id: string;
    PlaceholderId: string;
    PlaceInTable: number;
}

export interface PlaceInTableReferenceDTO extends ExternalReferenceDTO {
    PlaceInTable: number;
}

export interface PlanPhaseSchedule extends ICompetitionPhaseRelated, IRemoteCommand {
    CompetitionId: string;
    PhaseId: string;
    Schedule: MatchSlotDTO[];
    DefaultMatchDurationInMinutes: number;
    DefaultPauseBetweenMatchesInMinutes: number;
}

export interface PlayerAutocompleteDataDTO {
    Id: string;
    FirstName: string;
    LastName: string;
    HasAccount: boolean;
    TeamName: string;
    CompetitionName: string;
    SeasonName?: string | null;
}

export interface PlayerRankingsExportSpreadsheet extends ICompetitionRelated, IRemoteQuery<number[]> {
    CompetitionId: string;
    Rankings: CompetitionPlayerRankingsDTO;
}

export interface PlayersAutocompleteData extends ICompetitionTeamRelated, IRemoteQuery<PlayersAutocompleteDataDTO> {
    TeamId: string;
    FirstName?: string | null;
    LastName?: string | null;
}

export interface PlayersAutocompleteDataDTO {
    PlayersFromPreviousCompetitions: PlayerAutocompleteDataDTO[];
    OtherPlayers: PlayerAutocompleteDataDTO[];
}

export interface PresentPlayerDTO {
    Id: string;
    IsGoalkeeper: boolean;
}

export interface ProfilePicturesCreateToken extends IUserProfileRelated, IRemoteQuery<AzureStorageTokenDTO> {
    UserId: string;
}

export interface PublishMatchReport extends IMatchRelated, IRemoteCommand {
    MatchId: string;
    Team1PresentPlayers: PresentPlayerDTO[];
    Team2PresentPlayers: PresentPlayerDTO[];
    Events: MatchEventDTO[];
    Result: MatchResultDTO;
    Team1MvpId?: string | null;
    Team2MvpId?: string | null;
    MatchMvpId?: string | null;
}

export interface RecreateMatch extends IMatchRelated, IRemoteCommand {
    MatchId: string;
}

export interface RefereeDTO {
    PhoneNumber: string;
    FirstName: string;
    LastName: string;
}

export interface RefereesAutocompleteData extends ICompetitionRelated, IRemoteQuery<RefereeDTO[]> {
    CompetitionId: string;
}

export interface RegisterTenant extends IEmailAddressRelated, IRemoteCommand {
    EmailAddress: string;
    TenantName: string;
}

export interface RemoveAdminFromTeam extends ICompetitionTeamRelated, IRemoteCommand {
    TeamId: string;
    UserId: string;
}

export interface RemoveManagerUser extends IRemoteCommand {
    UserId: string;
}

export interface RemovePhase extends ICompetitionPhaseRelated, IRemoteCommand {
    PhaseId: string;
}

export interface RemovePlayerFromTeam extends ICompetitionTeamRelated, IRemoteCommand {
    TeamId: string;
    UserId: string;
}

export interface RemoveReferee extends ICompetitionRelated, IPhoneNumberRelated, IRemoteCommand {
    CompetitionId: string;
    PhoneNumber: string;
}

export interface RemoveTeamFromPhase extends ICompetitionPhaseRelated, IRemoteCommand {
    PhaseId: string;
    TeamId: string;
}

export interface RenameMatchGroupInCustomPhase extends ICompetitionPhaseRelated, IRemoteCommand {
    PhaseId: string;
    LocalGroupId: string;
    Name: string;
}

export interface RenamePhase extends ICompetitionPhaseRelated, IRemoteCommand {
    PhaseId: string;
    Name?: string | null;
}

export interface RenamePlaceholderTeamForPhase extends ICompetitionTeamRelated, IRemoteCommand {
    TeamId: string;
    Name: string;
}

export interface ReorderCompetitionGroups extends IRemoteCommand {
    OrderedGroupIds: string[];
}

export interface ReorderCompetitions extends ISeasonMaybeRelated, IRemoteCommand {
    SeasonId?: string | null;
    OrderedCompetitionIds: string[];
}

export interface ReorderMatchGroupsInCustomPhase extends ICompetitionPhaseRelated, IRemoteCommand {
    PhaseId: string;
    LocalGroupIds: string[];
}

export interface ReplacementDTO {
    PlaceholderTeamId: string;
    ReplacementTeamId: string;
}

export interface ReplacePlaceholderTeamForPhase extends ICompetitionRelated, IRemoteCommand {
    CompetitionId: string;
    PhaseId: string;
    Replacements: ReplacementDTO[];
}

export interface ReplaceTeamWithCopy extends ICompetitionTeamsRelated, IRemoteCommand {
    ReplacedTeamId: string;
    ReplacementTeamId: string;
}

export interface ReplaceTeamWithNew extends ICompetitionTeamRelated, IRemoteCommand {
    TeamId: string;
    Name: string;
    ShortName?: string | null;
    AgeGroup?: string | null;
}

export interface ResetPassword extends IRemoteCommand {
    UserId: string;
    Token: string;
    NewPassword: string;
}

export interface RevokeTeamAdvancing extends ICompetitionPhaseRelated, IRemoteCommand {
    PhaseId: string;
    ExternalReferenceId: string;
    ReplacementPlaceholderId: string;
}

export interface SeasonDTO {
    Id: string;
    Name: string;
    ExpectedStartDate: ApiDate;
    State: SeasonStateDTO;
}

export interface SeasonScheduleExportSpreadsheet extends ISeasonRelated, IRemoteQuery<number[]> {
    SeasonId: string;
}

export enum SeasonStateDTO {
    Default = 0,
    Ongoing = 1,
    Next = 2,
}

export interface SeasonTeamsDTO {
    SeasonName: string;
    Teams: CompetitionTeamDTO[];
}

export interface SelectProfilePicture extends IUserProfileRelated, IRemoteCommand {
    UserId: string;
    PictureUri?: string | null;
}

export interface SendPasswordRecoveryEmail extends IEmailAddressRelated, IRemoteCommand {
    EmailAddress: string;
}

export interface SetCompetitionSportsFields extends ICompetitionRelated, IRemoteCommand {
    CompetitionId: string;
    SportsFields: CompetitionSportsFieldDTO[];
}

export interface SetGroupsPhaseTeamGroups extends ICompetitionPhaseRelated, IRemoteCommand {
    PhaseId: string;
    TeamGroups: TeamGroupDTO[];
}

export interface SetMatchDate extends IMatchRelated, IRemoteCommand {
    MatchId: string;
    Date?: ApiDateTimeOffset | null;
}

export interface SetPhaseTieBreakingOrder extends ICompetitionPhaseRelated, IRemoteCommand {
    PhaseId: string;
    TeamsTieBreakingOrder: PhaseTeamDTO[];
}

export interface SwapTeamsInPhase extends ICompetitionPhaseRelated, IRemoteCommand {
    PhaseId: string;
    Team1Id: string;
    Team2Id: string;
}

export interface TablePhaseDetailsDTO {
    Scoring: CustomTableScoringDTO;
    Schedule: MatchDTO[];
    PointsModifiers: TablePointsModifierDTO[];
}

export enum TablePhaseMatchScheduleGenerationModeDTO {
    MatchesPerPair = 0,
    MatchesPerTeam = 1,
}

export interface TablePointsModifierDTO {
    Id: string;
    TeamId: string;
    Points: number;
    Description?: string | null;
}

export interface TeamDetails extends ICompetitionTeamRelated, IRemoteQuery<CompetitionTeamDetailsDTO> {
    TeamId: string;
}

export interface TeamGroupDTO {
    LocalId: string;
    Name: string;
    TeamIds: string[];
}

export interface TeamLogosCreateToken extends IRemoteQuery<AzureStorageTokenDTO> {
}

export interface TeamPhotosCreateToken extends IRemoteQuery<AzureStorageTokenDTO> {
}

export interface TeamPlayerDTO extends UserProfileDTO {
    ShirtNumber?: number | null;
    AccountState: AccountStateDTO;
    PendingProfileInvitation?: InvitationSummaryDTO | null;
}

export interface TeamsAutocompleteData extends ICompetitionRelated, IRemoteQuery<TeamsAutocompleteDataDTO> {
    CompetitionId: string;
}

export interface TeamsAutocompleteDataDTO {
    PreviousSeason?: SeasonTeamsDTO | null;
    OtherTeams: CompetitionTeamDTO[];
}

export interface TenantLogosCreateToken extends IRemoteQuery<AzureStorageTokenDTO> {
}

export interface TenantManagerUsers extends IRemoteQuery<ManagerUserDTO[]> {
    TenantId: string;
}

export interface TenantSettings extends IRemoteQuery<TenantSettingsDTO> {
}

export interface TenantSettingsDTO {
    Name: string;
    LogoUri?: string | null;
    LandingLogoUri?: string | null;
    AccessCode?: string | null;
    LandingUri?: string | null;
}

export interface UnhidePhase extends ICompetitionPhaseRelated, IRemoteCommand {
    PhaseId: string;
}

export interface UpdateCompetition extends ICompetitionRelated, ISeasonMaybeRelated, ICompetitionGroupMaybeRelated, IRemoteCommand {
    CompetitionId: string;
    SeasonId?: string | null;
    GroupId?: string | null;
    Name: string;
}

export interface UpdateCompetitionGroup extends ICompetitionGroupRelated, IRemoteCommand {
    GroupId: string;
    Name: string;
}

export interface UpdateCompetitionSettings extends ICompetitionRelated, IRemoteCommand {
    CompetitionId: string;
    PlayerRankings: CompetitionPlayerRankingsDTO;
}

export interface UpdateCustomPhaseMatch extends IMatchRelated, IRemoteCommand {
    MatchId: string;
    Team1Id: string;
    Team2Id: string;
    LocalGroupId?: string | null;
    Name?: string | null;
    Date?: ApiDateTimeOffset | null;
    SportsFieldId?: string | null;
    Status: MatchStatusDTO;
}

export interface UpdateFinalRankingConfiguration extends ICompetitionRelated, IRemoteCommand {
    CompetitionId: string;
    Entries?: FinalRankingEntryDTO[] | null;
}

export interface UpdateGroupsPhaseMatch extends IMatchRelated, IRemoteCommand {
    MatchId: string;
    GroupId: string;
    Team1Id: string;
    Team2Id: string;
    Matchday: number;
    Date?: ApiDateTimeOffset | null;
    SportsFieldId?: string | null;
    Status: MatchStatusDTO;
    Name?: string | null;
}

export interface UpdateKnockoutPhaseMatch extends IMatchRelated, IRemoteCommand {
    MatchId: string;
    Date?: ApiDateTimeOffset | null;
    SportsFieldId?: string | null;
    Name?: string | null;
}

export interface UpdateMatchReport extends IMatchRelated, IRemoteCommand {
    MatchId: string;
    Team1PresentPlayers?: PresentPlayerDTO[] | null;
    Team2PresentPlayers?: PresentPlayerDTO[] | null;
    Events: MatchEventDTO[];
    Result: MatchResultDTO;
    Team1MvpId?: string | null;
    Team2MvpId?: string | null;
    MatchMvpId?: string | null;
}

export interface UpdatePlayer extends ICompetitionTeamRelated, IRemoteCommand {
    TeamId: string;
    PlayerId: string;
    FirstName: string;
    LastName: string;
    ShirtNumber?: number | null;
}

export interface UpdateSeason extends ISeasonRelated, IRemoteCommand {
    SeasonId: string;
    Name: string;
    ExpectedStartDate: ApiDate;
}

export interface UpdateSeasonState extends ISeasonRelated, IRemoteCommand {
    SeasonId: string;
    State: SeasonStateDTO;
}

export interface UpdateTablePhaseMatch extends IMatchRelated, IRemoteCommand {
    MatchId: string;
    Team1Id: string;
    Team2Id: string;
    Matchday: number;
    Date?: ApiDateTimeOffset | null;
    SportsFieldId?: string | null;
    Status: MatchStatusDTO;
    Name?: string | null;
}

export interface UpdateTeam extends ICompetitionTeamRelated, IRemoteCommand {
    TeamId: string;
    Name: string;
    ShortName?: string | null;
    AgeGroup?: string | null;
}

export interface UpdateTeamLogo extends ICompetitionTeamRelated, IRemoteCommand {
    TeamId: string;
    LogoUri?: string | null;
}

export interface UpdateTeamPhoto extends ICompetitionTeamRelated, IRemoteCommand {
    TeamId: string;
    PhotoUri?: string | null;
}

export interface UploadLinksDTO {
    Link: string;
    PresignedLink: string;
    RequiredHeaders: { [index: string]: string };
}

export interface UserProfileDTO {
    Id: string;
    FirstName: string;
    LastName: string;
    PictureUri?: string | null;
}

export interface UserProfilePictures extends IUserProfileRelated, IRemoteQuery<string[]> {
    UserId: string;
}

export interface VideoDTO {
    Id: string;
    Title: string;
    ThumbnailUri: string;
    Uri: string;
    DateRecorded: ApiDateTime;
}

export const AddArticle = {
    ErrorCodes: {
        CompetitionNotFound: 1,
        ArticleIdAlreadyInUse: 101,
        TitleMissingOrEmpty: 102,
        TitleTooLong: 103,
        ContentMissingOrEmpty: 104,
        SummaryMissingOrEmpty: 105,
        ThumbnailPhotoUriTooLong: 106,
        OneOfTagsTooLong: 108,
        OneOfTagsEmpty: 109,
        SummaryTooLong: 110,
    },
};

export const AddCompetition = {
    ErrorCodes: {
        CompetitionIdAlreadyInUse: 1,
        SeasonNotFound: 2,
        GroupNotFound: 3,
        NameMissingOrEmpty: 4,
        NameTooLong: 5,
    },
};

export const AddCompetitionGroup = {
    ErrorCodes: {
        GroupIdAlreadyInUse: 1,
        NameMissingOrEmpty: 2,
        NameTooLong: 3,
    },
};

export const AddCopiesOfTeams = {
    ErrorCodes: {
        CompetitionNotFound: 1,
        TeamNotFound: 2,
        TeamAlreadyCompetes: 3,
        DuplicateTeam: 4,
        TeamIdsNullOrEmpty: 5,
    },
};

export const AddCustomPhaseMatch = {
    ErrorCodes: {
        PhaseNotFoundOrNotACustomPhase: 1,
        MatchIdAlreadyInUse: 2,
        TeamCannotPlayAgainstItself: 3,
        TeamNotFoundInPhase: 4,
        GroupNotFound: 5,
        NameTooLong: 6,
        SportsFieldNotFound: 7,
    },
};

export const AddExistingPlayersToTeam = {
    ErrorCodes: {
        TeamNotFound: 1,
        TeamIsAPlaceholder: 2,
        UserIdsNullOrEmpty: 3,
        UserNotFound: 4,
        UserAlreadyIsAPlayerInThisTeam: 5,
    },
};

export const AddGroupsPhaseMatch = {
    ErrorCodes: {
        PhaseNotFoundOrNotAGroupsPhase: 1,
        GroupNotFound: 2,
        TeamCannotPlayAgainstItself: 3,
        TeamNotFoundInGroup: 4,
        MatchdayNotPositive: 5,
        SportsFieldNotFound: 6,
        NameTooLong: 7,
    },
};

export const AddLinkedPhaseBase = {
    ErrorCodes: {
        LinkedPhaseNotFoundOrIsInvalidType: 4,
    },
};

export const AddManagerUser = {
    ErrorCodes: {
        EmailAddressMissingOrEmpty: 1,
        EmailAddressTooLong: 2,
        EmailAddressNotValid: 3,
        EmailAddressAlreadyRegistered: 4,
    },
};

export const AddMatchArticle = {
    ErrorCodes: {
        MatchNotFound: 1,
        MatchArticleAlreadyExists: 2,
        ArticleIdAlreadyInUse: 101,
        TitleMissingOrEmpty: 102,
        TitleTooLong: 103,
        ContentMissingOrEmpty: 104,
        SummaryMissingOrEmpty: 105,
        ThumbnailPhotoUriTooLong: 106,
        OneOfTagsTooLong: 108,
        OneOfTagsEmpty: 109,
        SummaryTooLong: 110,
    },
};

export const AddMatchGroupToCustomPhase = {
    ErrorCodes: {
        PhaseNotFound: 1,
        GroupIdAlreadyInUse: 2,
        NameMissingOrEmpty: 3,
        NameTooLong: 4,
    },
};

export const AddNewPlayerToTeam = {
    ErrorCodes: {
        TeamNotFound: 1,
        TeamIsAPlaceholder: 2,
        FirstNameNullOrEmpty: 3,
        FirstNameTooLong: 4,
        LastNameNullOrEmpty: 5,
        LastNameTooLong: 6,
        ShirtNumberOutOfRange: 7,
    },
};

export const AddNewTeam = {
    ErrorCodes: {
        CompetitionNotFound: 1,
        TeamIdAlreadyInUse: 2,
        NameNullOrEmpty: 3,
        NameTooLong: 4,
        ShortNameTooLong: 5,
        AgeGroupTooLong: 6,
    },
};

export const AddNthBestForPlaceInGroupReferences = {
    ErrorCodes: {
        PhaseNotFound: 1,
        PhaseNotLinkedToReferencedPhase: 2,
        OneOfReferenceIdsAlreadyInUse: 3,
        OneOfPlaceholderIdsAlreadyInUse: 4,
        ReferencedPhaseNotFoundOrNotAGroupsPhase: 5,
        OneOfPlacesInGroupOutOfRange: 6,
        PlaceForOneOfPlacesInGroupOutOfRange: 7,
        OneOfReferencesAlreadyExists: 8,
        PlaceInGroupReferenceForSamePlaceInGroupExists: 9,
    },
};

export const AddNthPlaceMatchToKnockoutPhase = {
    ErrorCodes: {
        PhaseNotFoundOrNotAKnockoutPhase: 1,
        TeamCannotPlayAgainstItself: 2,
        TeamNotFoundInPhase: 3,
        MatchIsNotNthPlaceMatch: 4,
        NthPlaceMatchAlreadyExists: 5,
        TooFewTeamsInPhaseForNthPlaceMatch: 6,
        SportsFieldNotFound: 7,
        NameTooLong: 8,
    },
};

export const AddPhaseBase = {
    ErrorCodes: {
        CompetitionNotFound: 1,
        PhaseIdAlreadyInUse: 2,
        NameTooLong: 3,
    },
};

export const AddPhotoForMatch = {
    ErrorCodes: {
        PhotoIdAlreadyInUse: 1,
        MatchNotFound: 2,
        UriMissingOrEmpty: 3,
        UriTooLong: 4,
        UriNotValid: 5,
    },
};

export const AddPlaceholderTeam = {
    ErrorCodes: {
        CompetitionNotFound: 1,
        TeamIdAlreadyInUse: 2,
    },
};

export const AddPlaceholderTeamForPhase = {
    ErrorCodes: {
        PhaseNotFoundInCompetition: 1,
        TeamIdAlreadyInUse: 2,
        NameMissingOrEmpty: 3,
        NameTooLong: 4,
    },
};

export const AddPlaceInGroupReferencesToPhase = {
    ErrorCodes: {
        PhaseNotFound: 1,
        PhaseNotLinkedToReferencedPhase: 2,
        OneOfReferenceIdsAlreadyInUse: 3,
        OneOfPlaceholderIdsAlreadyInUse: 4,
        ReferencedPhaseNotFoundOrNotAGroupsPhase: 5,
        OneOfGroupsNotFound: 6,
        OneOfPlacesInGroupOutOfRange: 7,
        OneOfReferencesAlreadyExists: 8,
        NthBestForPlaceInGroupReferenceForSamePlaceInGroupExists: 9,
    },
};

export const AddPlaceInTableReferencesToPhase = {
    ErrorCodes: {
        PhaseNotFound: 1,
        PhaseNotLinkedToReferencedPhase: 2,
        OneOfReferenceIdsAlreadyInUse: 3,
        OneOfPlaceholderIdsAlreadyInUse: 4,
        ReferencedPhaseNotFoundOrNotATablePhase: 5,
        OneOfPlacesInTableOutOfRange: 6,
        OneOfReferencesAlreadyExists: 7,
    },
};

export const AddProfilePicture = {
    ErrorCodes: {
        PictureUriNullOrEmpty: 1,
        PictureUriTooLong: 2,
        PictureUriNotValid: 3,
        PictureAlreadyAdded: 4,
        UserProfileNotFound: 5,
    },
};

export const AddReferees = {
    ErrorCodes: {
        CompetitionNotFound: 1,
        RefereesNullOrEmpty: 2,
        DuplicateRefereePhoneNumber: 3,
        RefereePhoneNumberNullOrEmpty: 4,
        RefereePhoneNumberInvalidFormat: 5,
        RefereeFirstNameNullOrEmpty: 6,
        RefereeFirstNameTooLong: 7,
        RefereeLastNameNullOrEmpty: 8,
        RefereeLastNameTooLong: 9,
        RefereeAlreadyExists: 10,
    },
};

export const AddSeason = {
    ErrorCodes: {
        SeasonIdAlreadyInUse: 1,
        NameMissingOrEmpty: 2,
        NameTooLong: 3,
        ExpectedStartDateOutOfRange: 4,
    },
};

export const AddTablePhaseMatch = {
    ErrorCodes: {
        PhaseNotFoundOrNotATablePhase: 1,
        TeamCannotPlayAgainstItself: 2,
        TeamNotFoundInPhase: 3,
        MatchdayNotPositive: 4,
        SportsFieldNotFound: 5,
        NameTooLong: 6,
    },
};

export const AddTablePointsModifier = {
    ErrorCodes: {
        TablePointsModifierIdAlreadyInUse: 1,
        PhaseNotFoundOrNotATableOrGroupsPhase: 2,
        TeamNotFoundInPhase: 3,
        TeamIsAPlaceholder: 4,
        DescriptionTooLong: 5,
        PointsEqualZero: 6,
    },
};

export const AddTeamsToPhase = {
    ErrorCodes: {
        CompetitionNotFound: 1,
        PhaseNotFound: 2,
        TeamNotFound: 3,
        TeamAlreadyInPhase: 4,
        TeamIdsNullOrEmpty: 5,
    },
};

export const AddVideo = {
    ErrorCodes: {
        VideoIdAlreadyInUse: 1,
        TitleMissingOrEmpty: 2,
        TitleTooLong: 3,
        UriMissingOrEmpty: 4,
        UriTooLong: 5,
        UriNotValid: 6,
        ThumbnailUriMissingOrEmpty: 7,
        ThumbnailUriTooLong: 8,
        ThumbnailUriNotValid: 9,
    },
};

export const AdvanceTeamsForPhaseRelatedReferences = {
    ErrorCodes: {
        PhaseNotFound: 1,
        PhaseNotLinked: 2,
        OneOfReferencesNotFoundInPhase: 3,
        OneOfAdvancingTeamsAlreadyInPhase: 4,
        OneOfAdvancingTeamsIsAPlaceholder: 5,
        ExternalReferenceIdsNullOrEmpty: 6,
    },
};

export const BeginPhase = {
    ErrorCodes: {
        PhaseNotFound: 1,
        PhaseAlreadyOngoing: 2,
    },
};

export const CancelInvitation = {
    ErrorCodes: {
        InvitationNotFound: 1,
        InvitationNotPending: 2,
    },
};

export const ChangeAccessCode = {
    ErrorCodes: {
        NewAccessCodeTooLong: 1,
        NewAccessCodeAlreadyInUse: 2,
    },
};

export const ChangeMatchday = {
    ErrorCodes: {
        MatchNotFound: 1,
        MatchNotInTableOrGroupsPhase: 2,
        MatchdayNotPositive: 3,
    },
};

export const ChangeTenantLandingLogo = {
    ErrorCodes: {
        NewLogoUriTooLong: 1,
        NewLogoUriNotAbsolute: 2,
        NewLogoUriUnacceptableScheme: 3,
    },
};

export const ChangeTenantLogo = {
    ErrorCodes: {
        NewLogoUriTooLong: 1,
        NewLogoUriNotAbsolute: 2,
        NewLogoUriUnacceptableScheme: 3,
    },
};

export const ChangeTenantName = {
    ErrorCodes: {
        NewNameMissingOrEmpty: 1,
        NewNameTooLong: 2,
    },
};

export const CopyCompetition = {
    ErrorCodes: {
        CompetitionIdAlreadyInUse: 1,
        SeasonNotFound: 2,
        GroupNotFound: 3,
        NameMissingOrEmpty: 4,
        NameTooLong: 5,
        CompetitionToCopyFromNotFound: 6,
    },
};

export const DeleteMatch = {
    ErrorCodes: {
        MatchNotFound: 1,
        MatchIsKnockoutPhaseMatch: 2,
    },
};

export const DeleteMatchGroupFromCustomPhase = {
    ErrorCodes: {
        PhaseNotFoundOrNotACustomPhase: 1,
        GroupNotFound: 2,
    },
};

export const DeletePhaseMatchSchedule = {
    ErrorCodes: {
        PhaseNotFound: 1,
        ScheduleNotGenerated: 2,
    },
};

export const DeletePhoto = {
    ErrorCodes: {
        PhotoNotFound: 1,
    },
};

export const DeleteTablePointsModifier = {
    ErrorCodes: {
        TablePointsModifierNotFoundInPhase: 1,
    },
};

export const DeleteTeam = {
    ErrorCodes: {
        TeamNotFound: 1,
    },
};

export const DeleteVideo = {
    ErrorCodes: {
        VideoNotFound: 1,
    },
};

export const EditArticle = {
    ErrorCodes: {
        CompetitionNotFound: 1,
        ArticleNotFound: 101,
        TitleMissingOrEmpty: 102,
        TitleTooLong: 103,
        ContentMissingOrEmpty: 104,
        SummaryMissingOrEmpty: 105,
        ThumbnailPhotoUriTooLong: 106,
        OneOfTagsTooLong: 108,
        OneOfTagsEmpty: 109,
        SummaryTooLong: 110,
        DatePublishedInvalid: 111,
        DatePublishedRequired: 112,
    },
};

export const EditMatchArticle = {
    ErrorCodes: {
        MatchNotFound: 1,
        ArticleNotRelatedToMatch: 1,
        ArticleNotFound: 101,
        TitleMissingOrEmpty: 102,
        TitleTooLong: 103,
        ContentMissingOrEmpty: 104,
        SummaryMissingOrEmpty: 105,
        ThumbnailPhotoUriTooLong: 106,
        OneOfTagsTooLong: 108,
        OneOfTagsEmpty: 109,
        SummaryTooLong: 110,
        DatePublishedInvalid: 111,
        DatePublishedRequired: 112,
    },
};

export const EditVideo = {
    ErrorCodes: {
        VideoNotFound: 1,
        TitleMissingOrEmpty: 2,
        TitleTooLong: 3,
        UriMissingOrEmpty: 4,
        UriTooLong: 5,
        UriNotValid: 6,
        ThumbnailUriMissingOrEmpty: 7,
        ThumbnailUriTooLong: 8,
        ThumbnailUriNotValid: 9,
    },
};

export const EndPhase = {
    ErrorCodes: {
        PhaseNotFound: 1,
        PhaseNotOngoing: 2,
    },
};

export const GenerateGroupsPhaseMatchSchedule = {
    ErrorCodes: {
        PhaseNotFound: 1,
        PhaseHasNoGroups: 2,
        GroupHasTooFewTeams: 3,
        MatchesPerPairOutOfRange: 4,
        ScheduleAlreadyGenerated: 5,
    },
};

export const GenerateKnockoutPhaseMatchSchedule = {
    ErrorCodes: {
        PhaseNotFound: 1,
        InitialTeamOrderMissingOrEmpty: 2,
        InitialTeamOrderContainsDuplicates: 3,
        NumberOfTeamsMustBeAPowerOfTwo: 4,
        ScheduleAlreadyGenerated: 5,
        InitialTeamOrderIsNotASubsetOfAllTeamsInPhase: 6,
        TooFewTeamsForAdditionalNthPlaceTree: 7,
    },
};

export const GenerateTablePhaseMatchSchedule = {
    ErrorCodes: {
        PhaseNotFound: 1,
        PhaseHasTooFewTeams: 2,
        MatchesPerPairOutOfRange: 3,
        ScheduleAlreadyGenerated: 4,
        InvalidGenerationMode: 5,
        MatchesPerTeamOutOfRange: 6,
    },
};

export const HideCompetition = {
    ErrorCodes: {
        CompetitionNotFound: 1,
        CompetitionAlreadyHidden: 2,
    },
};

export const HidePhase = {
    ErrorCodes: {
        PhaseNotFound: 1,
    },
};

export const HideSeason = {
    ErrorCodes: {
        SeasonNotFound: 1,
        SeasonAlreadyHidden: 2,
    },
};

export const InvitePlayerToProfile = {
    ErrorCodes: {
        TeamNotFound: 1,
        PhoneNumberNullOrEmpty: 2,
        PhoneNumberInvalidFormat: 3,
        InvitationAlreadyExists: 4,
        UserNotAPlayerInTeam: 5,
        UserAlreadyHasAccount: 6,
    },
};

export const InviteTeamAdmin = {
    ErrorCodes: {
        TeamNotFound: 1,
        TeamIsAPlaceholder: 2,
        PhoneNumberNullOrEmpty: 3,
        PhoneNumberInvalidFormat: 4,
        InvitationAlreadyExists: 5,
    },
};

export const MergeProfiles = {
    ErrorCodes: {
        User1NotFound: 1,
        User2NotFound: 2,
        ProfileCannotBeMergedWithItself: 3,
        BothProfilesAreOwned: 4,
    },
};

export const MergeTeam = {
    ErrorCodes: {
        TeamNotFound: 1,
        TeamToBeMergedInNotFound: 2,
        TeamsCompetitionsOverlap: 3,
    },
};

export const PlanPhaseSchedule = {
    ErrorCodes: {
        PhaseNotFoundInCompetition: 1,
        OneOfMatchesNotFound: 2,
        OneOfMatchesIsAssignedToMultipleSlots: 3,
        OneOfSportsFieldsNotFound: 4,
        DefaultMatchDurationInMinutesInvalid: 5,
        DefaultPauseBetweenMatchesInMinutesInvalid: 6,
    },
};

export const PublishMatchReport = {
    ErrorCodes: {
        MatchNotFound: 1,
        MatchCannotHaveReportAdded: 2,
        KnockoutPhaseMatchesCannotEndWithADraw: 3,
        PresentPlayersNullOrMissing: 4,
        PlayerNotFound: 5,
        PlayerIdNotInPresentPlayers: 6,
        EventsNullOrMissing: 7,
        EventsOutOfOrder: 8,
        EventNotValid: 9,
        ResultNullOrMissing: 10,
        ResultNotValid: 11,
        PlayersHaveDuplicates: 12,
    },
};

export const RecreateMatch = {
    ErrorCodes: {
        MatchNotFound: 1,
        MatchTeamDeleted: 2,
        NextStageMatchNotInUpcomingState: 3,
    },
};

export const RegisterTenant = {
    ErrorCodes: {
        EmailAddressMissingOrEmpty: 1,
        EmailAddressTooLong: 2,
        EmailAddressNotValid: 3,
        NameMissingOrEmpty: 4,
        NameTooLong: 5,
    },
};

export const RemoveAdminFromTeam = {
    ErrorCodes: {
        TeamNotFound: 1,
        UserNotFound: 2,
    },
};

export const RemoveManagerUser = {
    ErrorCodes: {
        CannotRemoveSelf: 1,
        ManagerUserNotFound: 2,
    },
};

export const RemovePhase = {
    ErrorCodes: {
        PhaseNotFound: 1,
        PhaseReferencedByOtherPhase: 2,
    },
};

export const RemovePlayerFromTeam = {
    ErrorCodes: {
        TeamNotFound: 1,
        UserNotFound: 2,
    },
};

export const RemoveReferee = {
    ErrorCodes: {
        PhoneNumberNullOrEmpty: 1,
        PhoneNumberInvalidFormat: 2,
        RefereeNotFound: 3,
    },
};

export const RemoveTeamFromPhase = {
    ErrorCodes: {
        PhaseNotFound: 1,
        TeamNotInPhase: 2,
        TeamIsAdvancedViaExternalReference: 3,
    },
};

export const RenameMatchGroupInCustomPhase = {
    ErrorCodes: {
        PhaseNotFound: 1,
        GroupNotFound: 2,
        NameMissingOrEmpty: 3,
        NameTooLong: 4,
    },
};

export const RenamePhase = {
    ErrorCodes: {
        PhaseNotFound: 1,
        NameTooLong: 2,
    },
};

export const RenamePlaceholderTeamForPhase = {
    ErrorCodes: {
        TeamNotFound: 1,
        TeamIsNotAPlaceholderForSinglePhaseOnly: 2,
        NameMissingOrEmpty: 3,
        NameTooLong: 4,
    },
};

export const ReorderCompetitionGroups = {
    ErrorCodes: {
        DuplicateGroupId: 1,
        GroupNotFound: 2,
        OrderedGroupIdsMissingOrEmpty: 3,
    },
};

export const ReorderCompetitions = {
    ErrorCodes: {
        SeasonNotFound: 1,
        DuplicateCompetitionId: 2,
        CompetitionNotFound: 3,
        OrderedCompetitionIdsMissingOrEmpty: 4,
    },
};

export const ReorderMatchGroupsInCustomPhase = {
    ErrorCodes: {
        PhaseNotFound: 1,
        LocalGroupIdsNullOrMissing: 2,
        LocalGroupIdsContainsDuplicates: 3,
        GroupNotFound: 4,
    },
};

export const ReplacePlaceholderTeamForPhase = {
    ErrorCodes: {
        CompetitionNotFound: 1,
        PhaseNotFoundInCompetition: 2,
        SomePlaceholderTeamsForPhaseNotFound: 3,
        SomeReplacementTeamsNotFoundInCompetition: 4,
        SomeReplacementTeamsAlreadyAddedToPhase: 5,
        SomeReplacementTeamsArePlaceholders: 6,
    },
};

export const ReplaceTeamWithCopy = {
    ErrorCodes: {
        ReplacedTeamNotFound: 1,
        ReplacementTeamNotFound: 2,
        ReplacementTeamAlreadyCompetes: 3,
        ReplacementTeamIsAPlaceholder: 4,
    },
};

export const ReplaceTeamWithNew = {
    ErrorCodes: {
        TeamNotFound: 1,
        NameNullOrEmpty: 2,
        NameTooLong: 3,
        ShortNameTooLong: 4,
        AgeGroupTooLong: 5,
    },
};

export const ResetPassword = {
    ErrorCodes: {
        UserDoesNotExist: 1,
        TokenMissingOrEmpty: 2,
        InvalidToken: 3,
        NewPasswordMissingOrEmpty: 4,
        NewPasswordTooWeak: 5,
    },
};

export const RevokeTeamAdvancing = {
    ErrorCodes: {
        PhaseNotFound: 1,
        ExternalReferenceNotFound: 2,
        ExternalReferenceWithNoTeamAdvanced: 3,
        ReplacementPlaceholderIdAlreadyInUse: 4,
    },
};

export const SelectProfilePicture = {
    ErrorCodes: {
        UserProfileNotFound: 1,
        PictureNotFound: 2,
    },
};

export const SendPasswordRecoveryEmail = {
    ErrorCodes: {
        MissingEmailAddress: 1,
        InvalidEmailAddress: 2,
    },
};

export const SetCompetitionSportsFields = {
    ErrorCodes: {
        CompetitionNotFound: 1,
        SportsFieldsMissingOrEmpty: 2,
        OneOfSportsFieldsNameMissingOrEmpty: 3,
        OneOfSportsFieldsNameTooLong: 4,
        OneOfSportsFieldsIdAlreadyInUse: 5,
        SportsFieldsDeletionNotSupported: 6,
    },
};

export const SetGroupsPhaseTeamGroups = {
    ErrorCodes: {
        PhaseNotFound: 1,
        PhaseScheduleGenerated: 2,
        TeamGroupsNullOrMissing: 3,
        TeamGroupDuplicateId: 4,
        TeamGroupNameMissingOrEmpty: 5,
        TeamGroupNameTooLong: 6,
        TeamGroupTeamIdsMissing: 7,
        TeamGroupTeamIdsTooMany: 8,
        TeamGroupTeamNotFound: 9,
        TeamGroupTeamInMultipleGroups: 10,
        PhaseReferencedByOtherPhase: 11,
    },
};

export const SetMatchDate = {
    ErrorCodes: {
        MatchNotFound: 1,
    },
};

export const SetPhaseTieBreakingOrder = {
    ErrorCodes: {
        PhaseNotFound: 1,
        OneOfTeamsIsNotInThePhase: 2,
        TieBreakingOrderMustBeUnique: 3,
        TieBreakingOrderMustCoverAllTeamsInPhase: 4,
        TieBreakingOrderMustBePositive: 5,
    },
};

export const SwapTeamsInPhase = {
    ErrorCodes: {
        PhaseNotFound: 1,
        Team1NotFound: 2,
        Team2NotFound: 3,
        Team1AndTeam2AreTheSame: 4,
    },
};

export const UnhidePhase = {
    ErrorCodes: {
        PhaseNotFound: 1,
    },
};

export const UpdateCompetition = {
    ErrorCodes: {
        CompetitionNotFound: 1,
        SeasonNotFound: 2,
        GroupNotFound: 3,
        NameMissingOrEmpty: 4,
        NameTooLong: 5,
    },
};

export const UpdateCompetitionGroup = {
    ErrorCodes: {
        CompetitionGroupNotFound: 1,
        NameMissingOrEmpty: 2,
        NameTooLong: 3,
    },
};

export const UpdateCompetitionSettings = {
    ErrorCodes: {
        CompetitionNotFound: 1,
        PlayerRankingsConfigNullOrMissing: 2,
    },
};

export const UpdateCustomPhaseMatch = {
    ErrorCodes: {
        MatchNotFoundOrNotACustomPhaseMatch: 1,
        ChangingTeamsNotAllowed: 2,
        TeamCannotPlayAgainstItself: 3,
        TeamNotFoundInPhase: 4,
        GroupNotFound: 5,
        NameTooLong: 6,
        SportsFieldNotFound: 7,
        ChangingFinishedMatchStatusNotAllowed: 8,
    },
};

export const UpdateFinalRankingConfiguration = {
    ErrorCodes: {
        CompetitionNotFound: 1,
        EntryNotValid: 2,
    },
};

export const UpdateGroupsPhaseMatch = {
    ErrorCodes: {
        MatchNotFoundOrNotAGroupsPhaseMatch: 1,
        GroupNotFound: 2,
        TeamNotFoundInGroup: 3,
        TeamCannotPlayAgainstItself: 4,
        ChangingTeamNotAllowed: 5,
        SportsFieldNotFound: 6,
        MatchdayNotPositive: 7,
        ChangingFinishedMatchStatusNotAllowed: 8,
        NameTooLong: 9,
    },
};

export const UpdateKnockoutPhaseMatch = {
    ErrorCodes: {
        MatchNotFoundOrNotAKnockoutPhaseMatch: 1,
        SportsFieldNotFound: 2,
        NameTooLong: 3,
    },
};

export const UpdateMatchReport = {
    ErrorCodes: {
        MatchNotFound: 1,
        MatchReportNotFound: 2,
        EventsNullOrMissing: 3,
        EventsOutOfOrder: 4,
        EventNotValid: 5,
        ResultNullOrMissing: 6,
        ResultNotValid: 7,
        PlayerNotFoundInPresentPlayersForTeam: 8,
        KnockoutPhaseMatchesCannotHaveWinnerChanged: 9,
        PlayerNotFound: 10,
        PlayersHaveDuplicates: 11,
    },
};

export const UpdatePlayer = {
    ErrorCodes: {
        TeamNotFound: 1,
        PlayerNotFound: 2,
        FirstNameNullOrEmpty: 3,
        FirstNameTooLong: 4,
        LastNameNullOrEmpty: 5,
        LastNameTooLong: 6,
        ShirtNumberOutOfRange: 7,
    },
};

export const UpdateSeason = {
    ErrorCodes: {
        SeasonNotFound: 1,
        NameMissingOrEmpty: 2,
        NameTooLong: 3,
        ExpectedStartDateOutOfRange: 4,
    },
};

export const UpdateSeasonState = {
    ErrorCodes: {
        SeasonNotFound: 1,
        StateNotInEnumerationValues: 2,
    },
};

export const UpdateTablePhaseMatch = {
    ErrorCodes: {
        MatchNotFoundOrNotATablePhaseMatch: 1,
        TeamNotFoundInPhase: 2,
        TeamCannotPlayAgainstItself: 3,
        ChangingTeamNotAllowed: 4,
        SportsFieldNotFound: 5,
        MatchdayNotPositive: 6,
        ChangingFinishedMatchStatusNotAllowed: 7,
        NameTooLong: 8,
    },
};

export const UpdateTeam = {
    ErrorCodes: {
        TeamNotFound: 1,
        TeamIsAPlaceholder: 2,
        NameNullOrEmpty: 3,
        NameTooLong: 4,
        ShortNameTooLong: 5,
        AgeGroupTooLong: 6,
    },
};

export const UpdateTeamLogo = {
    ErrorCodes: {
        TeamNotFound: 1,
        TeamIsAPlaceholder: 2,
        LogoUriTooLong: 3,
    },
};

export const UpdateTeamPhoto = {
    ErrorCodes: {
        TeamNotFound: 1,
        TeamIsAPlaceholder: 2,
        PhotoUriTooLong: 3,
        PhotoUriNotValid: 4,
    },
};

export default function (cqrsClient: CQRS) {
    return {
        addArticle: (dto: AddArticle) => cqrsClient.executeCommand<typeof AddArticle["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Articles.AddArticle", dto),
        addCompetition: (dto: AddCompetition) => cqrsClient.executeCommand<typeof AddCompetition["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.AddCompetition", dto),
        addCompetitionGroup: (dto: AddCompetitionGroup) => cqrsClient.executeCommand<typeof AddCompetitionGroup["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.CompetitionGroups.AddCompetitionGroup", dto),
        addCopiesOfTeams: (dto: AddCopiesOfTeams) => cqrsClient.executeCommand<typeof AddCopiesOfTeams["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.Teams.AddCopiesOfTeams", dto),
        addCustomPhase: (dto: AddCustomPhase) => cqrsClient.executeCommand<{}>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.Phases.AddCustomPhase", dto),
        addCustomPhaseMatch: (dto: AddCustomPhaseMatch) => cqrsClient.executeCommand<typeof AddCustomPhaseMatch["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Matches.AddCustomPhaseMatch", dto),
        addExistingPlayersToTeam: (dto: AddExistingPlayersToTeam) => cqrsClient.executeCommand<typeof AddExistingPlayersToTeam["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.Teams.AddExistingPlayersToTeam", dto),
        addGroupsPhase: (dto: AddGroupsPhase) => cqrsClient.executeCommand<{}>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.Phases.AddGroupsPhase", dto),
        addGroupsPhaseMatch: (dto: AddGroupsPhaseMatch) => cqrsClient.executeCommand<typeof AddGroupsPhaseMatch["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Matches.AddGroupsPhaseMatch", dto),
        addKnockoutPhase: (dto: AddKnockoutPhase) => cqrsClient.executeCommand<{}>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.Phases.AddKnockoutPhase", dto),
        addManagerUser: (dto: AddManagerUser) => cqrsClient.executeCommand<typeof AddManagerUser["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Tenants.AddManagerUser", dto),
        addMatchArticle: (dto: AddMatchArticle) => cqrsClient.executeCommand<typeof AddMatchArticle["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Articles.AddMatchArticle", dto),
        addMatchGroupToCustomPhase: (dto: AddMatchGroupToCustomPhase) => cqrsClient.executeCommand<typeof AddMatchGroupToCustomPhase["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.Phases.AddMatchGroupToCustomPhase", dto),
        addNewPlayerToTeam: (dto: AddNewPlayerToTeam) => cqrsClient.executeCommand<typeof AddNewPlayerToTeam["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.Teams.AddNewPlayerToTeam", dto),
        addNewTeam: (dto: AddNewTeam) => cqrsClient.executeCommand<typeof AddNewTeam["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.Teams.AddNewTeam", dto),
        addNthBestForPlaceInGroupReferences: (dto: AddNthBestForPlaceInGroupReferences) => cqrsClient.executeCommand<typeof AddNthBestForPlaceInGroupReferences["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.Phases.AddNthBestForPlaceInGroupReferences", dto),
        addPhotoForMatch: (dto: AddPhotoForMatch) => cqrsClient.executeCommand<typeof AddPhotoForMatch["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Photos.AddPhotoForMatch", dto),
        addPlaceholderTeam: (dto: AddPlaceholderTeam) => cqrsClient.executeCommand<typeof AddPlaceholderTeam["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.Teams.AddPlaceholderTeam", dto),
        addPlaceholderTeamForPhase: (dto: AddPlaceholderTeamForPhase) => cqrsClient.executeCommand<typeof AddPlaceholderTeamForPhase["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.Teams.AddPlaceholderTeamForPhase", dto),
        addPlaceInGroupReferencesToPhase: (dto: AddPlaceInGroupReferencesToPhase) => cqrsClient.executeCommand<typeof AddPlaceInGroupReferencesToPhase["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.Phases.AddPlaceInGroupReferencesToPhase", dto),
        addPlaceInTableReferencesToPhase: (dto: AddPlaceInTableReferencesToPhase) => cqrsClient.executeCommand<typeof AddPlaceInTableReferencesToPhase["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.Phases.AddPlaceInTableReferencesToPhase", dto),
        addProfilePicture: (dto: AddProfilePicture) => cqrsClient.executeCommand<typeof AddProfilePicture["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Profiles.AddProfilePicture", dto),
        addReferees: (dto: AddReferees) => cqrsClient.executeCommand<typeof AddReferees["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.Referees.AddReferees", dto),
        addSeason: (dto: AddSeason) => cqrsClient.executeCommand<typeof AddSeason["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Seasons.AddSeason", dto),
        addTablePhase: (dto: AddTablePhase) => cqrsClient.executeCommand<{}>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.Phases.AddTablePhase", dto),
        addTablePhaseMatch: (dto: AddTablePhaseMatch) => cqrsClient.executeCommand<typeof AddTablePhaseMatch["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Matches.AddTablePhaseMatch", dto),
        addTablePointsModifier: (dto: AddTablePointsModifier) => cqrsClient.executeCommand<typeof AddTablePointsModifier["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.Phases.AddTablePointsModifier", dto),
        addTeamsToPhase: (dto: AddTeamsToPhase) => cqrsClient.executeCommand<typeof AddTeamsToPhase["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.Phases.AddTeamsToPhase", dto),
        addVideo: (dto: AddVideo) => cqrsClient.executeCommand<typeof AddVideo["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Videos.AddVideo", dto),
        advanceTeamsForPhaseRelatedReferences: (dto: AdvanceTeamsForPhaseRelatedReferences) => cqrsClient.executeCommand<typeof AdvanceTeamsForPhaseRelatedReferences["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.Phases.AdvanceTeamsForPhaseRelatedReferences", dto),
        allArticles: (dto: AllArticles) => cqrsClient.executeQuery<ArticleDTO[]>("PlayooLeague.Core.Contracts.LeagueManager.Articles.AllArticles", dto),
        allCompetitionGroups: (dto: AllCompetitionGroups) => cqrsClient.executeQuery<CompetitionGroupDTO[]>("PlayooLeague.Core.Contracts.LeagueManager.CompetitionGroups.AllCompetitionGroups", dto),
        allCompetitions: (dto: AllCompetitions) => cqrsClient.executeQuery<CompetitionWithSeasonIdDTO[]>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.AllCompetitions", dto),
        allSeasons: (dto: AllSeasons) => cqrsClient.executeQuery<SeasonDTO[]>("PlayooLeague.Core.Contracts.LeagueManager.Seasons.AllSeasons", dto),
        allVideos: (dto: AllVideos) => cqrsClient.executeQuery<VideoDTO[]>("PlayooLeague.Core.Contracts.LeagueManager.Videos.AllVideos", dto),
        articleDetails: (dto: ArticleDetails) => cqrsClient.executeQuery<ArticleDetailsDTO>("PlayooLeague.Core.Contracts.LeagueManager.Articles.ArticleDetails", dto),
        beginPhase: (dto: BeginPhase) => cqrsClient.executeCommand<typeof BeginPhase["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.Phases.BeginPhase", dto),
        cancelInvitation: (dto: CancelInvitation) => cqrsClient.executeCommand<typeof CancelInvitation["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Invitations.CancelInvitation", dto),
        changeAccessCode: (dto: ChangeAccessCode) => cqrsClient.executeCommand<typeof ChangeAccessCode["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Tenants.ChangeAccessCode", dto),
        changeMatchday: (dto: ChangeMatchday) => cqrsClient.executeCommand<typeof ChangeMatchday["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Matches.ChangeMatchday", dto),
        changeTenantLandingLogo: (dto: ChangeTenantLandingLogo) => cqrsClient.executeCommand<typeof ChangeTenantLandingLogo["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Tenants.ChangeTenantLandingLogo", dto),
        changeTenantLogo: (dto: ChangeTenantLogo) => cqrsClient.executeCommand<typeof ChangeTenantLogo["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Tenants.ChangeTenantLogo", dto),
        changeTenantName: (dto: ChangeTenantName) => cqrsClient.executeCommand<typeof ChangeTenantName["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Tenants.ChangeTenantName", dto),
        competitionDetails: (dto: CompetitionDetails) => cqrsClient.executeQuery<CompetitionDetailsDTO>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.CompetitionDetails", dto),
        competitionReferees: (dto: CompetitionReferees) => cqrsClient.executeQuery<RefereeDTO[]>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.Referees.CompetitionReferees", dto),
        competitionSettings: (dto: CompetitionSettings) => cqrsClient.executeQuery<CompetitionSettingsDTO>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.Settings.CompetitionSettings", dto),
        competitionsInSeason: (dto: CompetitionsInSeason) => cqrsClient.executeQuery<CompetitionDTO[]>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.CompetitionsInSeason", dto),
        copyCompetition: (dto: CopyCompetition) => cqrsClient.executeCommand<typeof CopyCompetition["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.CopyCompetition", dto),
        deleteMatch: (dto: DeleteMatch) => cqrsClient.executeCommand<typeof DeleteMatch["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Matches.DeleteMatch", dto),
        deleteMatchGroupFromCustomPhase: (dto: DeleteMatchGroupFromCustomPhase) => cqrsClient.executeCommand<typeof DeleteMatchGroupFromCustomPhase["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.Phases.DeleteMatchGroupFromCustomPhase", dto),
        deletePhaseMatchSchedule: (dto: DeletePhaseMatchSchedule) => cqrsClient.executeCommand<typeof DeletePhaseMatchSchedule["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.Phases.DeletePhaseMatchSchedule", dto),
        deletePhoto: (dto: DeletePhoto) => cqrsClient.executeCommand<typeof DeletePhoto["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Photos.DeletePhoto", dto),
        deleteTablePointsModifier: (dto: DeleteTablePointsModifier) => cqrsClient.executeCommand<typeof DeleteTablePointsModifier["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.Phases.DeleteTablePointsModifier", dto),
        deleteTeam: (dto: DeleteTeam) => cqrsClient.executeCommand<typeof DeleteTeam["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.Teams.DeleteTeam", dto),
        deleteVideo: (dto: DeleteVideo) => cqrsClient.executeCommand<typeof DeleteVideo["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Videos.DeleteVideo", dto),
        editArticle: (dto: EditArticle) => cqrsClient.executeCommand<typeof EditArticle["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Articles.EditArticle", dto),
        editMatchArticle: (dto: EditMatchArticle) => cqrsClient.executeCommand<typeof EditMatchArticle["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Articles.EditMatchArticle", dto),
        editVideo: (dto: EditVideo) => cqrsClient.executeCommand<typeof EditVideo["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Videos.EditVideo", dto),
        endPhase: (dto: EndPhase) => cqrsClient.executeCommand<typeof EndPhase["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.Phases.EndPhase", dto),
        finalRankingConfiguration: (dto: FinalRankingConfiguration) => cqrsClient.executeQuery<FinalRankingConfigurationDTO | undefined | null>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.FinalRankingConfiguration", dto),
        finalRankingExportSpreadsheet: (dto: FinalRankingExportSpreadsheet) => cqrsClient.executeQuery<string | undefined | null>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.FinalRankingExportSpreadsheet", dto),
        generateGroupsPhaseMatchSchedule: (dto: GenerateGroupsPhaseMatchSchedule) => cqrsClient.executeCommand<typeof GenerateGroupsPhaseMatchSchedule["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.Phases.GenerateGroupsPhaseMatchSchedule", dto),
        generateKnockoutPhaseMatchSchedule: (dto: GenerateKnockoutPhaseMatchSchedule) => cqrsClient.executeCommand<typeof GenerateKnockoutPhaseMatchSchedule["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.Phases.GenerateKnockoutPhaseMatchSchedule", dto),
        generatePresignedPhotoUploadLink: (dto: GeneratePresignedPhotoUploadLink) => cqrsClient.executeQuery<UploadLinksDTO>("PlayooLeague.Core.Contracts.LeagueManager.Storage.GeneratePresignedPhotoUploadLink", dto),
        generatePresignedVideoThumbnailUploadLink: (dto: GeneratePresignedVideoThumbnailUploadLink) => cqrsClient.executeQuery<UploadLinksDTO>("PlayooLeague.Core.Contracts.LeagueManager.Storage.GeneratePresignedVideoThumbnailUploadLink", dto),
        generateTablePhaseMatchSchedule: (dto: GenerateTablePhaseMatchSchedule) => cqrsClient.executeCommand<typeof GenerateTablePhaseMatchSchedule["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.Phases.GenerateTablePhaseMatchSchedule", dto),
        hideCompetition: (dto: HideCompetition) => cqrsClient.executeCommand<typeof HideCompetition["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.HideCompetition", dto),
        hidePhase: (dto: HidePhase) => cqrsClient.executeCommand<typeof HidePhase["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.Phases.HidePhase", dto),
        hideSeason: (dto: HideSeason) => cqrsClient.executeCommand<typeof HideSeason["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.HideSeason", dto),
        invitePlayerToProfile: (dto: InvitePlayerToProfile) => cqrsClient.executeCommand<typeof InvitePlayerToProfile["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Invitations.InvitePlayerToProfile", dto),
        inviteTeamAdmin: (dto: InviteTeamAdmin) => cqrsClient.executeCommand<typeof InviteTeamAdmin["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Invitations.InviteTeamAdmin", dto),
        managerUsers: (dto: ManagerUsers) => cqrsClient.executeQuery<ManagerUserDTO[]>("PlayooLeague.Core.Contracts.LeagueManager.Tenants.ManagerUsers", dto),
        matchDetails: (dto: MatchDetails) => cqrsClient.executeQuery<MatchDetailsDTO>("PlayooLeague.Core.Contracts.LeagueManager.Matches.MatchDetails", dto),
        matchPhotos: (dto: MatchPhotos) => cqrsClient.executeQuery<PhotoDTO[]>("PlayooLeague.Core.Contracts.LeagueManager.Photos.MatchPhotos", dto),
        mergeProfiles: (dto: MergeProfiles) => cqrsClient.executeCommand<typeof MergeProfiles["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Profiles.MergeProfiles", dto),
        mergeTeam: (dto: MergeTeam) => cqrsClient.executeCommand<typeof MergeTeam["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.Teams.MergeTeam", dto),
        phaseDetails: (dto: PhaseDetails) => cqrsClient.executeQuery<CompetitionPhaseDetailsDTO>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.Phases.PhaseDetails", dto),
        planPhaseSchedule: (dto: PlanPhaseSchedule) => cqrsClient.executeCommand<typeof PlanPhaseSchedule["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.Phases.PlanPhaseSchedule", dto),
        playerRankingsExportSpreadsheet: (dto: PlayerRankingsExportSpreadsheet) =>cqrsClient.executeQuery<string | undefined | null>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.PlayerRankingsExportSpreadsheet", dto),
        playersAutocompleteData: (dto: PlayersAutocompleteData) => cqrsClient.executeQuery<PlayersAutocompleteDataDTO>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.Teams.PlayersAutocompleteData", dto),
        profilePicturesCreateToken: (dto: ProfilePicturesCreateToken) => cqrsClient.executeQuery<AzureStorageTokenDTO>("PlayooLeague.Core.Contracts.LeagueManager.Storage.ProfilePicturesCreateToken", dto),
        publishMatchReport: (dto: PublishMatchReport) => cqrsClient.executeCommand<typeof PublishMatchReport["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Matches.PublishMatchReport", dto),
        recreateMatch: (dto: RecreateMatch) => cqrsClient.executeCommand<typeof RecreateMatch["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Matches.RecreateMatch", dto),
        refereesAutocompleteData: (dto: RefereesAutocompleteData) => cqrsClient.executeQuery<RefereeDTO[]>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.Referees.RefereesAutocompleteData", dto),
        registerTenant: (dto: RegisterTenant) => cqrsClient.executeCommand<typeof RegisterTenant["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Tenants.RegisterTenant", dto),
        removeAdminFromTeam: (dto: RemoveAdminFromTeam) => cqrsClient.executeCommand<typeof RemoveAdminFromTeam["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.Teams.RemoveAdminFromTeam", dto),
        removeManagerUser: (dto: RemoveManagerUser) => cqrsClient.executeCommand<typeof RemoveManagerUser["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Tenants.RemoveManagerUser", dto),
        removePhase: (dto: RemovePhase) => cqrsClient.executeCommand<typeof RemovePhase["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.Phases.RemovePhase", dto),
        removePlayerFromTeam: (dto: RemovePlayerFromTeam) => cqrsClient.executeCommand<typeof RemovePlayerFromTeam["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.Teams.RemovePlayerFromTeam", dto),
        removeReferee: (dto: RemoveReferee) => cqrsClient.executeCommand<typeof RemoveReferee["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.Referees.RemoveReferee", dto),
        removeTeamFromPhase: (dto: RemoveTeamFromPhase) => cqrsClient.executeCommand<typeof RemoveTeamFromPhase["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.Phases.RemoveTeamFromPhase", dto),
        renameMatchGroupInCustomPhase: (dto: RenameMatchGroupInCustomPhase) => cqrsClient.executeCommand<typeof RenameMatchGroupInCustomPhase["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.Phases.RenameMatchGroupInCustomPhase", dto),
        renamePhase: (dto: RenamePhase) => cqrsClient.executeCommand<typeof RenamePhase["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.Phases.RenamePhase", dto),
        renamePlaceholderTeamForPhase: (dto: RenamePlaceholderTeamForPhase) => cqrsClient.executeCommand<typeof RenamePlaceholderTeamForPhase["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.Teams.RenamePlaceholderTeamForPhase", dto),
        reorderCompetitionGroups: (dto: ReorderCompetitionGroups) => cqrsClient.executeCommand<typeof ReorderCompetitionGroups["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.CompetitionGroups.ReorderCompetitionGroups", dto),
        reorderCompetitions: (dto: ReorderCompetitions) => cqrsClient.executeCommand<typeof ReorderCompetitions["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.ReorderCompetitions", dto),
        reorderMatchGroupsInCustomPhase: (dto: ReorderMatchGroupsInCustomPhase) => cqrsClient.executeCommand<typeof ReorderMatchGroupsInCustomPhase["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.Phases.ReorderMatchGroupsInCustomPhase", dto),
        replacePlaceholderTeamForPhase: (dto: ReplacePlaceholderTeamForPhase) => cqrsClient.executeCommand<typeof ReplacePlaceholderTeamForPhase["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.Teams.ReplacePlaceholderTeamForPhase", dto),
        replaceTeamWithCopy: (dto: ReplaceTeamWithCopy) => cqrsClient.executeCommand<typeof ReplaceTeamWithCopy["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.Teams.ReplaceTeamWithCopy", dto),
        replaceTeamWithNew: (dto: ReplaceTeamWithNew) => cqrsClient.executeCommand<typeof ReplaceTeamWithNew["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.Teams.ReplaceTeamWithNew", dto),
        resetPassword: (dto: ResetPassword) => cqrsClient.executeCommand<typeof ResetPassword["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.PasswordRecovery.ResetPassword", dto),
        revokeTeamAdvancing: (dto: RevokeTeamAdvancing) => cqrsClient.executeCommand<typeof RevokeTeamAdvancing["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.Phases.RevokeTeamAdvancing", dto),
        seasonScheduleExportSpreadsheet: (dto: SeasonScheduleExportSpreadsheet) => cqrsClient.executeQuery<string | undefined | null>("PlayooLeague.Core.Contracts.LeagueManager.Seasons.SeasonScheduleExportSpreadsheet", dto),
        selectProfilePicture: (dto: SelectProfilePicture) => cqrsClient.executeCommand<typeof SelectProfilePicture["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Profiles.SelectProfilePicture", dto),
        sendPasswordRecoveryEmail: (dto: SendPasswordRecoveryEmail) => cqrsClient.executeCommand<typeof SendPasswordRecoveryEmail["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.PasswordRecovery.SendPasswordRecoveryEmail", dto),
        setCompetitionSportsFields: (dto: SetCompetitionSportsFields) => cqrsClient.executeCommand<typeof SetCompetitionSportsFields["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.SportsFields.SetCompetitionSportsFields", dto),
        setGroupsPhaseTeamGroups: (dto: SetGroupsPhaseTeamGroups) => cqrsClient.executeCommand<typeof SetGroupsPhaseTeamGroups["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.Phases.SetGroupsPhaseTeamGroups", dto),
        setMatchDate: (dto: SetMatchDate) => cqrsClient.executeCommand<typeof SetMatchDate["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Matches.SetMatchDate", dto),
        setPhaseTieBreakingOrder: (dto: SetPhaseTieBreakingOrder) => cqrsClient.executeCommand<typeof SetPhaseTieBreakingOrder["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.Phases.SetPhaseTieBreakingOrder", dto),
        swapTeamsInPhase: (dto: SwapTeamsInPhase) => cqrsClient.executeCommand<typeof SwapTeamsInPhase["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.Phases.SwapTeamsInPhase", dto),
        teamDetails: (dto: TeamDetails) => cqrsClient.executeQuery<CompetitionTeamDetailsDTO>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.Teams.TeamDetails", dto),
        teamLogosCreateToken: (dto: TeamLogosCreateToken) => cqrsClient.executeQuery<AzureStorageTokenDTO>("PlayooLeague.Core.Contracts.LeagueManager.Storage.TeamLogosCreateToken", dto),
        teamPhotosCreateToken: (dto: TeamPhotosCreateToken) => cqrsClient.executeQuery<AzureStorageTokenDTO>("PlayooLeague.Core.Contracts.LeagueManager.Storage.TeamPhotosCreateToken", dto),
        teamsAutocompleteData: (dto: TeamsAutocompleteData) => cqrsClient.executeQuery<TeamsAutocompleteDataDTO>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.Teams.TeamsAutocompleteData", dto),
        tenantLogosCreateToken: (dto: TenantLogosCreateToken) => cqrsClient.executeQuery<AzureStorageTokenDTO>("PlayooLeague.Core.Contracts.LeagueManager.Storage.TenantLogosCreateToken", dto),
        tenantSettings: (dto: TenantSettings) => cqrsClient.executeQuery<TenantSettingsDTO>("PlayooLeague.Core.Contracts.LeagueManager.Tenants.TenantSettings", dto),
        unhidePhase: (dto: UnhidePhase) => cqrsClient.executeCommand<typeof UnhidePhase["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.Phases.UnhidePhase", dto),
        updateCompetition: (dto: UpdateCompetition) => cqrsClient.executeCommand<typeof UpdateCompetition["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.UpdateCompetition", dto),
        updateCompetitionGroup: (dto: UpdateCompetitionGroup) => cqrsClient.executeCommand<typeof UpdateCompetitionGroup["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.CompetitionGroups.UpdateCompetitionGroup", dto),
        updateCompetitionSettings: (dto: UpdateCompetitionSettings) => cqrsClient.executeCommand<typeof UpdateCompetitionSettings["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.Settings.UpdateCompetitionSettings", dto),
        updateCustomPhaseMatch: (dto: UpdateCustomPhaseMatch) => cqrsClient.executeCommand<typeof UpdateCustomPhaseMatch["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Matches.UpdateCustomPhaseMatch", dto),
        updateFinalRankingConfiguration: (dto: UpdateFinalRankingConfiguration) => cqrsClient.executeCommand<typeof UpdateFinalRankingConfiguration["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.UpdateFinalRankingConfiguration", dto), 
        updateGroupsPhaseMatch: (dto: UpdateGroupsPhaseMatch) => cqrsClient.executeCommand<typeof UpdateGroupsPhaseMatch["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Matches.UpdateGroupsPhaseMatch", dto),
        updateKnockoutPhaseMatch: (dto: UpdateKnockoutPhaseMatch) => cqrsClient.executeCommand<typeof UpdateKnockoutPhaseMatch["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Matches.UpdateKnockoutPhaseMatch", dto),
        updateMatchReport: (dto: UpdateMatchReport) => cqrsClient.executeCommand<typeof UpdateMatchReport["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Matches.UpdateMatchReport", dto),
        updatePlayer: (dto: UpdatePlayer) => cqrsClient.executeCommand<typeof UpdatePlayer["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.Teams.UpdatePlayer", dto),
        updateSeason: (dto: UpdateSeason) => cqrsClient.executeCommand<typeof UpdateSeason["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Seasons.UpdateSeason", dto),
        updateSeasonState: (dto: UpdateSeasonState) => cqrsClient.executeCommand<typeof UpdateSeasonState["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Seasons.UpdateSeasonState", dto),
        updateTablePhaseMatch: (dto: UpdateTablePhaseMatch) => cqrsClient.executeCommand<typeof UpdateTablePhaseMatch["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Matches.UpdateTablePhaseMatch", dto),
        updateTeam: (dto: UpdateTeam) => cqrsClient.executeCommand<typeof UpdateTeam["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.Teams.UpdateTeam", dto),
        updateTeamLogo: (dto: UpdateTeamLogo) => cqrsClient.executeCommand<typeof UpdateTeamLogo["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.Teams.UpdateTeamLogo", dto),
        updateTeamPhoto: (dto: UpdateTeamPhoto) => cqrsClient.executeCommand<typeof UpdateTeamPhoto["ErrorCodes"]>("PlayooLeague.Core.Contracts.LeagueManager.Competitions.Teams.UpdateTeamPhoto", dto),
        userProfilePictures: (dto: UserProfilePictures) => cqrsClient.executeQuery<string[]>("PlayooLeague.Core.Contracts.LeagueManager.Profiles.UserProfilePictures", dto),
    };
}
