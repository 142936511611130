import { Spin } from "antd";
import Form from "antd/lib/form";
import { FormInstance } from "antd/lib/form/util";
import { Select } from "Components/Select";
import { TablePhaseMatchScheduleGenerationModeDTO } from "Contracts/PlayooLeagueClient";
import TablePhase from "Domain/CompetitionPhases/TablePhase/TablePhase";
import { l } from "Languages";
import React, { useMemo, useState } from "react";
import guard from "Utils/guard";
import useRunInTask from "Utils/Hooks/useRunInTask";

type PhaseScheduleConfigurationFormProps = {
    form: FormInstance;
    onFinish: (values: PhaseScheduleConfigurationFormFields) => Promise<void>;
    teamsCount?: number;
};

const PhaseScheduleConfigurationForm: React.FunctionComponent<PhaseScheduleConfigurationFormProps> = ({
    form,
    onFinish,
    teamsCount,
}) => {
    const [isRunning, runInTask] = useRunInTask();

    const hasGenerationModes = teamsCount !== undefined;
    const availableMatchesPerTeam = useMemo(() => TablePhase.getValidNumbersOfMatchesPerTeam(teamsCount ?? 0), [
        teamsCount,
    ]);

    const initialValues: PhaseScheduleConfigurationFormFields = {
        generationMode: TablePhaseMatchScheduleGenerationModeDTO.MatchesPerPair,
        matchesPerPair: 1,
        matchesPerTeam: availableMatchesPerTeam[0],
    };

    const [generationMode, setGenerationMode] = useState<TablePhaseMatchScheduleGenerationModeDTO>(
        initialValues.generationMode,
    );

    return (
        <Spin spinning={isRunning}>
            <Form
                layout="vertical"
                form={form}
                onFinish={(values: PhaseScheduleConfigurationFormFields) => runInTask(() => onFinish(values))}
                initialValues={initialValues}>
                {hasGenerationModes && (
                    <Form.Item
                        label={l("CompetitionDetails_GeneratePhaseSchedule_Form_GenerationMode")}
                        name={guard<FormField>("generationMode")}>
                        <Select
                            onChange={value => setGenerationMode(value as TablePhaseMatchScheduleGenerationModeDTO)}
                            selectOptions={[
                                {
                                    label: l(
                                        "CompetitionDetails_GeneratePhaseSchedule_Form_GenerationMode_MatchesPerPair",
                                    ),
                                    value: TablePhaseMatchScheduleGenerationModeDTO.MatchesPerPair,
                                    key: TablePhaseMatchScheduleGenerationModeDTO.MatchesPerPair.toString(),
                                },
                                {
                                    label: l(
                                        "CompetitionDetails_GeneratePhaseSchedule_Form_GenerationMode_MatchesPerTeam",
                                    ),
                                    value: TablePhaseMatchScheduleGenerationModeDTO.MatchesPerTeam,
                                    key: TablePhaseMatchScheduleGenerationModeDTO.MatchesPerTeam.toString(),
                                },
                            ]}
                        />
                    </Form.Item>
                )}
                {generationMode === TablePhaseMatchScheduleGenerationModeDTO.MatchesPerPair && (
                    <Form.Item
                        label={l("CompetitionDetails_GeneratePhaseSchedule_Form_MatchesPerPair")}
                        name={guard<FormField>("matchesPerPair")}>
                        <Select
                            selectOptions={[
                                {
                                    label: l("CompetitionDetails_GeneratePhaseSchedule_Form_MatchesPerPair_One"),
                                    value: 1,
                                    key: "1",
                                },
                                {
                                    label: l("CompetitionDetails_GeneratePhaseSchedule_Form_MatchesPerPair_Two"),
                                    value: 2,
                                    key: "2",
                                },
                            ]}
                        />
                    </Form.Item>
                )}
                {generationMode === TablePhaseMatchScheduleGenerationModeDTO.MatchesPerTeam && hasGenerationModes && (
                    <Form.Item
                        label={l("CompetitionDetails_GeneratePhaseSchedule_Form_MatchesPerTeam")}
                        name={guard<FormField>("matchesPerTeam")}>
                        <Select
                            selectOptions={availableMatchesPerTeam.map(n => ({
                                label: n,
                                value: n,
                                key: n.toString(),
                            }))}
                        />
                    </Form.Item>
                )}
            </Form>
        </Spin>
    );
};

export type PhaseScheduleConfigurationFormFields = {
    generationMode: TablePhaseMatchScheduleGenerationModeDTO;
    matchesPerPair?: 1 | 2;
    matchesPerTeam?: number;
};

type FormField = keyof PhaseScheduleConfigurationFormFields;

export default PhaseScheduleConfigurationForm;
