import { ExclamationCircleOutlined } from "@ant-design/icons";
import mkCx from "@leancode/cx";
import { downloadFile } from "@leancodepl/utils";
import { message, Modal, Skeleton } from "antd";
import Button from "antd/es/button";
import Spacing from "Components/Spacing";
import Competition from "Domain/Competitions/Competition";
import { CompetitionFinalRanking } from "Domain/Competitions/CompetitionFinalRanking";

import { l } from "Languages";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useState } from "react";
import api from "Services/Api";
import { xlsxDownloadPrefix } from "Utils/downloadFile";
import CompetitionSettingsFinalRankingTable from "./CompetitionSettingsFinalRankingTable";
import styles from "./styles.scss";

const cx = mkCx(styles);

type CompetitionSettingsFinalRankingProps = {
    competition: Competition;
};

const CompetitionSettingsFinalRanking: React.FunctionComponent<CompetitionSettingsFinalRankingProps> = ({
    competition,
}) => {
    const [finalRanking, setFinalRanking] = useState<CompetitionFinalRanking>();

    useEffect(() => {
        if (!finalRanking) {
            const f = new CompetitionFinalRanking(competition.id);
            setFinalRanking(f);

            f.fetchFinalRankingConfiguration();
        }
    }, [competition, finalRanking]);

    const showTurnOffConfirmation = useCallback(() => {
        if (!finalRanking) {
            return;
        }

        Modal.confirm({
            onOk: finalRanking.turnOff,
            icon: <ExclamationCircleOutlined />,
            title: l("CompetitionDetails_FinalRanking_Off"),
            content: l("CompetitionDetails_FinalRanking_Off_Confirmation"),
            okText: l("CompetitionDetails_FinalRanking_Off"),
            okButtonProps: {
                danger: true,
            },
            cancelText: l("Common_Cancel"),
            centered: true,
            maskClosable: true,
        });
    }, [finalRanking]);

    const exportRankings = useCallback(async () => {
        const r = await api.finalRankingExportSpreadsheet({
            CompetitionId: competition.id,
        });

        if (r.isSuccess && r.result) {
            downloadFile(xlsxDownloadPrefix + r.result, {
                name: l("CompetitionDetails_FinalRanking_Filename", competition.name),
            });
        } else {
            message.error(l("CompetitionDetails_FinalRanking_Export_Error"));
        }
    }, [competition.id, competition.name]);

    return (
        <Skeleton loading={finalRanking?.isLoading} active={finalRanking?.isLoading}>
            <Spacing childrenGutterY className={cx("rankings-spacing")}>
                <div className={cx("rankings-header")}>
                    <b>{l("CompetitionDetails_FinalRanking")}</b>
                    <div className={cx("actions")}>
                        {finalRanking?.entriesDraft ? (
                            <>
                                <Button onClick={finalRanking.cancelEditing}>{l("Common_Cancel")}</Button>
                                <Button
                                    type="primary"
                                    onClick={finalRanking.saveEdited}
                                    loading={finalRanking.isSaving}>
                                    {l("Common_Save")}
                                </Button>
                            </>
                        ) : finalRanking?.currentEntries ? (
                            <>
                                <Button type="danger" onClick={showTurnOffConfirmation} loading={finalRanking.isSaving}>
                                    {l("CompetitionDetails_FinalRanking_Off")}
                                </Button>
                                <Button type="primary" onClick={finalRanking.startEditing}>
                                    {l("CompetitionDetails_FinalRanking_Edit")}
                                </Button>
                                <Button onClick={exportRankings}>{l("Common_Export")}</Button>
                            </>
                        ) : (
                            <Button type="primary" onClick={finalRanking?.turnOn} loading={finalRanking?.isSaving}>
                                {l("CompetitionDetails_FinalRanking_On")}
                            </Button>
                        )}
                    </div>
                </div>
                {finalRanking?.entriesDraft || finalRanking?.currentEntries ? (
                    <>
                        <CompetitionSettingsFinalRankingTable
                            entries={finalRanking.assignedEntries}
                            type="assigned"
                            mode={finalRanking?.entriesDraft ? "edit" : "readonly"}
                            finalRanking={finalRanking}
                        />
                        <CompetitionSettingsFinalRankingTable
                            entries={finalRanking.unassignedEntries}
                            type="unassigned"
                            mode={finalRanking?.entriesDraft ? "edit" : "readonly"}
                            finalRanking={finalRanking}
                        />
                    </>
                ) : (
                    <div className={cx("is-off")}>{l("CompetitionDetails_FinalRanking_Is_Off")}</div>
                )}
            </Spacing>
        </Skeleton>
    );
};

export default observer(CompetitionSettingsFinalRanking);
